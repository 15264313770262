import React from 'react';
import LandingLayout from 'layouts/LandingLayout/LandingLayout';
import Header from 'Components/Header/Header';
import { Container } from 'react-bootstrap';
import './TermsConditions.module.scss';

const TermsConditions = () => (
  <LandingLayout>
    <Header />
    <Container className="mt-5">
      <div>
        <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>
          TERMS OF SERVICE
        </h1>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          <p>Last Updated:&nbsp; January 2025</p>
        </p>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          1. INTRODUCTION
        </h1>
        <p style={{ textAlign: 'justify' }}>
          Welcome to Vocable.ai ("Vocable," "we," "our," or "us"). These Terms
          of Service ("Terms") govern your access and use of our artificial
          intelligence-powered content marketing platform (the "Service"). By
          using the Service, you agree to be bound by these Terms. If you do not
          agree, you may not use the Service.
        </p>
        <p style={{ textAlign: 'justify' }}>
          These Terms constitute a legally binding agreement between you and
          Vocable. If you are using the Service on behalf of a business, you
          represent that you have the authority to bind that business to these
          Terms.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          2. SERVICES PROVIDED
        </h1>
        <p style={{ textAlign: 'justify' }}>
          Vocable provides AI-powered tools for content planning, creation, and
          management, leveraging third-party APIs such as OpenAI and Claude AI
          (the "AI Providers"). The Service allows users to generate, edit, and
          manage content but does not guarantee accuracy or reliability of
          AI-generated output.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          3. ACCOUNT REGISTRATION & ELIGIBILITY
        </h1>
        <ul>
          <li>Be at least 18 years old.</li>
          <li>Provide accurate, complete, and current account information.</li>
          <li>
            Maintain the security of your login credentials and notify us
            immediately of unauthorized access.
          </li>
          <li>Use the Service only for lawful purposes.</li>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          Vocable reserves the right to suspend or terminate your account if you
          violate these Terms.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          4. DATA PRIVACY & SECURITY
        </h1>
        <p style={{ textAlign: 'justify' }}>
          Your privacy is important to us. We do not use customer data to train
          AI models. The AI Providers process user inputs to generate responses,
          but Vocable does not store or use this data beyond session purposes.
          See our Privacy Policy for details.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          5. AI-GENERATED CONTENT & RESPONSIBILITY
        </h1>
        <ul>
          <li>
            AI-generated output may contain errors, biases, or inaccuracies.
          </li>
          <li>
            Vocable does not guarantee factual accuracy or compliance with legal
            or regulatory requirements.
          </li>
          <li>
            You are responsible for reviewing, verifying, and using AI-generated
            content appropriately before publishing.
          </li>
        </ul>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          6. ACCEPTABLE USE POLICY
        </h1>
        <ul>
          <li>Do not violate any applicable laws or regulations.</li>
          <li>
            Do not use the Service for unlawful, harmful, or fraudulent
            activities.
          </li>
          <li>
            Do not copy, modify, distribute, or reverse-engineer the Service.
          </li>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          Vocable reserves the right to suspend or terminate accounts that
          violate these policies.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          7. PAYMENT, SUBSCRIPTIONS, AND CANCELLATIONS
        </h1>
        <p style={{ textAlign: 'justify' }}>
          Vocable offers subscription plans with recurring billing. Payments are
          processed through Stripe, and all fees are non-refundable unless
          otherwise stated. Subscription cancellations take effect at the end of
          the billing cycle.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          8. INTELLECTUAL PROPERTY RIGHTS
        </h1>
        <p style={{ textAlign: 'justify' }}>
          Vocable retains all rights in the Service, including AI-powered tools,
          software, and brand materials. Users retain ownership of original
          content they create using Vocable.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          9. DISCLAIMERS & LIMITATIONS OF LIABILITY
        </h1>
        <p style={{ textAlign: 'justify' }}>
          The Service is provided "AS IS" without warranties of any kind.
          Vocable is not liable for inaccuracies in AI-generated content or any
          indirect, incidental, or consequential damages.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          10. INDEMNIFICATION
        </h1>
        <p style={{ textAlign: 'justify' }}>
          You agree to indemnify and hold harmless Vocable from claims, damages,
          or liabilities arising from your use or misuse of the Service.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          11. THIRD-PARTY SERVICES
        </h1>
        <p style={{ textAlign: 'justify' }}>
          Vocable integrates third-party AI services (e.g., OpenAI, Claude). You
          acknowledge that these services are subject to their own terms and
          policies.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          12. TERMINATION & SUSPENSION
        </h1>
        <p style={{ textAlign: 'justify' }}>
          Vocable may suspend or terminate your account if you breach these
          Terms, fail to pay fees, or engage in fraudulent activities.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          13. GOVERNING LAW & DISPUTE RESOLUTION
        </h1>
        <p style={{ textAlign: 'justify' }}>
          These Terms are governed by the laws of the State of New York. Any
          disputes shall be resolved through arbitration in New York, NY.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          14. CHANGES TO TERMS
        </h1>
        <p style={{ textAlign: 'justify' }}>
          Vocable may update these Terms at any time. We will notify users of
          material changes via email or platform notifications.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          15. CONTACT US
        </h1>
        <p style={{ textAlign: 'justify' }}>
          For questions about these Terms, contact us at: support@vocable.ai
        </p>
      </div>
    </Container>
  </LandingLayout>
);

export default TermsConditions;
