import React from 'react';
import Styles from './Style.module.scss';
import Container from '../../assets/login/Container.jpg';
import VocableLogo from '../../assets/login/vocable..svg';
import { ErrorMessage, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import BSButton from 'Components/Button/BSButton';
import FormikTextField from 'Components/Form/FormikTextField';
import { HiOutlineEnvelope } from 'react-icons/hi2';
import { GoLock } from 'react-icons/go';
import { IoEyeOutline } from 'react-icons/io5';
import Google from '../../assets/login/google.svg';
import { IoEyeOffSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import _Button1 from '../../assets/login/_Button.svg';
import _Button2 from '../../assets/login/_Button (1).svg';
import _Button3 from '../../assets/login/_Button (2).svg';
import _Button4 from '../../assets/login/_Button (3).svg';
import { useDispatch } from 'react-redux';
import { login, register, socialLogin } from 'redux/slices/auth';
import * as Yup from 'yup';
import { EMAIL_REGEX } from 'constants';
import { ROUTES } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { SIGNIN_MODAL_HEADING } from 'constants';
import ForgotPasswordModal from 'Containers/Modal/ForgotPasswordModal/ForgotPasswordModal';
import { Style } from 'domelementtype';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPasswordModalShow, setforgotPasswordModalShow] = useState(false);

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(EMAIL_REGEX, 'Invalid email')
      .required('Email is required.'),
    password: Yup.string().required('Password is required.'),
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = async (values) => {
    const { email, password } = values || {};
    const body = { user: { email, password } };

    const response = await dispatch(login(body));
    const { payload = {} } = response;
    if (payload.user?.sign_in_count < 2) {
      // navigate(ROUTES.OnboardingInfo);
    }
    // if (payload.confirmation_required) {
    //   handleClose();
    // } else if (payload.user?.sign_in_count < 2) {
    //   navigate(ROUTES.OnboardingInfo);
    // }
  };

  const handleGoogleLoginSuccess = async (response) => {
    const responseData = await dispatch(
      socialLogin(
        { googleAccessToken: response.access_token },
        SIGNIN_MODAL_HEADING,
      ),
    );
    const { payload = {} } = responseData || {};
    if (payload.user?.sign_in_count < 2) {
      navigate(ROUTES.OnboardingInfo);
    }
  };

  const handleGoogleLoginFailure = () => {
    renderError('Login failed, please contact with support!');
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginFailure,
  });

  const forgotPasswordModalShowHandler = () => {
    setforgotPasswordModalShow(true);
  };
  const forgotPasswordModalCloseHandler = () => {
    setforgotPasswordModalShow(false);
  };

  return (
    <div className={Styles.OuterContainer}>
      <div className={Styles.OuterContainer__left}>
        <img
          src={VocableLogo}
          alt="logo"
          onClick={() => navigate('/')}
          style={{ cursor: 'pointer' }}
        />
        <div
          className={`${Styles.OuterContainer__left__form_container} ${Styles.centered_container}`}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
          >
            {({ values, setFieldValue, isValid, dirty }) => {
              const {
                content_type,
                state,
                keywords,
                priority,
                assignee_id,
                project_id,
                document_id,
              } = values || {};

              return (
                <>
                  <div
                    className={
                      Styles.OuterContainer__left__form_container__welcome
                    }
                  >
                    <p>Welcome</p>
                    <span>Sign in and explore Vocable.ai</span>
                  </div>
                  <div>
                    <div className={Styles.logos_container}>
                      <button
                        onClick={() => googleLogin()}
                        className={Styles.google_btn}
                      >
                        <span>Continue with Google</span>
                        <img src={Google} alt="google" />
                      </button>
                    </div>
                    <div className={Styles.or_container}>
                      <div className={Styles.or_container__line}></div>
                      <div className={Styles.or_container__or}>OR</div>
                      <div className={Styles.or_container__line}></div>
                    </div>
                  </div>
                  <Form
                    className={Styles.formik_form}
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className={Styles.fullWidth}>
                      <FormikTextField
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        classList="input input__Email"
                        Label="Email"
                        isRequired
                        preIcon={<HiOutlineEnvelope color="#667085" />}
                      />
                      <ErrorMessage name="email">
                        {(msg) => <span className="error">{msg}</span>}
                      </ErrorMessage>
                    </div>
                    <div className={Styles.password_field}>
                      <FormikTextField
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Enter your password"
                        classList="input input__Email"
                        Label="Password"
                        isRequired
                        preIcon={<GoLock color="#667085" />}
                        postIcon={
                          showPassword ? (
                            <IoEyeOffSharp
                              color="#667085"
                              onClick={togglePasswordVisibility}
                            />
                          ) : (
                            <IoEyeOutline
                              color="#667085"
                              onClick={togglePasswordVisibility}
                            />
                          )
                        }
                      />
                      <ErrorMessage name="password">
                        {(msg) => <span className="error">{msg}</span>}
                      </ErrorMessage>
                    </div>
                    <div
                      className={Styles.forgot_pass}
                      onClick={forgotPasswordModalShowHandler}
                    >
                      <Link to="#">Forgot Password?</Link>
                    </div>
                    <div className={Styles.btn_container}>
                      <BSButton
                        classList={`btn button secondary_btn w-100`}
                        variant="dark"
                        ButtonText="Sign In"
                        onClick={() => handleSubmit(values)}
                        disabled={!isValid}
                      />
                    </div>
                    <div className={Styles.trial_account}>
                      <span className={Styles.trial_account__dont_have}>
                        Don’t have an account?
                      </span>
                      <Link
                        to="/sign-up"
                        className={Styles.trial_account__have}
                        onClick={() => navigate('/sign-up')}
                      >
                        Start your free trial.
                      </Link>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className={Styles.OuterContainer__right}>
        <img src={Container} alt="image" />
      </div>
      {forgotPasswordModalShow && (
        <ForgotPasswordModal
          show={forgotPasswordModalShow}
          handleClose={forgotPasswordModalCloseHandler}
          handleShow={forgotPasswordModalShowHandler}
        />
      )}
    </div>
  );
};

export default LoginPage;
