import ImageView from 'Components/Image/ImageView';
import { deleteRequest, putRequest } from 'helpers/axios';
import { error as renderError } from 'helpers/toaster.js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Styles from './Collaborator.module.scss';
import { toast } from 'react-toastify';

const colourStyles = {
  option: (styles, { isSelected, isFocused }) => {
    return {
      boxShadow: isFocused ? null : null,
      background: isSelected ? `url("./Images/tickDark.svg")` : '',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '95% 50%;',
      backgroundColor: isSelected ? '#F2F4F7!important' : '#fff',
      paddingRight: isSelected ? '12px' : '25px',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '500',
      color: '#344054',

      '&:hover': {
        backgroundColor: 'lightgray',
        cursor: 'pointer',
      },
    };
  },
};

const removeCollaborator = (
  <div className={'d-flex flex-column ' + Styles.labelsDropDowns}>
    <p className={Styles.OptionsHeading__remove}>Remove Collaborator</p>
  </div>
);

function Collaborator({
  options,
  profileImg,
  nameLetters,
  name,
  email,
  accessLevel,
  sharedDocumentId,
  reload,
  owner,
  collaboratorId,
  pending,
  isDocument,
}) {
  const [shareOption, setShareOption] = useState('');

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setShareOption(accessLevel);
  }, [accessLevel]);

  const handleCollaboratorAccess = async (id) => {
    const collaboratorAccessResponse = await putRequest(
      `${
        isDocument ? 'shared_documents' : 'shared_folders'
      }/${sharedDocumentId}`,
      {
        [isDocument ? 'shared_document' : 'shared_folder']: {
          access_level_id: Number(id),
        },
      },
    );
    if (collaboratorAccessResponse.status === 200) {
      id !== accessLevel && toast.success('Access updated successfully');
      reload();
    }
  };

  const handleDeleteCollaborator = async () => {
    try {
      await deleteRequest(
        `${
          isDocument ? 'shared_documents' : 'shared_folders'
        }/${sharedDocumentId}`,
      );

      toast.success('Collaborator removed successfully');
      reload();
    } catch (exception) {
      exception?.response?.data?.error &&
        renderError(exception?.response?.data?.error);
    }
  };

  return (
    <div className={Styles.Collaborator}>
      <div className={'d-flex  ' + Styles.Collaborator__Info}>
        {profileImg ? (
          <ImageView src={profileImg} class="img-fluid rounded-profile" />
        ) : (
          <span class={' rounded-profile '}>{nameLetters}</span>
        )}

        <section className="d-flex flex-column">
          <p className={Styles.Collaborator__Info_Name}>{`${name} ${
            owner ? '(Owner)' : pending ? '(Pending)' : ''
          }`}</p>
          <p className={Styles.Collaborator__Info_Email}>{email}</p>
        </section>
      </div>

      <Select
        isSearchable={false}
        className={'Custom-Select'}
        classNamePrefix={'Custom-Select'}
        styles={colourStyles}
        options={[
          ...(options || []),
          { value: 'Remove', label: removeCollaborator },
        ]}
        placeholder="Select Role"
        value={options.find((option) => option.id === shareOption)}
        isDisabled={owner || pending || collaboratorId === user.id}
        onChange={({ id }) => {
          if (!id) {
            handleDeleteCollaborator();
          } else {
            handleCollaboratorAccess(id);
            setShareOption(id);
          }
        }}
      />
    </div>
  );
}

export default Collaborator;
