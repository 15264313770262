import { useMemo, useCallback, useState } from 'react';
import { generateResponse } from 'helpers/openAI';
import { error as errorToast } from 'helpers/toaster.js';
import { generateAiPoweredBrief } from 'services/taskService';
import { getTagsFromInput } from 'constants/KeywordResearchStats';
import { getMultiResearch } from 'services/researchService';

const extractWordCount = (parameter) => {
  const pattern = /(\d+)\s*(?:to|-)\s*(\d+)/;
  const match = pattern.exec(parameter);

  if (!match) {
    return ''; // No number found
  }

  const number1 = parseInt(match[1], 10);
  const number2 = parseInt(match[2], 10);

  return isNaN(number2) ? number1 : Math.max(number1, number2);
};

const sanitize = (brief) => {
  return {
    title: brief.Title,
    description: brief.Description,
    priority: 'medium',
    state: 'not_started',
    content_type: brief['Content Type'],
    keywords: brief.Keywords,
    target_audience: brief.Audience,
    word_count: extractWordCount(brief['Word Count']),
    content_structure: brief['Content Structure'],
  };
};

const buildHeadlinePrompt = ({
  content_type,
  topic,
  keywords,
  goals,
  target_audience,
}) => {
  return `Based on the following information and context, think deeply and generate 10 title or headline options for: Topic: ${topic} 
    Keywords: ${keywords}
    
    Type of content: ${content_type}
    
    Audience: ${target_audience}
    
    Goal: ${goals}
    Only return the generated headlines nothing other than that.`;
};

const getResearchInfo = async (selectedTags) => {
  if (!selectedTags || selectedTags.length === 0) {
    return;
  }

  const phrases = selectedTags.map((tag) => tag.title).join(',');

  const response = await getMultiResearch(phrases);

  const { data } = response || {};

  return data;
};

const useAiPoweredBrief = (initialValues) => {
  const [setupStep, setSetupStep] = useState(0);
  const [headlines, setHeadlines] = useState({});
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [researchInfo, setResearchInfo] = useState();
  const [isAnalyzingKeywords, setIsAnalyzingKeywords] = useState(false);
  const [isFetchingKeywords, setIsFetchingKeywords] = useState(false);
  const [isFetchingHeadlines, setIsFetchingHeadlines] = useState(false);
  const [aiBrief, setAiBrief] = useState({});
  const [isGeneratingAiBrief, setIsGeneratingAiBrief] = useState(false);

  const [formFields, setFormFields] = useState({
    content_type: '',
    topic: '',
    target_audience: '',
    goals: '',
    keywords: [],
    headline: '',
  });

  const nextStep = () => {
    setSetupStep((prevStep) => prevStep + 1);
  };

  const previousStep = () => {
    setSetupStep((prevStep) => prevStep - 1);
  };

  const handleFormFieldsChange = useCallback((fieldName, fieldValue) => {
    setFormFields((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  }, []);

  const memoizedSelectedTags = useMemo(() => selectedTags, [selectedTags]);

  const fetchHeadlines = useCallback(async () => {
    try {
      setIsFetchingHeadlines(true);
      const data = await generateResponse(
        buildHeadlinePrompt({
          ...formFields,
          keywords: memoizedSelectedTags.map((tag) => tag.title).join(', '),
        }),
      );
      setHeadlines(data);
      setIsFetchingHeadlines(false);
    } catch (error) {
      setIsFetchingHeadlines(false);
      errorToast(error);
    }
  }, [formFields, memoizedSelectedTags]);

  const generateBrief = async () => {
    try {
      setIsGeneratingAiBrief(true);
      nextStep();
      const { data } =
        (await generateAiPoweredBrief({
          ...formFields,
          keywords: memoizedSelectedTags.map((tag) => tag.title).join(', '),
        })) || {};
      const sanitizedResponse = sanitize(data);
      setAiBrief((prevAiBrief) =>
        Object.assign({}, initialValues, sanitizedResponse),
      );
      setIsGeneratingAiBrief(false);
    } catch (error) {
      setIsGeneratingAiBrief(false);
      errorToast(error);
    }
  };

  const fetchTags = async () => {
    setSelectedTags([]);
    setTags(() => []);
    const { content_type, topic, goals } = formFields;

    if (content_type && topic && goals) {
      try {
        setIsFetchingKeywords(true);
        await getTagsFromInput({ title: topic, setTags });
        setIsFetchingKeywords(false);
      } catch (error) {
        setIsFetchingKeywords(false);
        setTags([]);

        errorToast(error);
      }
    } else {
      setTags([]);
    }
  };

  const getResearchData = async () => {
    try {
      setIsAnalyzingKeywords(true);
      // const data = await getResearchInfo(memoizedSelectedTags);
      // setResearchInfo(data);
      setIsAnalyzingKeywords(false);
    } catch (error) {
      setIsAnalyzingKeywords(false);
    }
  };

  return {
    nextStep,
    previousStep,
    setupStep,
    formFields,
    handleFormFieldsChange,
    fetchTags,
    tags,
    setTags,
    selectedTags,
    setSelectedTags,
    memoizedSelectedTags,
    fetchHeadlines,
    headlines,
    getResearchData,
    researchInfo,
    generateBrief,
    aiBrief,
    setResearchInfo,
    isFetchingKeywords,
    isAnalyzingKeywords,
    setIsAnalyzingKeywords,
    isFetchingHeadlines,
    isGeneratingAiBrief,
  };
};

export default useAiPoweredBrief;
