import React, { useRef, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { BiDisc } from 'react-icons/bi';
import { FiCalendar, FiTag, FiUser } from 'react-icons/fi';
import Styles from './KanbanList.module.scss';
import TableHead from 'Components/TableHeader/TableHead';
import ContentPlannerTable from 'Components/ContentPlanner/ContentPlannerTable';

const TableHeadings = [
  {
    id: 'deadline',
    title: 'Deadline',
    icon: <FiCalendar color="#667085" size={16} />,
  },
  {
    id: 'assignedTo',
    title: 'Assigned to',
    icon: <FiUser color="#667085" size={16} />,
  },
  {
    id: 'status',
    title: 'Status',
    icon: <BiDisc color="#667085" size={16} />,
  },
  {
    id: 'contentType',
    title: 'Content Type',
    icon: <FiTag color="#667085" size={16} />,
  },
];

function KanbanList({
  tasks,
  onDelete,
  onEdit,
  onMove,
  fetchCPTasks,
  onArchive,
  onUpdateBrief = () => {},
  selectedColumns = [
    ['deadline', true],
    ['assignedTo', true],
    ['status', true],
    ['contentType', true],
  ],
  updateColumns = null,
  calendarTable,
  handleAICreate,
}) {
  // State for tracking current sort
  const [currentSort, setCurrentSort] = useState(null);
  
  // Create a ref object (we don't need useRef hook for this simple use case)
  const sortRef = { current: {} };
  
  // Handle sort action from TableHead
  const handleSort = (column, direction) => {
    setCurrentSort({ column, direction });
    
    // Trigger sorting in the ContentPlannerTable via ref
    if (sortRef.current && sortRef.current.sortBy) {
      sortRef.current.sortBy(column, direction);
    }
  };

  return (
    <section className={Styles.calendar_table}>
      <Table className="table-fixed table-sticky" hover responsive="lg">
        <TableHead
          selectedColumns={selectedColumns}
          updateColumns={updateColumns}
          TableHeadings={TableHeadings}
          onSort={handleSort}
          currentSort={currentSort}
        />
        <ContentPlannerTable
          tasks={tasks}
          onDelete={onDelete}
          onMove={onMove}
          onEdit={onEdit}
          fetchCPTasks={fetchCPTasks}
          onArchive={onArchive}
          onUpdateBrief={onUpdateBrief}
          selectedColumns={selectedColumns}
          calendarTable={calendarTable}
          handleAICreate={handleAICreate}
          sortRef={sortRef}
          initialSortColumn={currentSort?.column}
          initialSortDirection={currentSort?.direction}
        />
      </Table>
    </section>
  );
}

export default KanbanList;