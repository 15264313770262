import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DocumentCard } from 'Components/DocumentCard/DocumentCard';
import { ROUTES } from 'constants/routes';
import { ReactComponent as DocCompleted } from '../../assets/svg/doc_completed.svg';
import { ReactComponent as DocInprogress } from '../../assets/svg/doc_inprogress.svg';
import { ReactComponent as DocOverDue } from '../../assets/svg/doc_overdue.svg';
import Styles from './Dashboard.module.scss';
import { AvatarGroup } from 'Components/AvatarGroup/AvatarGroup';
import NoAvatar from 'Components/AvatarGroup/NoAvatar';
import { useNavigate } from 'react-router-dom';

import { getGreeting } from 'helpers';
import Select from 'react-select';
import { error as renderError } from 'helpers/toaster.js';
import { getAllMembers } from 'services/teamService';
import {
  DashboardSelectStyles,
  dashboardSelectOptions,
} from './dashboardSelectOptions';
import moment from 'moment';

const options = dashboardSelectOptions([
  { value: 'week', label: 'This Week' },
  {
    value: 'month',
    label: 'This Month',
  },
]);

const StatsSection = ({
  setInsightsDuration,
  overdueBriefsCount,
  completedDocumentsCount,
  inProgressDocumentsCount,
  planName,
}) => {
  const currentDate = moment().format('dddd, MMMM D');
  const navigate = useNavigate();
  const [isFirstSession, setIsFirstSession] = useState(false);
  const [collaborators, setCollaborators] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { name, sign_in_count } = user || {};
  const userName = name.split(' ')[0];

  const fetchAllMembers = useCallback(async () => {
    try {
      const response = await getAllMembers();
      setCollaborators(
        response.data
          .filter((c) => c.id !== user.id)
          .map((c) => ({
            avatar: c.avatar,
            name: c.name,
          })),
      );
    } catch (e) {
      renderError(e);
      setCollaborators([]);
    }
  });

  useEffect(() => {
    // This is in order to display a greeting message on the first session of the user
    let firstSession = localStorage.getItem('firstSession');

    // If the user is logging in for the first time, the value coming from the local storage will be null
    // So we set the value to 0
    if (firstSession === null) {
      firstSession = 0;
    }

    // If the firstSession is lower or equal to 1
    // and the user has signed in only once, we set the isFirstSession to true
    // And then increment the local storage value
    // because now the user has had more than one session
    if (firstSession <= 1 && sign_in_count && sign_in_count <= 1) {
      setIsFirstSession(true);
      localStorage.setItem('firstSession', firstSession + 1);
    } else {
      setIsFirstSession(false);
    }
  }, []);

  useEffect(() => {
    fetchAllMembers();
  }, []);

  return (
    <div className={Styles.Dashboard__Header}>
      <div className="d-flex justify-content-between align-items-center gap-3">
        <div className={Styles.Dashboard__Header_Greets}>
          <p className={Styles.Dashboard__Header_Greets_Date}>{currentDate}</p>
          {isFirstSession ? (
            <>
              <p className={Styles.Dashboard__Header_Greets_Greeting}>
                Welcome to Your Content Studio!
              </p>
              <p className={Styles.Dashboard__Header_Greets_Subtitle}>
                Dive in and discover all that Vocable has to offer.
              </p>
            </>
          ) : (
            <div className="d-flex align-items-center gap-4">
              <p className={Styles.Dashboard__Header_Greets_Greeting}>
                {getGreeting()}, {userName}
              </p>
            </div>
          )}
        </div>
        <div className={Styles.Dashboard__Header_Navigation}>
          <section className={Styles.Dashboard__Header_Navigation_Select}>
            <Select
              isSearchable={false}
              classNamePrefix={'DashboardHeaderSelect'}
              className={`DashboardHeaderSelect`}
              styles={DashboardSelectStyles}
              placeholder={options[0].label}
              options={options}
              onChange={(value) => {
                setInsightsDuration(value.value);
              }}
            />
          </section>
        </div>
      </div>
      <div className={Styles.Dashboard__Header_Documents}>
        <section
          className={Styles.Dashboard__Header_Navigation_DocumentsDetails}
        >
          <DocumentCard
            Icon={DocCompleted}
            to={ROUTES.Library}
            count={completedDocumentsCount}
            text="Completed"
            polorized="Document"
          />
          <DocumentCard
            Icon={DocInprogress}
            to={ROUTES.Library}
            count={inProgressDocumentsCount}
            text="In Progress"
            polorized="Documents"
          />
          <DocumentCard
            Icon={DocOverDue}
            to={ROUTES.Planner}
            count={overdueBriefsCount}
            text="Overdue"
            polorized="Brief"
          />
          {/* <DocumentCardSkeleton /> */}
        </section>
        {planName !== 'Basic Plan' ? (
          <div
            className={`align-items-end gap-4 ${!collaborators?.length > 0 && Styles.dashed_border}`}
          >
            {collaborators?.length > 0 ? (
              <AvatarGroup
                heading={'Collaborators'}
                avatars={collaborators}
                overflowAfter={4}
              />
            ) : (
              <NoAvatar
                heading="Collaborators"
                description="No team member added yet!"
              />
            )}
            <div
              className={
                collaborators?.length > 0
                  ? Styles.divider
                  : Styles.divider_noavatar
              }
            ></div>
            <span
              className={Styles.add_collabrators}
              onClick={() => navigate(ROUTES.Teams)}
            >
              +
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StatsSection;
