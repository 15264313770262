import React from 'react';
import LandingLayout from 'layouts/LandingLayout/LandingLayout';
import Header from 'Components/Header/Header';
import { Container } from 'react-bootstrap';

const PrivacyPolicy = () => (
  <LandingLayout>
    <Header />
    <Container className="mt-5">
      <div>
        <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>
          VOCABLE.AI PRIVACY POLICY
        </h1>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          1. INTRODUCTION
        </h1>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          Welcome to Vocable.ai ("Vocable," "we," "our," or "us"). Your privacy
          is important to us, and we are committed to protecting the information
          you share with us. This Privacy Policy explains how we collect, use,
          and safeguard your information when you use our artificial
          intelligence-powered content marketing platform (the "Service").
        </p>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          By using the Service, you consent to the practices described in this
          Privacy Policy. If you do not agree, please do not use the Service.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          2. INFORMATION WE COLLECT
        </h1>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          We collect minimal information necessary to provide and improve the
          Service. We do not collect user data to train AI models.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          2.1 Information You Provide
        </h1>
        <ul>
          <li>
            <strong
              style={{
                marginTop: '12pt',
                marginBottom: '12pt',
                pageBreakAfter: 'avoid',
                fontSize: '12pt',
              }}
            >
              Account Information:
            </strong>{' '}
            Name, email address, billing information, and other details provided
            when creating an account.
          </li>
          <li>
            <strong
              style={{
                marginTop: '12pt',
                marginBottom: '12pt',
                pageBreakAfter: 'avoid',
                fontSize: '12pt',
              }}
            >
              Content Data:
            </strong>{' '}
            Text, documents, and other materials uploaded or created using the
            Service.
          </li>
          <li>
            <strong
              style={{
                marginTop: '12pt',
                marginBottom: '12pt',
                pageBreakAfter: 'avoid',
                fontSize: '12pt',
              }}
            >
              Payment Information:
            </strong>{' '}
            Processed securely via third-party payment providers (e.g., Stripe).
            We do not store payment details.
          </li>
        </ul>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          2.2 Information Collected Automatically
        </h1>
        <ul>
          <li>
            <strong
              style={{
                marginTop: '12pt',
                marginBottom: '12pt',
                pageBreakAfter: 'avoid',
                fontSize: '12pt',
              }}
            >
              Usage Data:
            </strong>{' '}
            Information about your interactions with the Service (e.g., logins,
            feature usage, timestamps).
          </li>
          <li>
            <strong
              style={{
                marginTop: '12pt',
                marginBottom: '12pt',
                pageBreakAfter: 'avoid',
                fontSize: '12pt',
              }}
            >
              Device & Technical Data:
            </strong>{' '}
            IP address, browser type, operating system, and other device-related
            information.
          </li>
          <li>
            <strong
              style={{
                marginTop: '12pt',
                marginBottom: '12pt',
                pageBreakAfter: 'avoid',
                fontSize: '12pt',
              }}
            >
              Cookies & Tracking Technologies:
            </strong>{' '}
            We use cookies to enhance functionality and improve user experience.
          </li>
        </ul>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          2.3 Third-Party AI Processing
        </h1>
        <ul>
          <li>
            Vocable integrates with third-party AI providers such as OpenAI and
            Claude AI.
          </li>
          <li>
            User inputs may be processed by these providers to generate
            responses, but Vocable does not store or use this data beyond
            session functionality.
          </li>
        </ul>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          3. HOW WE USE YOUR INFORMATION
        </h1>
        <ul>
          <li>Provide, operate, and improve the Service.</li>
          <li>Authenticate and secure user accounts.</li>
          <li>Process payments and manage subscriptions.</li>
          <li>Offer customer support and resolve issues.</li>
          <li>Analyze usage trends and optimize features.</li>
          <li>Enforce compliance with our Terms of Service.</li>
        </ul>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          We do not use personal data for advertising or AI model training.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          4. DATA SHARING & DISCLOSURE
        </h1>
        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          4.1 Service Providers
        </h1>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          We use trusted third-party vendors (e.g., cloud hosting, payment
          processors, analytics) to facilitate the Service.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          4.2 Legal Compliance
        </h1>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          We may disclose information if required by law, court order, or
          regulatory authority.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          4.3 Business Transfers
        </h1>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          In the event of a merger, acquisition, or sale, user data may be
          transferred to the new entity.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          5. DATA SECURITY
        </h1>
        <ul>
          <li>Encryption of sensitive information.</li>
          <li>Access controls and authentication safeguards.</li>
          <li>Regular security audits and monitoring.</li>
        </ul>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          Despite these efforts, no online system is completely secure. Users
          should take necessary precautions to protect their accounts.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          6. DATA RETENTION
        </h1>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          We retain user data only as long as necessary to provide the Service
          and comply with legal obligations. Account-related information may be
          deleted upon request, subject to retention requirements.
        </p>

        <h1
          style={{
            marginTop: '12pt',
            marginBottom: '12pt',
            pageBreakAfter: 'avoid',
            fontSize: '12pt',
            fontWeight: 'bold',
          }}
        >
          7. USER RIGHTS & CHOICES
        </h1>
        <p style={{ marginTop: '0', marginBottom: '12pt' }}>
          To exercise these rights, contact us at{' '}
          <a href="mailto:info@vocable.ai">info@vocable.ai</a>.
        </p>
      </div>
    </Container>
  </LandingLayout>
);

export default PrivacyPolicy;
