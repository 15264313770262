import React, { useEffect, useState } from 'react';
import { KANBAN_STATES } from 'constants';
import KanbanContainer from 'Containers/KanbanContainer/KanbanContainer';
import { kanbanTasks } from 'helpers/contentPlanner';
import { updateTask } from 'services/contentPlannerService';
import { error as errorToast } from 'helpers/toaster.js';
import InfiniteScroll from 'react-infinite-scroll-component';

function KanbanBoard({
  tasks,
  onDelete,
  onCreateClick,
  onMove,
  onEdit,
  fetchCPTasks,
  onArchive,
  next,
  hasMore,
  setTasks,
  handleAICreate,
}) {
  const onDragStart = (e, id, projectId, documentId) => {
    e.dataTransfer.setData('taskId', id);
    if (projectId) {
      e.dataTransfer.setData('projectId', projectId);
    }
    if (documentId) {
      e.dataTransfer.setData('documentId', documentId);
    }
  };

  const onDrop = async (e, category) => {
    const taskId = e.dataTransfer.getData('taskId');
    const project_id = e.dataTransfer.getData('projectId');
    const document_id = e.dataTransfer.getData('documentId');

    const updatedTask = tasks.find((task) => task.id === Number(taskId));
    updatedTask.state = category;

    const updatedTasks = tasks.map((task) => {
      if (Number(task.id) === Number(taskId)) {
        return { ...task, ...updatedTask };
      }
      return task;
    });

    setTasks(updatedTasks);

    try {
      const payload = { state: category };
      if (project_id) {
        payload.project_id = project_id;
      }
      if (document_id) {
        payload.document_id = document_id;
      }
      await updateTask(taskId, payload);
    } catch (error) {
      errorToast(error);
      setTasks(updatedTasks);
    }
  };

  const kanbanContainers = KANBAN_STATES.map((state) => {
    const filteredTasks = kanbanTasks(tasks, state);

    return (
      <KanbanContainer
        key={state}
        onCreateClick={onCreateClick}
        category={state}
        countOfTasks={filteredTasks.length}
        tasks={filteredTasks}
        onDelete={onDelete}
        onMove={onMove}
        onEdit={onEdit}
        onDragStart={onDragStart}
        onDrop={onDrop}
        fetchCPTasks={fetchCPTasks}
        onArchive={onArchive}
        handleAICreate={handleAICreate}
      />
    );
  });

  return (
    <InfiniteScroll next={next} hasMore={hasMore} dataLength={tasks.length}>
      <div className="d-flex" style={{ overflow: 'auto' }}>
        {kanbanContainers}
      </div>
    </InfiniteScroll>
  );
}

export default KanbanBoard;
