import React, { useCallback, useEffect, useRef, useState } from 'react';
import BSButton from 'Components/Button/BSButton';
import Collaborator from 'Components/Collaborator/Collaborator';
import FormikTextField from 'Components/Form/FormikTextField';
import { EDITOR, EMAIL_REGEX, FOLDER, FREESTYLE_EDITOR } from 'constants';
import { Formik } from 'formik';
import {
  copyToClipboard,
  getAccessDescription,
  getNameInitials,
} from 'helpers';
import { getRequest, postRequest } from 'helpers/axios';
import useOnClickOutside from 'hooks/useOutsideClick';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import ModalStyles from './ShareModal.module.scss';
import { FiLink, FiMail } from 'react-icons/fi';
import { getAccessLevels } from 'services/userService';

const getAccessLabel = (name) => {
  return (
    <div className={'d-flex flex-column ' + ModalStyles.labelsDropDowns}>
      <p className={ModalStyles.OptionsHeading}>{name}</p>
      <span className={ModalStyles.OptionRole}>
        {getAccessDescription(name)}
      </span>
    </div>
  );
};

const Styles = {
  option: (styles, { isSelected, isFocused }) => {
    return {
      boxShadow: isFocused ? null : null,
      background: isSelected ? `url("./Images/tickDark.svg")` : '',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '95% 50%',
      backgroundColor: isSelected ? '#F2F4F7!important' : '#fff',
      paddingRight: isSelected ? '12px' : '25px',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '500',
      color: '#344054',

      '&:hover': {
        backgroundColor: 'lightgrey',
        cursor: 'pointer',
      },
    };
  },

  control: (baseStyles) => {
    return {
      ...baseStyles,
      cursor: 'pointer',
    };
  },
};

const initialValues = {
  email: '',
  shareType: '',
};

const validationSchema = Yup.object({
  email: Yup.string().matches(EMAIL_REGEX, 'Invalid email').required(),
  shareType: Yup.string().required(),
});

const ShareModal = ({
  template_id,
  document,
  isDocument = true,
  folder,
  fetchFolders,
  ...props
}) => {
  const [isCopied, setIsCopied] = useState('');
  const [shareOptionLink, setshareOptionLink] = useState('can_view');
  const [options, setOptions] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [pendingCollaborators, setPendingCollaborators] = useState([]);
  const [documentOwner, setDocumentOwner] = useState({});
  const copyRef = useRef();
  const { user } = useSelector((state) => state.auth);

  const { documentId: documentIdParams } = useParams();
  const { id: documentIdProps } = document || {};
  const { id: folderId } = folder || {};

  const documentId = documentIdProps || documentIdParams;

  useOnClickOutside(copyRef, () => setIsCopied(false));

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 5000);
    }
  }, [isCopied]);

  const shareStr = isDocument ? 'documents' : 'folders';

  const fetchAccessLevels = useCallback(async () => {
    try {
      const accessLevels = await getAccessLevels();
      const { data } = accessLevels || {};
      const { access_levels } = data || {};
      const transformedAccessLevels = access_levels?.map((accessLevel) => {
        return {
          value: accessLevel.level,
          id: accessLevel.id,
          label: getAccessLabel(accessLevel.name),
          uuid: accessLevel.uuid,
        };
      });

      setOptions(transformedAccessLevels || []);
    } catch (error) {}
  }, []);

  useEffect(() => {
    fetchAccessLevels();
  }, [fetchAccessLevels]);

  const fetchCollaborators = useCallback(async () => {
    try {
      const collaboratorResponse = await getRequest(
        `${shareStr}/${isDocument ? documentId : folder.id}/collaborators`,
      );

      const { data } = collaboratorResponse || {};
      const { shared_documents, pending_collaborators, shared_folders } =
        data || {};

      setCollaborators(shared_documents || shared_folders || []);
      setPendingCollaborators(pending_collaborators || []);
    } catch (error) {}
  }, []);

  useEffect(() => {
    fetchCollaborators();
  }, [fetchCollaborators]);

  const fetchDocumentOwner = useCallback(async () => {
    try {
      const documentReponse = await getRequest(
        `${shareStr}/${isDocument ? documentId : folder.id}`,
      );
      const { data } = documentReponse || {};
      const { document, folder: dataFolder } = data || {};
      const { user: userDocument } = document || {};
      const { user: userFolder } = dataFolder || {};
      setDocumentOwner(userDocument || userFolder || {});
    } catch (error) {}
  }, []);

  useEffect(() => {
    fetchDocumentOwner();
  }, [fetchDocumentOwner]);

  const handleCopy = () => {
    const selectedAccessLevel = options.find(
      (option) => option.value === shareOptionLink,
    );

    if (!isDocument) {
      copyToClipboard(
        `${window.location.origin}/${FOLDER}/${folderId}/true/${selectedAccessLevel.uuid}`,
      );
      setIsCopied(true);
      return;
    }

    if (documentIdProps) {
      if (template_id) {
        copyToClipboard(
          `${window.location.origin}${EDITOR}/document/${documentId}/template/${template_id}/true/${selectedAccessLevel.uuid}`,
        );
      } else {
        copyToClipboard(
          `${window.location.origin}${FREESTYLE_EDITOR}/document/${documentId}/true/${selectedAccessLevel.uuid}`,
        );
      }
    } else {
      copyToClipboard(
        `${window.location.href}/true/${selectedAccessLevel.uuid}`,
      );
    }
    setIsCopied(true);
  };

  const addCollaborator = async ({ email, shareType, note }, setFieldValue) => {
    if (email === user.email) {
      toast.warning('You cannot share document with yourself');
    } else if (email === documentOwner.email) {
      toast.warning('You cannot share document with document owner');
    } else {
      const matchedCollaborator = collaborators.find(
        (item) =>
          item.collaborator.email === email &&
          item.access_level.uuid === shareType,
      );

      const matchedPendingCollaborator = pendingCollaborators.find(
        (item) => item.email === email,
      );

      if (matchedCollaborator || matchedPendingCollaborator) {
        toast.warning(
          'Hold up! seems this collaborator is already in your list',
        );
      } else {
        const addCollaboratorRequest = await postRequest(
          isDocument ? `shared_documents` : 'shared_folders',
          {
            [isDocument ? 'shared_document' : 'shared_folder']: {
              collaborators: [email],
              document_id: documentId,
              access_level_id: shareType,
              folder_id: folder?.id,
              note,
            },
          },
        );
        if (addCollaboratorRequest.status === 200) {
          toast.success('Invite sent out successfully!');
          fetchCollaborators();
          fetchFolders && fetchFolders();
        }
        setFieldValue('email', '');
        setFieldValue('shareType', '');
      }
    }

    props.onHide && props.onHide();
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={ModalStyles.DialogWrapper}
        style={{ zIndex: 10000 }}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className={ModalStyles.ShareModalHeading}>
            Share
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={ModalStyles.InviteInputSection}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnMount={true}
              enableReinitialize={true}
            >
              {({ values, isValid, setFieldValue }) => {
                return (
                  <>
                    <div>
                      <InputGroup
                        className={ModalStyles.GroupedInputs}
                        style={{ maxWidth: '540px' }}
                      >
                        <InputGroup.Text className={ModalStyles.Input__Icon}>
                          <FiMail />
                        </InputGroup.Text>

                        <FormikTextField
                          name="email"
                          type="email"
                          placeholder="Enter email for invite"
                          classList="input input__Email"
                          inputWrapperClass={ModalStyles.inputWrapperClass}
                        />
                        <Select
                          isSearchable={false}
                          className={`Custom-Select`}
                          classNamePrefix={`Custom-Select`}
                          styles={Styles}
                          options={options}
                          placeholder="Select Role"
                          value={
                            options.find(
                              (option) => option.uuid === values.shareType,
                            ) || null
                          }
                          onChange={({ value, id, uuid }) => {
                            setFieldValue('shareType', uuid);
                          }}
                        />
                      </InputGroup>
                      {/* </div> */}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <InputGroup className={ModalStyles.GroupedInputs}>
                          <FormikTextField
                            name="note"
                            type="text"
                            placeholder="Enter note"
                            classList="input input__Email"
                          />
                        </InputGroup>
                        <BSButton
                          classList={`button secondary_btn w-100`}
                          ButtonText="Send"
                          variant="dark"
                          disabled={!isValid}
                          onClick={() => addCollaborator(values, setFieldValue)}
                          style={{ height: 47 }}
                        />
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>

          <Collaborator
            profileImg={
              documentOwner.id === user.id
                ? user.medium_avatar
                : documentOwner.medium_avatar
            }
            name={documentOwner.name}
            email={documentOwner.email}
            nameLetters={getNameInitials(documentOwner.name)}
            options={options}
            accessLevel={
              options.find((option) => option.value === 'full_access')?.id
            }
            owner={true}
          />

          {collaborators.map((collaboratorInfo) => {
            const { collaborator, access_level, id } = collaboratorInfo || {};

            const {
              name,
              email,
              medium_avatar,
              id: collaboratorId,
            } = collaborator || {};
            const { id: accessLevel } = access_level || {};

            return (
              <Collaborator
                key={collaboratorId}
                collaboratorId={collaboratorId}
                profileImg={medium_avatar}
                name={name}
                email={email}
                nameLetters={getNameInitials(name)}
                options={options}
                accessLevel={accessLevel}
                sharedDocumentId={id}
                reload={() => fetchCollaborators()}
                isDocument={isDocument}
              />
            );
          })}

          {pendingCollaborators.map((collaboratorInfo) => {
            const { email, access_level_id: accessLevel } =
              collaboratorInfo || {};

            return (
              <Collaborator
                key={email}
                name={email}
                email={email}
                options={options}
                accessLevel={accessLevel}
                pending={true}
                nameLetters={getNameInitials(email)}
              />
            );
          })}
        </Modal.Body>
        <Modal.Footer className={ModalStyles.ShareModalFooter}>
          <div
            className={ModalStyles.CopyLink}
            onClick={handleCopy}
            ref={copyRef}
          >
            <FiLink size={18} color="#00a7b7" />
            <p>{isCopied ? 'Copied' : 'Copy Link'}</p>
          </div>
          <div className={'d-flex align-items-center '}>
            <span className={ModalStyles.linkLabel}>Anyone with the link:</span>
            <Select
              isSearchable={false}
              className={'Custom-Select'}
              classNamePrefix={'Custom-Select'}
              styles={Styles}
              options={options}
              placeholder="Select Role"
              value={options.find((option) => option.value === shareOptionLink)}
              onChange={({ value }) => {
                if (value === 'Remove') {
                  setshareOptionLink(shareOptionLink || 'Can View');
                } else {
                  setshareOptionLink(value);
                }
              }}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShareModal;
