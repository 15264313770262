import React, { useContext, useRef, useState } from 'react';
import { ModalContext } from 'layouts/LandingLayout/LandingLayout';

import Box from 'ui/Box/Box';
import Flex from 'ui/Flex/Flex';
import Text from 'ui/Text/Text';

import Header from 'Components/Header/Header';
import Button from 'ui/Button/Button';
import { Image } from 'react-bootstrap';

import Styles from './HeroSection.module.scss';
import { IoCaretBackCircleOutline } from 'react-icons/io5';
import TickerHero from '../Ticker/TickerHero';
import { useNavigate } from 'react-router-dom';
import Thumb from '../../../assets/landingpage/poster.jpg';
const HeroSection = () => {
  const { modalViewHandler, setModalType } = useContext(ModalContext);
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Box
      as="section"
      id="HeroSection"
      direction="column"
      gap="64"
      color="sunset"
      margins
      css={{ padding: '12px', marginTop: '-8px' }}
    >
      <Header />
      <Flex direction="column" align="center" gap="64">
        <Flex
          direction="column"
          align="center"
          gap="24"
          css={{ textAlign: 'center' }}
        >
          <div className={Styles.Banner}>
            For Solopreneurs, Creators, and Content Teams...
          </div>
          <p className={Styles.banner_header}>
            Automate Your Content Marketing with AI
          </p>
          <p className={Styles.baner_description}>
            Vocable's platform streamlines your entire content operations,
            making it easy to ideate, plan, draft, <br /> collaborate and
            optimise multi-channel content in one centralized workflow.
          </p>
        </Flex>
        <Flex
          align="center"
          gap="16"
          css={{ fontWeight: 600 }}
          className={Styles.mobile_watch_started}
        >
          <a
            href="https://youtu.be/EiDImRh5Jag"
            target="_blank"
            className={Styles.watch_demo}
          >
            <IoCaretBackCircleOutline
              size={32}
              color="##101828"
              className={Styles.watch_demo__play_btn}
            />
            <span>Watch our demo</span>
          </a>
          <Text as="p" weight="semiBold" css={{ color: '#101828' }}>
            or
          </Text>
          <button
            className={Styles.watch_demo__get_started_btn}
            onClick={() => navigate('sign-up')}
          >
            Start your FREE Trial
          </button>
        </Flex>
      </Flex>
      <div className={Styles.banner_ticker_video}>
        <TickerHero />
        <div className={`${Styles.banner_video} ${!isPlaying ? 'paused' : ''}`}>
          <video
            ref={videoRef}
            controls
            playsInline
            loop
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              borderRadius: '20px',
            }}
            onClick={handlePlayPause}
            poster={Thumb}
          >
            <source
              src={
                'https://blobsharedstg.blob.core.windows.net/media/intro.mp4'
              }
            />
          </video>
          <div
            className={`${Styles.video_controls} ${!isPlaying && Styles.visible}`}
          >
            {!isPlaying ? (
              <button
                className={Styles.play_button}
                onClick={handlePlayPause}
              ></button>
            ) : (
              <button
                className={Styles.pause_button}
                onClick={handlePlayPause}
              ></button>
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default HeroSection;
