import React, { useState, useEffect } from 'react';
import BSButton from 'Components/Button/BSButton';
import TableHead from 'Components/TableHeader/TableHead';
import Table from 'react-bootstrap/Table';
import sc from './Team.module.scss';
import sc1 from 'Containers/Dashboard/Dashboard.module.scss';
import CreateTeamModal from 'Containers/Modal/TeamModals/CreateTeamModal';
import Styles from 'Components/TableBody/TableBody.module.scss';
import ImageView from 'Components/Image/ImageView';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'redux/slices/loader';
import { TEAM_TABLE_HEADER_TITLES, PLAN_TEAM_COUNT_MAPPING } from 'constants';
import CustomDropDown from 'Components/CustomDropDown/CustomDropDown';
import { FiEdit, FiPlus, FiTrash2, FiXCircle } from 'react-icons/fi';
import ConfirmDeleteModal from 'Containers/Modal/ConfirmDeleteModal/ConfirmDeleteModal';
import LeaveTeamModal from 'Containers/Modal/LeaveTeamModal/LeaveTeamModal';
import { useNavigate } from 'react-router-dom';
import { getNameInitials } from 'helpers';
import { error as renderError } from 'helpers/toaster.js';
import PageHeader from 'Components/PageHeader/PageHeader';
import { getTeams } from 'services/teamService';
import EmptyState from 'Components/EmptyState/EmptyState';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';

const Team = () => {
  const [createTeamModalView, setCreateTeamModalView] = useState(false);
  const [deleteTeamModalView, setDeleteTeamModalView] = useState(false);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setTeam] = useState({});
  const { user } = useSelector((state) => state.auth);
  const { subscriptions } = user ?? {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  const [leaveTeamModal, setLeaveTeamModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ModalViewHanlder = () => {
    setCreateTeamModalView(!createTeamModalView);
  };

  useEffect(() => {
    fetchTeams();
    if (currentSubscription?.plan_name === 'Basic Plan') {
      setShowUpgradeModal(true);
    }
  }, []);

  const fetchTeams = async () => {
    try {
      const res = await getTeams();

      setTeams(res?.data?.teams);
      dispatch(setLoader({ isLoading: false }));
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      renderError(error);
    }
  };

  const editTeam = (resource) => {
    setTeam(resource);
    setCreateTeamModalView(true);
  };

  const deleteTeam = (resource) => {
    setDeleteTeamModalView(true);
    setTeam(resource);
  };

  const leaveTeam = (resource) => {
    setLeaveTeamModal(true);
    setTeam(resource);
  };

  const navigateToMembers = (team_id) => {
    navigate(`/teams/${team_id}`);
  };

  const handleUpgrade = () => {
    navigate('/subscription');
  };

  const dropDownOptions = [
    {
      label: 'Edit',
      icon: <FiEdit />,
      action: editTeam,
    },
    {
      label: 'Delete',
      icon: <FiTrash2 />,
      action: deleteTeam,
    },
  ];

  const otherDropDownOptions = [
    {
      label: 'Leave team',
      icon: <FiXCircle />,
      action: leaveTeam,
    },
  ];

  const canCreateTeam = (teams, planName) => {
    return teams?.length < PLAN_TEAM_COUNT_MAPPING[planName];
  };

  return (
    <section className={sc.TeamWrapper}>
      <div
        className={
          'd-flex justify-content-between align-items-center flex-wrap gap-2 h-100'
        }
      >
        <PageHeader
          title="Your Content Crew"
          subtitle="Add and manage your team members to streamline collaboration, and boost content productivity."
        />
        {canCreateTeam(teams, currentSubscription.plan_name) && (
          <BSButton
            icon={<FiPlus color="#fff" size={20} />}
            classList={'button font-Poppins secondary_btn'}
            ButtonText="Create Team"
            variant="light"
            onClick={ModalViewHanlder}
          />
        )}
      </div>
      <div className={sc.TeamWrapper_Table}>
        <Table className={`mt-3 table-fixed`} hover responsive="lg">
          <TableHead TableHeadings={TEAM_TABLE_HEADER_TITLES} />
          <tbody>
            {teams?.map((team) => {
              return (
                <tr
                  key={team.id}
                  className={Styles.TableRow}
                  style={{ cursor: 'pointer' }}
                >
                  <td
                    className={`d-flex align-items-center g-10 text-md-Regular ${Styles.TableData} ${Styles.TableData__Extensive}`}
                    onClick={() => navigateToMembers(team.id)}
                  >
                    {team.mini_avatar ? (
                      <ImageView
                        class="rounded-profile-x-sm"
                        src={team.mini_avatar}
                      />
                    ) : (
                      <span
                        className={'rounded-profile rounded-profile-x-sm'}
                        style={{ fontSize: '0.75rem' }}
                      >
                        {getNameInitials(team.title)}
                      </span>
                    )}
                    <p className={Styles.TableData_Title}>{team.title}</p>
                  </td>
                  <td
                    className={'text-md-Regular ' + Styles.TableData_Title}
                    onClick={() => navigateToMembers(team.id)}
                  >
                    {team.members_count}
                  </td>
                  <td
                    className={'text-md-Regular ' + Styles.TableData_Title}
                    onClick={() => navigateToMembers(team.id)}
                  >
                    {team.created_at}
                  </td>
                  <td
                    className={'text-md-Regular ' + Styles.TableData_Title}
                    onClick={() => navigateToMembers(team.id)}
                  >
                    {team.description?.substring(0, 100)}
                  </td>
                  <td
                    className={
                      'text-md-Regular overflow-visible ' +
                      Styles.TableData_Title
                    }
                  >
                    <CustomDropDown
                      options={
                        team.access === 'owner'
                          ? dropDownOptions
                          : otherDropDownOptions
                      }
                      resource={team}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {teams.length === 0 && (
          <EmptyState
            buttonText={`Create Team`}
            emptyText="No team found. Create a team and invite your members."
            onClick={ModalViewHanlder}
          >
            <ImageView src="/Images/plan.svg" class="img-fluid" />
          </EmptyState>
        )}
      </div>
      {createTeamModalView && (
        <CreateTeamModal
          show={createTeamModalView}
          team={selectedTeam}
          onHide={() => {
            setCreateTeamModalView(false);
            setTeam({});
          }}
          fetchTeams={fetchTeams}
        />
      )}
      {deleteTeamModalView && (
        <ConfirmDeleteModal
          show={deleteTeamModalView}
          onHide={() => {
            setDeleteTeamModalView(false);
            setTeam({});
            fetchTeams();
          }}
          resource={selectedTeam}
          resourceName="teams"
        />
      )}
      {leaveTeamModal && (
        <LeaveTeamModal
          show={leaveTeamModal}
          onHide={() => {
            setLeaveTeamModal(false);
            setTeam({});
            fetchTeams();
          }}
          resource={selectedTeam}
          email={user.email}
          showCustomMessage={true}
        />
      )}
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}
    </section>
  );
};

export default Team;
