// import React, { useState, useEffect } from 'react';
// import Styles from './Styles.module.scss';
// import { Form } from 'react-bootstrap';
// import { Formik } from 'formik';
// import Tag from 'Components/tag/Tag';
// import { ReactComponent as Instagram } from '../../../../assets/svg/instagram.svg';
// import { ReactComponent as Other } from '../../../../assets/svg/Group.svg';
// import { ReactComponent as X } from '../../../../assets/svg/x.svg';
// import { ReactComponent as Linkedin } from '../../../../assets/svg/linkedin.svg';
// import { ReactComponent as Youtube } from '../../../../assets/svg/youtube.svg';
// import { ReactComponent as Blog } from '../../../../assets/svg/blog.svg';
// import { ReactComponent as CaseStudy } from '../../../../assets/svg/case-study.svg';
// import { ReactComponent as Guide } from '../../../../assets/svg/Guide.svg';
// import { ReactComponent as Quiz } from '../../../../assets/svg/quiz.svg';
// import FormikDateField from 'Components/Form/FormikDateField';
// import ButtonSection from '../ButtonSection/ButtonSection';
// import { useDispatch, useSelector } from 'react-redux';
// import { nextStep, prevStep, updateStep4 } from 'redux/slices/newOnboarding';
// import * as Yup from 'yup';
// import { generateHeadlines } from 'services/projectService';
// import { error as errorToast, success as successToast } from 'helpers/toaster';

// const Step4 = ({ setHeadlines }) => {
//   const dispatch = useDispatch();
//   const onboardingData = useSelector((state) => state.newOnboarding.step4);

//   const tags = [
//     {
//       id: 'linkedin',
//       title: 'Linkedin Post',
//       selected: false,
//       icon: <Linkedin />,
//     },
//     {
//       id: 'instagram',
//       title: 'Instagram Post',
//       selected: false,
//       icon: <Instagram />,
//     },
//     {
//       id: 'medium',
//       title: 'Medium Article',
//       selected: false,
//       icon: <Other />,
//     },
//     {
//       id: 'twitter',
//       title: 'Twitter Thread',
//       selected: false,
//       icon: <X />,
//     },
//     {
//       id: 'youtube',
//       title: 'Youtube Script',
//       selected: false,
//       icon: <Youtube />,
//     },
//     {
//       id: 'blog',
//       title: 'Blog Post',
//       selected: false,
//       icon: <Blog />,
//     },
//     {
//       id: 'case',
//       title: 'Case Study',
//       selected: false,
//       icon: <CaseStudy />,
//     },
//     {
//       id: 'guides',
//       title: 'Guide',
//       selected: false,
//       icon: <Guide />,
//     },
//     {
//       id: 'quiz',
//       title: 'Quiz',
//       selected: false,
//       icon: <Quiz />,
//     },
//   ];

//   const [tagState, setTagState] = useState(
//     tags.map((tag) => ({
//       ...tag,
//       selected:
//         Array.isArray(onboardingData.content_types) &&
//         onboardingData.content_types.includes(tag.title),
//     })),
//   );

//   const validationSchema = Yup.object().shape({
//     start_date: Yup.date().required('Start date is required'),
//     end_date: Yup.date()
//       .required('End date is required')
//       .min(Yup.ref('start_date'), 'End date must be after start date'),
//   });

//   const handleTagSelection = (selectedTag) => {
//     setTagState((prevTags) =>
//       prevTags.map((tag) =>
//         tag.id === selectedTag.id ? { ...tag, selected: !tag.selected } : tag,
//       ),
//     );
//   };

//   const handleNext = async (values) => {
//     const selectedTagIds = tagState
//       .filter((tag) => tag.selected)
//       .map((tag) => tag.title);

//     const serializedValues = {
//       ...values,
//       start_date: values.start_date
//         ? new Date(values.start_date).toISOString()
//         : '',
//       end_date: values.end_date ? new Date(values.end_date).toISOString() : '',
//       content_types: selectedTagIds,
//     };
//     try {
//       const body = {
//         start_date: serializedValues.start_date,
//         end_date: serializedValues.end_date,
//         content_types: serializedValues.content_types,
//       };
//       setHeadlines(body);
//       dispatch(updateStep4(serializedValues));
//       dispatch(nextStep());
//     } catch (error) {
//       errorToast('Error generating headlines:', error);
//     }
//   };

//   const handleBack = (values) => {
//     const selectedTagIds = tagState
//       .filter((tag) => tag.selected)
//       .map((tag) => tag.title);

//     const serializedValues = {
//       ...values,
//       start_date: values.start_date
//         ? new Date(values.start_date).toISOString()
//         : '',
//       end_date: values.end_date ? new Date(values.end_date).toISOString() : '',
//       content_types: selectedTagIds,
//     };
//     dispatch(updateStep4(serializedValues));
//     dispatch(prevStep());
//   };

//   const initialValues = {
//     start_date: onboardingData.start_date
//       ? new Date(onboardingData.start_date)
//       : '',
//     end_date: onboardingData.end_date ? new Date(onboardingData.end_date) : '',
//     content_types: onboardingData.content_types || [],
//   };

//   return (
//     <div>
//       <Formik
//         initialValues={initialValues}
//         // validationSchema={validationSchema}
//         validateOnMount={true}
//         enableReinitialize={true}
//         onSubmit={handleNext}
//       >
//         {({ values, setFieldValue, isValid, dirty, submitForm }) => {
//           const handleClick = () => {
//             submitForm();
//           };
//           return (
//             <>
//               <Form
//                 onSubmit={(e) => {
//                   e.preventDefault();
//                 }}
//                 className={Styles.form4_wrapper}
//               >
//                 <div className={Styles.form4_wrapper__tags_container}>
//                   <label
//                     htmlFor=""
//                     className={Styles.form4_wrapper__tags_container__label}
//                   >
//                     Please let us us know which type of content you want to
//                     create.
//                   </label>
//                   <div className={Styles.form4_wrapper__tags_container__outer}>
//                     {tagState?.map((tag) => (
//                       <Tag
//                         key={tag.id}
//                         tag={tag}
//                         setTags={setTagState}
//                         tags={tagState}
//                         // maxTags={3}
//                         Tagclasses={
//                           Styles.form4_wrapper__tags_container__outer__tag
//                         }
//                         iconLeft={tag.icon}
//                         selected={tag.selected}
//                         onClick={() => handleTagSelection(tag)}
//                       />
//                     ))}
//                   </div>
//                 </div>
//                 <div className={Styles.form4_wrapper__timeframe}>
//                 </div>
//                 <ButtonSection
//                   btn_1="Generate Plan"
//                   btn_2="Back to previous step"
//                   isValid={true}
//                   handleNext={handleClick}
//                   handleBack={() => handleBack(values)}
//                 />
//               </Form>
//             </>
//           );
//         }}
//       </Formik>
//     </div>
//   );
// };

// export default Step4;

import React, { useState, useEffect } from 'react';
import Styles from './Styles.module.scss';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import Tag from 'Components/tag/Tag';
import { ReactComponent as Instagram } from '../../../../assets/svg/instagram.svg';
import { ReactComponent as Other } from '../../../../assets/svg/Group.svg';
import { ReactComponent as X } from '../../../../assets/svg/x.svg';
import { ReactComponent as Linkedin } from '../../../../assets/svg/linkedin.svg';
import { ReactComponent as Youtube } from '../../../../assets/svg/youtube.svg';
import { ReactComponent as Blog } from '../../../../assets/svg/blog.svg';
import { ReactComponent as CaseStudy } from '../../../../assets/svg/case-study.svg';
import { ReactComponent as Guide } from '../../../../assets/svg/Guide.svg';
import { ReactComponent as Quiz } from '../../../../assets/svg/quiz.svg';
import { ReactComponent as TikTok } from '../../../../assets/svg/tik-tok.svg';
import FormikDateField from 'Components/Form/FormikDateField';
import ButtonSection from '../ButtonSection/ButtonSection';
import { useDispatch, useSelector } from 'react-redux';
import { nextStep, prevStep, updateStep4 } from 'redux/slices/newOnboarding';
import * as Yup from 'yup';
import { generateHeadlines } from 'services/projectService';
import { error as errorToast, success as successToast } from 'helpers/toaster';

const Step4 = ({ setHeadlines }) => {
  const dispatch = useDispatch();
  const onboardingData = useSelector((state) => state.newOnboarding.step4);

  const tags = [
    {
      id: 'linkedin',
      title: 'Linkedin Post',
      selected: false,
      icon: <Linkedin />,
    },
    {
      id: 'instagram',
      title: 'Instagram Carousel',
      selected: false,
      icon: <Instagram />,
    },
    {
      id: 'instagram-post',
      title: 'Instagram Post',
      selected: false,
      icon: <Instagram />,
    },
    {
      id: 'instagram-reel',
      title: 'Instagram Reel',
      selected: false,
      icon: <Instagram />,
    },
    {
      id: 'medium',
      title: 'Medium Article',
      selected: false,
      icon: <Other />,
    },
    {
      id: 'twitter',
      title: 'Twitter Thread',
      selected: false,
      icon: <X />,
    },
    {
      id: 'youtube',
      title: 'Youtube Script',
      selected: false,
      icon: <Youtube />,
    },
    {
      id: 'blog',
      title: 'Blog Post',
      selected: false,
      icon: <Blog />,
    },
    {
      id: 'case',
      title: 'Case Study',
      selected: false,
      icon: <CaseStudy />,
    },
    {
      id: 'guides',
      title: 'Guide',
      selected: false,
      icon: <Guide />,
    },
    {
      id: 'quiz',
      title: 'Quiz',
      selected: false,
      icon: <Quiz />,
    },
    {
      id: 'tiktok-video',
      title: 'TikTok Video',
      selected: false,
      icon: <TikTok />,
    },
  ];

  const [tagState, setTagState] = useState(
    tags.map((tag) => ({
      ...tag,
      selected:
        Array.isArray(onboardingData.content_types) &&
        onboardingData.content_types.includes(tag.title),
    })),
  );

  const validationSchema = Yup.object().shape({
    start_date: Yup.date().required('Start date is required'),
    end_date: Yup.date()
      .required('End date is required')
      .min(Yup.ref('start_date'), 'End date must be after start date'),
  });

  const handleTagSelection = (selectedTag) => {
    setTagState((prevTags) =>
      prevTags.map((tag) =>
        tag.id === selectedTag.id ? { ...tag, selected: !tag.selected } : tag,
      ),
    );
  };

  const handleNext = async (values) => {
    const selectedTagIds = tagState
      .filter((tag) => tag.selected)
      .map((tag) => tag.title);

    const serializedValues = {
      ...values,
      start_date: values.start_date
        ? new Date(values.start_date).toISOString()
        : '',
      end_date: values.end_date ? new Date(values.end_date).toISOString() : '',
      content_types: selectedTagIds,
    };
    try {
      const body = {
        start_date: serializedValues.start_date,
        end_date: serializedValues.end_date,
        content_types: serializedValues.content_types,
      };
      setHeadlines(body);
      dispatch(updateStep4(serializedValues));
      dispatch(nextStep());
    } catch (error) {
      errorToast('Error generating headlines:', error);
    }
  };

  const handleBack = (values) => {
    const selectedTagIds = tagState
      .filter((tag) => tag.selected)
      .map((tag) => tag.title);

    const serializedValues = {
      ...values,
      start_date: values.start_date
        ? new Date(values.start_date).toISOString()
        : '',
      end_date: values.end_date ? new Date(values.end_date).toISOString() : '',
      content_types: selectedTagIds,
    };
    dispatch(updateStep4(serializedValues));
    dispatch(prevStep());
  };

  const initialValues = {
    start_date: onboardingData.start_date
      ? new Date(onboardingData.start_date)
      : '',
    end_date: onboardingData.end_date ? new Date(onboardingData.end_date) : '',
    content_types: onboardingData.content_types || [],
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={handleNext}
      >
        {({ values, setFieldValue, isValid, dirty, submitForm }) => {
          const handleClick = () => {
            submitForm();
          };
          return (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className={Styles.form4_wrapper}
              >
                <div className={Styles.form4_wrapper__tags_container}>
                  <label
                    htmlFor=""
                    className={Styles.form4_wrapper__tags_container__label}
                  >
                    Please let us us know which type of content you want to
                    create.
                  </label>
                  <div className={Styles.form4_wrapper__tags_container__outer}>
                    {tagState?.map((tag) => (
                      <Tag
                        key={tag.id}
                        tag={tag}
                        setTags={setTagState}
                        tags={tagState}
                        // maxTags={3}
                        Tagclasses={
                          Styles.form4_wrapper__tags_container__outer__tag
                        }
                        iconLeft={tag.icon}
                        selected={tag.selected}
                        onClick={() => handleTagSelection(tag)}
                      />
                    ))}
                  </div>
                </div>
                <div className={Styles.form4_wrapper__timeframe}></div>
                <ButtonSection
                  btn_1="Generate Plan"
                  btn_2="Back to previous step"
                  isValid={true}
                  handleNext={handleClick}
                  handleBack={() => handleBack(values)}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Step4;
