import React, { useState, useEffect } from 'react';
import StyleTable from './TableHead.module.scss';
import { DropdownButton } from 'react-bootstrap';
import BSButton from 'Components/Button/BSButton';
import CustomCheckbox from 'Components/Form/CustomCheckbox';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { TbSettings } from 'react-icons/tb';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { cloneDeep } from 'lodash';

function TableHead({ bg, TableHeadings, selectedColumns, updateColumns, onSort, currentSort }) {
  const [updatedColumns, setUpdatedColumns] = useState();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const columnsData = [
    { id: 'deadline', label: 'Deadline' },
    { id: 'assignedTo', label: 'Assigned to' },
    { id: 'status', label: 'Status' },
    { id: 'contentType', label: 'Content Type' },
  ];

  // Determine if column is sortable
  const isSortableColumn = (columnId) => {
    return ['deadline', 'status', 'contentType'].includes(columnId);
  };

  // Get sort icon for column
  const getSortIcon = (columnId) => {
    if (!isSortableColumn(columnId)) return null;
    
    if (currentSort && currentSort.column === columnId) {
      return currentSort.direction === 'asc' ? (
        <FaSortUp className="ms-2" size={12} />
      ) : (
        <FaSortDown className="ms-2" size={12} />
      );
    }
    
    return <FaSort className="ms-2" size={12} opacity={0.5} />;
  };

  // Handle column header click for sorting
  const handleSortClick = (columnId) => {
    if (!isSortableColumn(columnId) || !onSort) return;
    
    let direction = 'asc';
    if (currentSort && currentSort.column === columnId) {
      // Toggle direction if already sorting by this column
      direction = currentSort.direction === 'asc' ? 'desc' : 'asc';
    }
    
    onSort(columnId, direction);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newOrder = Array.from(updatedColumns);
    const [removed] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, removed);
    setUpdatedColumns(cloneDeep(newOrder));
  };

  const handleCancelClick = () => {
    setDropdownVisible(false);
  };

  const handleUpdateClick = async () => {
    updateColumns(cloneDeep(updatedColumns));
    setDropdownVisible(false);
  };

  useEffect(() => {
    setUpdatedColumns(cloneDeep(selectedColumns));
  }, [dropdownVisible, selectedColumns]);

  return selectedColumns ? (
    <thead className={`${bg && 'bg-thead'}`}>
      <tr className={StyleTable.table_header}>
        <th className={`${StyleTable.customHeader}`} key={'name'}>
          <div className={`${StyleTable.TableHeadTitle}`}>Brief</div>
        </th>
        {selectedColumns
          .filter((c) => c[1])
          .map((c) => c[0])
          .map((column) => {
            const title = TableHeadings.find(
              (heading) => heading.id === column,
            );
            if (!title) {
              return <></>;
            }
            return (
              <th 
                className={`${StyleTable.customHeader} ${isSortableColumn(column) ? StyleTable.sortableColumn : ''}`} 
                key={title.id}
                onClick={() => handleSortClick(column)}
                style={isSortableColumn(column) ? { cursor: 'pointer' } : {}}
              >
                <div className={`${StyleTable.TableHeadTitle} d-flex align-items-center`}>
                  {title.icon && <span className="me-2">{title.icon}</span>}
                  <span>{title.title}</span>
                  {getSortIcon(column)}
                </div>
              </th>
            );
          })}
        {updateColumns && (
          <th className={`${StyleTable.customHeader}`} key={'draggable'}>
            <div
              className={`${StyleTable.TableHeadTitle} d-flex justify-content-end`}
            >
              <DropdownButton
                title={
                  <TbSettings
                    color="#101828"
                    size={16}
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                  />
                }
                show={dropdownVisible}
              >
                <div className={StyleTable.outer_container}>
                  <p className={StyleTable.outer_container__title}>
                    Hide Columns
                  </p>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                          }}
                        >
                          {updatedColumns.map((uColumn, index) => {
                            const column = columnsData.find(
                              (cd) => cd.id === uColumn[0],
                            );
                            return (
                              <Draggable
                                key={column.id}
                                draggableId={column.id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="d-flex align-items-center gap-3"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <RxDragHandleDots2 />
                                    <CustomCheckbox
                                      id={column.id}
                                      label={column.label}
                                      checked={uColumn[1]}
                                      onChange={() => {
                                        setUpdatedColumns((prevColumns) => {
                                          prevColumns.forEach((prevColumn) => {
                                            if (prevColumn[0] === column.id) {
                                              prevColumn[1] = !prevColumn[1];
                                            }
                                          });
                                          return cloneDeep(prevColumns);
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <div className="d-flex justify-content-end align-items-center gap-2 mt-3 w-100">
                    <BSButton
                      variant="light"
                      ButtonText={'Cancel'}
                      onClick={handleCancelClick}
                      classList={'btn button white_btn'}
                    />
                    <BSButton
                      variant=""
                      ButtonText={'Update'}
                      onClick={handleUpdateClick}
                      classList={'btn button secondary_btn'}
                    />
                  </div>
                </div>
              </DropdownButton>
            </div>
          </th>
        )}
      </tr>
    </thead>
  ) : (
    <thead className={bg && 'bg-thead'}>
      <tr>
        {TableHeadings.map((title) => (
          <th 
            className={`${StyleTable.customHeader} ${isSortableColumn(title.id) ? StyleTable.sortableColumn : ''}`} 
            key={title.id}
            onClick={() => handleSortClick(title.id)}
            style={isSortableColumn(title.id) ? { cursor: 'pointer' } : {}}
          >
            <div className={`${StyleTable.TableHeadTitle} d-flex align-items-center`}>
              {title.icon && <span className="me-2">{title.icon}</span>}
              <span>{title.title}</span>
              {getSortIcon(title.id)}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
}

export default TableHead;