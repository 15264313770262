// services/anthropicService.js

/**
 * Service to interact with Claude via our backend API
 * Simplified version that doesn't use streaming
 */
export const generateCampaignTitleWithClaude = async (campaignDetails) => {
    try {
      const { description, goal, channels, brand } = campaignDetails;
      
      // Format the prompt for Claude
      const systemPrompt = 
        "You are a marketing campaign title generator. Generate a short, catchy, and professional title (5-7 words max) for a marketing campaign. The title should be creative, memorable, and reflect the campaign's purpose. Use wordplay, alliteration, or metaphors when appropriate. Return ONLY the title with no additional text, explanations, or formatting.";
      
      const userPrompt = `
      Create a title for this marketing campaign:
      
      ${description ? `Description: ${description}` : ''}
      ${goal ? `Goal: ${goal}` : ''}
      ${channels && channels.length > 0 ? `Channels: ${channels.join(', ')}` : ''}
      ${brand ? `Brand: ${brand}` : ''}
      
      Remember to generate ONLY the title itself. No introduction, explanation, or additional text.
      `;
      
      console.log("Making API call for title generation");
      
      // Make a direct fetch call
      const response = await fetch('/api/v1/assistant_completion_stream_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt: userPrompt,
          system_prompt: systemPrompt,
          max_token: 100,
          ai_model: 'claude-3-7-sonnet-20250219'
        })
      });
      
      console.log("API response status:", response.status);
      
      if (!response.ok) {
        console.error("API error:", response.status);
        return null;
      }
      
      // Read the response as text
      const text = await response.text();
      console.log("Raw API response:", text);
      
      // Clean up the response
      const cleanTitle = cleanupTitle(text);
      console.log("Cleaned title:", cleanTitle);
      
      return cleanTitle;
    } catch (error) {
      console.error('Error in title generation:', error);
      return null;
    }
  };
  
  /**
   * Helper function to clean up the title text
   */
  function cleanupTitle(text) {
    // Start with the raw text
    let cleanTitle = text.trim();
    
    // Extract just the first meaningful line if there are multiple lines
    if (cleanTitle.includes('\n')) {
      const lines = cleanTitle.split('\n')
        .map(line => line.trim())
        .filter(line => line.length > 0);
      
      if (lines.length > 0) {
        cleanTitle = lines[0];
      }
    }
    
    // Parse SSE format if present
    if (cleanTitle.includes('data:')) {
      cleanTitle = cleanTitle
        .split('\n')
        .filter(line => line.startsWith('data:'))
        .map(line => line.substring(5).trim())
        .join(' ');
    }
    
    // Remove any "Title:" prefixes that might be in the response
    if (cleanTitle.toLowerCase().startsWith('title:')) {
      cleanTitle = cleanTitle.substring(6).trim();
    }
    
    // Remove any quotation marks
    cleanTitle = cleanTitle.replace(/["']/g, '');
    
    // If the title is too long (more than 10 words), truncate it
    const words = cleanTitle.split(' ');
    if (words.length > 10) {
      cleanTitle = words.slice(0, 7).join(' ') + '...';
    }
    
    return cleanTitle || 'New Marketing Campaign';
  }