import React, { useState, useEffect } from 'react';
import Styles from './OrderSummary.module.scss';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { CiLock } from 'react-icons/ci';
import { CouponElement } from 'Containers/Subscription/CouponElement';
import { removeRedundantDecimalPlaces } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'redux/slices/loader';
import { toast } from 'react-toastify';
import { updateSubscription, startTrial } from 'services/subscriptionService';
import { error as renderError } from 'helpers/toaster.js';
import { useNavigate } from 'react-router-dom';
import { CONFIRM_SUBSCRIPTION } from 'constants';
import ConfirmationModal from 'Containers/Modal/ConfirmationModal/ConfirmationModal';
import moment from 'moment';
import { actions } from 'redux/slices/auth';
import { applyCouponDiscount } from 'services/subscriptionService';

const OrderSummary = ({
  newSubscription,
  setIsDisabledSwitch,
  refetch,
  isTrial = false,
  product = {},
  setOrderSummary,
  paymentMethodData,
  planId,
  selectedPlanType,
}) => {
  const [couponName, setCouponName] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [showCouponForm, setShowCouponForm] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const { subscriptions } = user ?? {};
  const {
    current_subscription: currentSubscription,
    last_subscription: lastSubscription,
  } = subscriptions ?? {};
  const { end_date: endDate } = currentSubscription ?? {};

  const finalPrice =
    discountPercentage > 0 ? discountedPrice : newSubscription?.amount;

  const isMonthly = newSubscription?.interval === 'month';
  const isYearly = !isMonthly;

  function getCookie(name) {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [key, value] = cookie.split('=');
      if (key === name) {
        return decodeURIComponent(value);
      }
    }
    return null;
  }
  const applyCoupon = async (couponName, priceId) => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Applying coupon',
        description: 'Please wait...',
      }),
    );
    try {
      const response = await applyCouponDiscount(couponName, priceId);
      const responseData = response.data;
      if (responseData.valid) {
        setDiscountedPrice(responseData.discounted_price);
        setDiscountPercentage(responseData.discount_percentage);
        toast.success('Coupon applied successfully');
      } else {
        toast.error(responseData.message);
      }
      setShowCouponForm(true);
      setIsDisabledSwitch(false);
    } catch (error) {
      toast.error('Oops! An error occurred while applying the coupon', error);
    }
    dispatch(setLoader({ isLoading: false }));
  };

  useEffect(() => {
    const rewardfulCookie = getCookie('rewardful.referral');
    if (rewardfulCookie) {
      try {
        // Parse JSON string
        const parsedData = JSON.parse(rewardfulCookie);
        setCouponName(parsedData?.coupon?.name);
        console.log(parsedData);
        toggleCouponForm();
        parsedData?.coupon?.name &&
          applyCoupon(parsedData?.coupon?.name, newSubscription?.id);
      } catch (error) {
        console.error('Error parsing cookie:', error);
      }
    }
  }, []);

  const handleSubscribe = async () => {
    const { id, interval } = newSubscription || {};
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Subscribing Plan!',
          description: 'Please wait...',
        }),
      );

      let subscription = await updateSubscription(
        id,
        couponName,
        discountedPrice,
      );
      window.rewardful('convert', { email: user.email });
      // dispatch(
      //   actions.updateUser({ user: { subscriptions: { currentSubscription: subscription?.data?.subscription } } }),
      // );
      // fetchPlansData();
      refetch();
      toast.success('Plan Subscribed Successfully');
      dispatch(setLoader({ isLoading: false }));
      if (
        !currentSubscription &&
        lastSubscription?.active === false &&
        lastSubscription?.status === 'canceled'
      ) {
        navigate(
          `/resubscribe-confirmation/plan/${newSubscription?.type}/price/${finalPrice}/type/${newSubscription?.interval}`,
        );
      } else {
        navigate(
          `/${CONFIRM_SUBSCRIPTION}/plan/${newSubscription?.type}/price/${finalPrice}/type/${interval}`,
        );
      }
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      renderError(error);
    }
  };

  const initiateTrial = async (product) => {
    // const { id, type, amount, interval } = product || {};
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Starting Trial!',
          description: 'Please wait...',
        }),
      );
      await startTrial(planId, couponName);
      window.rewardful('convert', { email: user.email });

      // fetchPlansData();
      refetch();
      dispatch(setLoader({ isLoading: false }));
      toast.success('Free Trial Started Successfully');
      setOrderSummary &&
        setOrderSummary({
          planType: newSubscription?.type,
          finalPrice,
          interval,
        });
      // dispatch(setCurrentStep(6))
      navigate(
        `/summary/plan/${newSubscription?.type}/price/${finalPrice}/type/${newSubscription?.interval}?trial=${isTrial}`,
      );
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      renderError(error);
    }
  };

  const getModalDescription = () => {
    if (isTrial) {
      return "Your 7-days free trial will start today where you can explore all features. You won't be charged during the trial, and you can cancel anytime before it ends.";
    } else if (
      currentSubscription?.active &&
      currentSubscription?.status === 'trial'
    ) {
      return "Your trial will end immediately. Your new plan will start right away, and you'll be charged for it.";
    } else if (
      currentSubscription?.active &&
      planId !== currentSubscription?.stripe_price?.id
    ) {
      return 'Your plan will be upgraded. Your current plan ends today, and the new plan starts immediately with an adjusted billing cycle. The payment will be prorated accordingly, reflecting the cost of the new plan.';
    } else if (
      currentSubscription?.active &&
      planId === currentSubscription?.stripe_price?.id &&
      selectedPlanType === currentSubscription?.stripe_price?.interval
    ) {
      return 'You plan will be resubscribed. Your previous subscription resumes seamlessly, and billing will continue as scheduled.';
    } else if (
      currentSubscription?.active &&
      planId === currentSubscription?.stripe_price?.id &&
      selectedPlanType !== currentSubscription?.stripe_price?.interval
    ) {
      return 'Your plan will be upgraded. Your current plan ends today, and the new plan starts immediately with an adjusted billing cycle. The payment will be prorated accordingly, reflecting the cost of the new plan.';
    } else {
      return 'Are you sure you want to proceed with the payment for your subscription? Please review the details carefully, as this action will be final and cannot be undone.';
    }
  };

  const handlePayment = () => {
    if (isTrial) {
      initiateTrial(product);
    } else {
      handleSubscribe();
    }
  };

  const toggleCouponForm = () => {
    setShowCouponForm(!showCouponForm);
  };

  const handleCancel = () => {
    setShowCouponForm(false);
    setCouponName('');
    setDiscountedPrice('');
  };
  return (
    <div>
      <div className={Styles.card_container}>
        <div className={Styles.card_container__heading}>ORDER SUMMARY</div>
        <div className={Styles.card_container__top}>
          <div className={Styles.card_container__top__detail}>
            <span className={Styles.card_container__top__detail__heading}>
              {newSubscription?.type}
            </span>
            <span className={Styles.card_container__top__detail__amount}>
              ${removeRedundantDecimalPlaces(newSubscription?.amount)}
            </span>
          </div>
          <div className={Styles.card_container__top__detail}>
            <span className={Styles.card_container__top__detail__heading}>
              Discount
            </span>
            <span className={Styles.card_container__top__detail__amount}>
              {removeRedundantDecimalPlaces(parseFloat(discountPercentage))}%
            </span>
          </div>
        </div>
        <div className={Styles.card_container__total}>
          <span className={Styles.card_container__total__heading}>
            Total
            {isYearly &&
              ` ($${removeRedundantDecimalPlaces(finalPrice / 12)} x 12mo)`}
          </span>
          <span className={Styles.card_container__total__amount}>
            ${removeRedundantDecimalPlaces(finalPrice)}
          </span>
        </div>
        <div
          className={`${Styles.card_container__coupan} flex-column align-items-start`}
        >
          {discountPercentage === 0 && (
            <div className="d-flex w-100 justify-content-between">
              <span className={Styles.card_container__coupan__heading}>
                Do you have any coupon?
              </span>
              <button
                className={Styles.card_container__coupan__btn}
                onClick={() => {
                  showCouponForm ? handleCancel() : toggleCouponForm();
                }}
              >
                {showCouponForm ? 'Cancel' : 'Add Coupon'}
              </button>
            </div>
          )}

          {showCouponForm && (
            <div className="d-flex flex-column gap-3 w-100">
              <CouponElement
                couponName={couponName}
                setCouponName={setCouponName}
                setDiscountedPrice={setDiscountedPrice}
                setShowCouponForm={setShowCouponForm}
                setDiscountPercentage={setDiscountPercentage}
                priceId={newSubscription?.id}
                setIsDisabledSwitch={setIsDisabledSwitch}
              />
            </div>
          )}
          {discountPercentage > 0 && (
            <div>
              <p className={`${Styles.textAppiedCoupan} mr-8 d-inline-block `}>
                <span className={Styles.coupanValue}>{couponName}</span> Coupon
                code applied.
              </p>
            </div>
          )}
        </div>
        <div className={Styles.card_container__pay_btn}>
          <button
            disabled={!paymentMethodData || paymentMethodData.length === 0}
            className={`w-100 px-3 py-2 ${Styles.black_btn}`}
            onClick={() => setCancelModalOpen(true)} // uncomment this to show pop up
            // onClick={() => handlePayment()}
          >
            {isTrial ? 'Start Free Trial' : `Pay $${finalPrice}`}
            {/* {isTrial ? 'Start Free Trial' : currentSubscription?.active && currentSubscription?.status === 'trial' ? 'Change trial plan' 
            : currentSubscription?.active && planId !== currentSubscription?.stripe_price?.id ? "Upgrade" 
            : currentSubscription?.active && planId === currentSubscription?.stripe_price?.id && selectedPlanType ===  currentSubscription?.stripe_price?.interval ? "Resubscribe" 
            : currentSubscription?.active && planId === currentSubscription?.stripe_price?.id && selectedPlanType !==  currentSubscription?.stripe_price?.interval ? "Upgrade" 
            : `Pay $${finalPrice}`}  */}
          </button>
        </div>
        <div className={Styles.card_container__footer}>
          <div>
            <HiOutlineExclamationCircle />
          </div>
          <span>
            {isTrial
              ? 'Your trial period starts now, and you will only be billed once the trial is over. Enjoy exploring Vocable!'
              : `You will be charged every month until canceled. The next payment will be charged at ${moment(
                  endDate ?? new Date(),
                )
                  .add(endDate ? 0 : 1, isMonthly ? 'months' : 'years')
                  .format('MM/DD/YYYY')}`}
            .
          </span>
        </div>
      </div>
      <div className={Styles.footer}>
        <div>
          <CiLock />
        </div>
        <span className={Styles.footer__text}>
          Your payment data will be processed and{' '}
          <span className={Styles.footer__text__underline}>
            secured by Stripe
          </span>
          <span>{` `} </span>and their stringent level of certification.
        </span>
      </div>
      <ConfirmationModal
        show={cancelModalOpen}
        onHide={() => setCancelModalOpen(false)}
        onDelete={handlePayment}
        modalHeading="Confirm Subscription"
        modalDescription={getModalDescription()}
        actionText="Proceed"
        loaderHeading="Processing!"
      />
    </div>
  );
};

export default OrderSummary;
