import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import sc from './CreateTeamModal.module.scss';
import FormikTextField from 'Components/Form/FormikTextField';
import ImageView from 'Components/Image/ImageView';
import BSButton from 'Components/Button/BSButton';
import { useDispatch } from 'react-redux';
import { setLoader } from 'redux/slices/loader';
import * as Yup from 'yup';
import { getNameInitials } from 'helpers';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import { createTeam, updateTeam } from 'services/teamService';
import { FiPlus } from 'react-icons/fi';

const CreateTeamModal = (props) => {
  const { team } = props || {};
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(team.avatar);
  const [showChangeProfile, setShowChangeProfile] = useState(false);
  const [ImageFile, setImageFile] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);

  const initialValues = {
    title: team?.title,
    description: team?.description,
  };

  const validationSchema = Yup.object({
    title: Yup.string().trim().required('Team name is required.'),
    description: Yup.string().trim().required('Description is required.'),
  });

  const handleSubmit = async (values) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Saving!',
          description: 'Please wait...',
        }),
      );

      const avatar = imageChanged ? ImageFile : {};
      const teamPayload = {
        title: values.title,
        description: values.description,
        avatar,
      };

      if (team.id) {
        const res = await updateTeam(team.id, teamPayload);
        renderSuccess(res?.data?.message);
      } else {
        const res = await createTeam(teamPayload);
        renderSuccess(res?.data?.message);
      }

      dispatch(setLoader({ isLoading: false }));
      props.fetchTeams();
      props.onHide();
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      renderError(error);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedImage(reader.result);
      setImageChanged(true);
      setImageFile(file);
    };
  };

  return (
    <Modal
      {...props}
      className={sc.Modal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className={`${sc.fontsPoppins} Modal-Title `}
          id="contained-modal-title-vcenter"
        >
          {team.id ? 'Update' : 'Create'} Team
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          onMouseEnter={() => {
            setShowChangeProfile(true);
          }}
          onMouseLeave={() => {
            setShowChangeProfile(false);
          }}
          className={'d-flex position-relative ' + sc.ProfileImageSection}
        >
          {selectedImage ? (
            <ImageView
              class={'img-fluid  ' + sc.profileImage}
              alt="Profile Image"
              src={selectedImage}
            />
          ) : (
            <span className={'rounded-profile ' + sc.profileImage}>
              {getNameInitials(team.title)}
            </span>
          )}
          <input
            className={sc.profileImage_Input}
            type="file"
            name="myImage"
            onChange={handleImageChange}
            accept="image/*"
          />
          {showChangeProfile && (
            <div
              className={`d-flex justify-content-center align-items-center  ${sc.profileImage_Input} ${sc.profileImage_Input_text}`}
            >
              {selectedImage ? (
                'Change Avatar'
              ) : (
                <FiPlus color="#00A7B7" size={20} />
              )}
            </div>
          )}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {({ values, isValid, setErrors, errors, dirty }) => {
            return (
              <section>
                <Form
                  className="d-flex pt-4 flex-column gap-4"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div>
                    <FormikTextField
                      Label="Team Name"
                      isRequired={true}
                      name="title"
                      placeholder="Enter team name"
                      classList="w-100 input"
                      onKeyDown={(e) => {
                        e.key === 'Enter' && e.preventDefault();
                      }}
                    ></FormikTextField>
                    <ErrorMessage name="title">
                      {(msg) => <span className="error">{msg}</span>}
                    </ErrorMessage>
                  </div>
                  <div>
                    <FormikTextField
                      Label="Description"
                      name="description"
                      isRequired={true}
                      placeholder="Add team description here..."
                      classList="w-100 input"
                      onKeyDown={(e) => {
                        e.key === 'Enter' && e.preventDefault();
                      }}
                    ></FormikTextField>
                    <ErrorMessage name="description">
                      {(msg) => <span className="error">{msg}</span>}
                    </ErrorMessage>
                  </div>
                  <Modal.Footer
                    className={'border-0 p-0 ' + sc.ProfileModalFooter}
                  >
                    <BSButton
                      onClick={props.onHide}
                      ButtonText="Cancel"
                      variant="light"
                      classList={'btn button cancel_btn'}
                    />
                    <BSButton
                      onClick={() => handleSubmit(values)}
                      ButtonText={team.id ? 'Update' : 'Create'}
                      variant="dark"
                      classList={'btn button secondary_btn'}
                      disabled={
                        !(ImageFile != null || (dirty && isValid)) ||
                        !(values.description && values.title)
                      }
                    />
                  </Modal.Footer>
                </Form>
              </section>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateTeamModal;
