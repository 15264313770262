import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { ROUTES } from 'constants/routes';
import { getNameInitials } from 'helpers';
import ImageView from 'Components/Image/ImageView';
import ContentPlanner from 'Containers/Content Planner/ContentPlanner';
import Documents from 'Containers/Documents/Documents';
import Integrations from 'Containers/Integrations/Integrations';
import StyleGuide from 'Containers/StyleGuide/StyleGuide';
import EditorAddModal from './EditorAddModal';
import EditorRemoveModal from './EditorRemoveModal';
import StyleClasses from './Projects.module.scss';
import ProjectOverview from './ProjectOverview';
import { PROJECT_TABS } from 'constants/projects';
import useProject from 'hooks/projects/useProject';
import Loader from 'Components/Loader/Loader';
import Publish from 'Containers/Editor/Publish';
import ProjectTabs from './ProjectTabs/ProjectTabs';
import ProjectDrawer from './ProjectDrawer';
import { useSelector } from 'react-redux';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';

const ProjectDetail = () => {
  const { projectId, projectType, folderSlug } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const isContentTabOn = !!(projectType || folderSlug);

  const query = new URLSearchParams(search);
  const defaultTab = query?.get('tab');

  const [, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(
    defaultTab || PROJECT_TABS.plan,
  );
  const [editorRemoveModalOpen, setEditorRemoveModalOpen] = useState(false);
  const [editorAddModalOpen, setEditorAddModalOpen] = useState(false);
  const [projectDrawerView, setProjectDrawerView] = useState(false);

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { id: userId, subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  const { project, isLoading, refetch } = useProject({ projectId });

  useEffect(() => {
    if (isContentTabOn) {
      setSelectedTab(PROJECT_TABS.content);
      setSearchParams({ tab: PROJECT_TABS.content });
    }
  }, [isContentTabOn]);

  const { editors } = project || {};
  const editorsLength = editors?.length || 0;
  const tabs = [
    // {
    //   key: 'overview',
    //   label: 'Overview',
    //   content: <ProjectOverview project={project} fetchProject={refetch} />,
    // },
    {
      key: 'plan',
      label: 'Plan',
      content: <ContentPlanner projectView={false} noMargin={true} />,
    },
    {
      key: 'content',
      label: 'Content',
      content: (
        <Documents
          projectView={false}
          projectType={projectType}
          noMargin={true}
        />
      ),
    },
    // {
    //   key: 'styleGuide',
    //   label: 'Style Guide',
    //   content: <StyleGuide projectId={projectId} />,
    // },
    // {
    //   key: 'channels',
    //   label: 'Channels',
    //   content: (
    //     <>
    //       <Integrations projectId={projectId} />
    //       {/* <Publish /> */}
    //     </>
    //   ),
    // },
  ];

  return (
    <>
      {isLoading ? (
        <Loader inline loaderHeading="Fetching Campaign..." />
      ) : (
        <>
          <div className={StyleClasses.ProjectDetail_titleContainer}>
            <div className={StyleClasses.ProjectDetail_titleContainer_arrow}>
              <FiArrowLeft
                className={StyleClasses.ProjectDetail_titleContainer_arrow}
                size={24}
                color="#344054"
                onClick={() => navigate(ROUTES.Projects)}
              />
            </div>
            <div className={StyleClasses.ProjectDetail_titleContainer_title}>
              <p>{project?.name}</p>
              {/* <p
                className={
                  StyleClasses.ProjectDetail_titleContainer_description
                }
              >
                {project?.objective}
              </p> */}
            </div>

            <button
              onClick={
                currentSubscription?.plan_name === 'Basic Plan'
                  ? () => setShowUpgradeModal(true)
                  : () => setProjectDrawerView(true)
              }
              className="cancel_btn text-nowrap"
            >
              Edit Campaign
            </button>
            <div
              className="d-flex stacked-Images-rounded"
              style={{ alignItems: 'center' }}
            >
              <div
                className={StyleClasses.ProjectDetail_titleContainer_editors}
                onClick={() => setEditorRemoveModalOpen(true)}
              >
                {editors?.slice(0, 3)?.map(({ id, avatar, name: userName }) => {
                  return avatar ? (
                    <ImageView
                      key={id}
                      src={avatar}
                      class="rounded-avatar-bordered"
                    />
                  ) : (
                    <div
                      key={id}
                      className={`rounded-profile ${StyleClasses.avatar}`}
                    >
                      {getNameInitials(userName)}
                    </div>
                  );
                })}

                {editorsLength > 3 && (
                  <div
                    className={
                      StyleClasses.ProjectDetail_titleContainer_editorCount
                    }
                  >
                    {`+${editorsLength - 3}`}
                  </div>
                )}
              </div>

              <div
                className={StyleClasses.ProjectDetail_titleContainer_editorAdd}
                onClick={
                  currentSubscription?.plan_name === 'Basic Plan'
                    ? () => setShowUpgradeModal(true)
                    : () => setEditorAddModalOpen(true)
                }
              >
                +
              </div>
            </div>
          </div>

          <div className={`d-flex flex-column gap-2 mt-1`}>
            <div className={StyleClasses.Projects__Views}>
              <ProjectTabs tabs={tabs} />
            </div>
          </div>
        </>
      )}

      {editorRemoveModalOpen && (
        <EditorRemoveModal
          show={editorRemoveModalOpen}
          editors={editors}
          onHide={async () => {
            await refetch();
            setEditorRemoveModalOpen(false);
          }}
        />
      )}

      {editorAddModalOpen && (
        <EditorAddModal
          show={editorAddModalOpen}
          editors={editors}
          onHide={async () => {
            await refetch();
            setEditorAddModalOpen(false);
          }}
        />
      )}
      {projectDrawerView && (
        <ProjectDrawer
          show={projectDrawerView}
          onHide={() => {
            setProjectDrawerView(false);
          }}
          action="edit"
          project={project}
          // refreshDashboard={async () => {
          //   await fetchProject();
          // }}
        />
      )}
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}
    </>
  );
};

export default ProjectDetail;
