import React, { useState, useRef, useEffect } from 'react';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { LinkedInLoginButton } from 'react-social-login-buttons';
import {
  error as errorToast,
  success as successToast,
} from 'helpers/toaster.js';
import Modal from 'react-bootstrap/Modal';

import Styles from '../Socials.module.scss';
import { addLinkedinOrgs } from 'services/integrationService';

const LinkedinLoginForm = ({ onLoginSuccess }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to control dropdown visibility
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control dropdown visibility
  const [selectedOption, setSelectedOption] = useState(null); // State to store the selected option (Profile/Organizations)
  const [organizations, setOrganizations] = useState([]); // State to store organizations data
  const [selectedOrganizations, setSelectedOrganizations] = useState([]); // State for selected organizations
  const [hasInteracted, setHasInteracted] = useState(false); // Tracks user interaction
  const [orgToken, setOrgToken] = useState(''); // Tracks user interaction

  const dropdownRef = useRef(null); // Ref for the dropdown container

  const redirectURL = process.env.REACT_APP_LINKEDIN_OAUTH_REDIRECT_URI;
  const clientID = process.env.REACT_APP_LINKEDIN_OAUTH_CLIENT_ID;

  const handleLinkedInSuccess = async (code) => {
    onLoginSuccess();
    successToast('Integration Successfully added!');
  };

  const handleLinkedInFailure = (error) => {
    // errorToast('LinkedIn Login Failed:', error);
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Handle option selection from the dropdown
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  // Handle checkbox selection
  const handleCheckboxChange = (event, urn) => {
    console.log(selectedOrganizations);
    setSelectedOrganizations((prev) => {
      if (event.target.checked) {
        return [...prev, urn]; // Add URN if checked
      } else {
        return prev.filter((item) => item !== urn); // Remove URN if unchecked
      }
    });
    setHasInteracted(true);
  };
  // Handle the modal action (submit selected organizations)
  const handleModalSubmit = async () => {
    addLinkedinOrgs({
      token: orgToken,
      orgs: selectedOrganizations,
      redirect_uri: redirectURL,
    })
      .then((data) => {
        handleLinkedInSuccess();
        setIsModalOpen(false); // Close the modal after submission
      })
      .catch((error) => {
        const message =
          error.response.data.error?.message || error.response.data.error;
        successToast(message);
      });
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'LINKEDIN_SUCCESS') {
        if (event.data.payload.type === 'profile') {
          handleLinkedInSuccess();
        } else {
          const { organizations, token } = event.data.payload;
          setOrgToken(token);
          organizations && setOrganizations(organizations); // Update organizations state
          setIsModalOpen(true);
        }
        // Open the modal when organizations are received
      } else if (event.data.type === 'LINKEDIN_ERROR') {
        successToast(event.data.payload.message);
        console.error('LinkedIn error:', event.data.payload);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    console.log('Updated selected organizations:', selectedOrganizations);
  }, [selectedOrganizations, hasInteracted]);

  const handleCardClick = (urn) => {
    setSelectedOrganizations((prev) =>
      prev.includes(urn) ? prev.filter((item) => item !== urn) : [...prev, urn],
    );
  };

  return (
    <div>
      <img
        src={'/Images/logo-linkedin.png'}
        alt={'linkedin'}
        onClick={toggleDropdown}
        width="50"
        height="50"
      />

      {isDropdownOpen && (
        <div className={Styles.Dropdown} ref={dropdownRef}>
          <LinkedIn
            redirectUri={redirectURL + '?type=profile'}
            clientId={clientID}
            onSuccess={handleLinkedInSuccess}
            onError={handleLinkedInFailure}
            scope="openid email profile w_member_social"
            state="randomString"
          >
            {({ linkedInLogin }) => (
              <div
                className={Styles.DropdownItem}
                onClick={() => linkedInLogin()}
              >
                Profile
              </div>
            )}
          </LinkedIn>
          <LinkedIn
            redirectUri={redirectURL + '?type=org'}
            clientId={clientID}
            onSuccess={handleLinkedInSuccess}
            onError={handleLinkedInFailure}
            scope="r_organization_admin w_organization_social rw_organization_admin"
            state="randomString"
          >
            {({ linkedInLogin }) => (
              <div
                className={Styles.DropdownItem}
                onClick={() => linkedInLogin()}
              >
                Organizations
              </div>
            )}
          </LinkedIn>
        </div>
      )}

      {/* Modal to display organizations */}
      {
        <Modal
          show={isModalOpen}
          onHide={() => setIsModalOpen(false)}
          size="lg"
          centered
        >
          <Modal.Header className="border-0 pb-0" closeButton>
            <Modal.Title>Select Organizations</Modal.Title>
          </Modal.Header>
          <Modal.Body className="border-0 pb-0">
            {organizations.length === 0 ? (
              <p>
                You don't have any organizations associated with this account.
              </p>
            ) : (
              <div className="d-flex flex-column gap-3">
                <ul className="list-group">
                  {organizations.map((org) => {
                    const isSelected = selectedOrganizations.includes(org.$URN);
                    return (
                      <li
                        key={org.$URN}
                        className={`list-group-item d-flex justify-content-between align-items-center p-2 `}
                        style={{
                          cursor: 'pointer',
                          minWidth: '220px',
                          position: 'relative',
                        }}
                        onClick={() => handleCardClick(org.$URN)}
                      >
                        {/* Organization Info */}
                        <div>
                          <p className="mb-0">
                            {org.vanityName} (
                            <small>{org.organizationType}</small>)
                          </p>
                        </div>
                        <span
                          style={{
                            position: 'relative',
                            display: 'inline-block',
                            width: '50px', // Adjusted width
                            height: '24px', // Adjusted height
                            borderRadius: '12px', // Consistent border-radius for a toggle shape
                            border: '2px solid #00b7c3', // Matching the border color
                            backgroundColor: isSelected ? '#00b7c3' : 'white', // Matching toggle colors
                            transition: 'background-color 0.3s ease',
                            cursor: 'pointer', // Add a pointer cursor for better UX
                          }}
                        >
                          <span
                            style={{
                              position: 'absolute',
                              top: '50%', // Center vertically
                              left: isSelected ? '26px' : '2px', // Adjust circle position based on state
                              transform: 'translateY(-50%)', // Center the circle
                              width: '20px', // Adjusted circle size
                              height: '20px',
                              borderRadius: '50%',
                              backgroundColor: isSelected ? 'white' : '#00b7c3', // White circle when selected
                              transition:
                                'left 0.3s ease, background-color 0.3s ease', // Smooth transitions
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
                            }}
                          />
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="border-0  justify-content-center">
            <button
              className="btn btn-outline-success"
              disabled={selectedOrganizations.length === 0}
              onClick={handleModalSubmit}
            >
              Add Organizations({selectedOrganizations.length})
            </button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  );
};

export default LinkedinLoginForm;
