import { LINKEDIN_CALLBACK } from '../../../constants';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { postRequest } from '../../../helpers/axios';
import { postLinkedInIntegration } from 'services/integrationService';

const LinkedInCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');
    const type = queryParams.get('type');
    const redirectURI = process.env.REACT_APP_LINKEDIN_OAUTH_REDIRECT_URI;
    if (code) {
      postLinkedInIntegration({ code, redirect_uri: redirectURI, type })
        .then((data) => {
          if (type === 'org') {
            window.opener.postMessage(
              { type: 'LINKEDIN_SUCCESS', payload: data.data },
              '*',
            );
          } else {
            window.opener.postMessage(
              { type: 'LINKEDIN_SUCCESS', payload: { type: 'profile' } },
              '*',
            );
          }
          window.close();
        })
        .catch((error) => {
          console.error('Error fetching LinkedIn token:', error);

          // Send error back to parent window
          if (window.opener) {
            window.opener.postMessage(
              { type: 'LINKEDIN_ERROR', payload: error.response.data },
              '*',
            );
          }

          // Close the popup
          window.close();
        });
    }
  }, [location]);

  return <div>Processing LinkedIn Login...</div>;
};

export default LinkedInCallback;
