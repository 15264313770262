import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import Styles from './AIProjectPlan.module.scss';
import ProjectPlanCard from './ProjectPlanCard';
import {
  generateBriefs,
  generateHeadlines,
  saveBriefs,
} from 'services/projectService';
import { error as errorToast, success as successToast } from 'helpers/toaster';
import { sumDaysToDate } from 'helpers/dates';
import { ROUTES } from 'constants/routes';
import AIPlanLayout from './AIPlanLayout';
import Spinner from '../../Spinner/Spinner';
import BriefDrawer from 'Components/Drawers/BriefDrawer/BriefDrawer';
import ConfirmDeleteModal from 'Containers/Modal/ConfirmDeleteModal/ConfirmDeleteModal';
import { PROJECT_TABS } from 'constants/projects';
import { resetOnboarding } from 'redux/slices/newOnboarding';
import { useDispatch, useSelector } from 'react-redux';
import { success } from 'helpers/toaster';

const sanitizeBrief = ({ brief, projectId }) => {
  return {
    id: uuid(),
    title: brief['Topic/Headline'],
    description: brief.Description,
    deadline_date: sumDaysToDate(brief.Deadline),
    priority: 'medium',
    state: 'not_started',
    content_type: brief['Content Type'],
    keywords: brief['Keywords to Use'],
    project_id: projectId,
    content_structure: brief['Content Structure'],
    word_count: brief['Word Count'],
  };
};

const AIProjectPlan = ({ ...props }) => {
  // Destructure props and URL parameters
  const {
    headlines: contentTopics,
    subscriptionPlansDrawer,
    onboardingFooter,
  } = props ?? {};
  const { projectId } = useParams();
  const [headlines, setHeadlines] = useState(props.headlines || []);
  const [briefs, setBriefs] = useState([]);
  const [loadingBriefs, setLoadingBriefs] = useState([]);
  const [editBrief, setEditBrief] = useState();
  const [deleteTaskModalView, setDeleteTaskModalView] = useState(false);
  const [autoSaved, setAutoSaved] = useState(false); // Auto-save flag
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const campaignCards = location?.state?.campaignCards;
  const customAmount = location?.state?.customAmount;
  const contentAmount = location?.state?.amount;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isManual = query?.get('isManual') || 'true';
  const url = useSelector((state) => state?.newOnboarding?.step1?.url);

  // Helper to mark a brief as done loading
  const setLoadingToFalse = (index) => {
    setLoadingBriefs((prev) => {
      const loadingArray = [...prev];
      loadingArray[index] = false;
      return loadingArray;
    });
  };

  // Fetch briefs based on generated headlines
  const fetchBriefs = () => {
    if (!headlines.length) {
      return;
    }
    headlines.forEach((headline, index) => {
      setLoadingBriefs((prev) => {
        prev.push(true);
        return prev;
      });
      const generateBriefsParams = {
        project_id: projectId,
        headlines: [headline],
        business_id: props?.brand,
      };

      if (props?.flow === 'onboarding' || props?.flow === 'newPlan') {
        generateBriefsParams.url = url;
      }

      generateBriefs(generateBriefsParams)
        .then((res) => {
          if (!res) {
            setLoadingToFalse(index);
            return;
          }
          const newBrief = sanitizeBrief({
            brief: res.data[0],
            projectId,
          });
          setBriefs((prevBriefs) => [...prevBriefs, newBrief]);
          setLoadingToFalse(index);
        })
        .catch((e) => {
          setLoadingToFalse(index);
          console.error(e);
        });
    });
  };

  // Fetch headlines for the project plan
  const fetchHeadlines = async () => {
    try {
      setLoadingBriefs([]);
      const generateHeadlinesParams = {
        project_id: projectId,
        amount: customAmount ? contentAmount : props.amount || 16,
        previously_generated: briefs.map(({ title, content_type }) => ({
          'Topic/Headline': title,
          'Content Type': content_type,
        })),
        start_date: props?.headlines?.start_date,
        end_date: props?.headlines?.end_date,
        content_types: props?.headlines?.content_types,
        business_id: props?.brand,
        topics: props?.topics,
        goals: props?.goals,
      };

      if (props?.flow === 'onboarding' || props?.flow === 'newPlan') {
        generateHeadlinesParams.url = url;
      }
      const resGenerateHeadlines = await generateHeadlines(generateHeadlinesParams);
      setHeadlines(resGenerateHeadlines?.data);
    } catch (e) {
      setLoadingBriefs([false]);
      errorToast(e);
    }
  };

  // Clear onboarding state after plan is accepted
  const clearOnboarding = () => {
    setTimeout(() => {
      dispatch(resetOnboarding());
    }, 5000); // 5 seconds
  };

  // Accept plan with an optional callback based on flow type
  const handleAcceptPlanWithCallback = () => {
    if (props.onHide) {
      props.onHide();
    }
    const callback =
      props.flow === 'newPlan'
        ? clearOnboarding
        : props.flow === 'onboarding'
          ? subscriptionPlansDrawer
          : props.flow === 'expandPlan'
            ? props.callback
            : null;
    onAcceptPlan(callback);
  };

  useEffect(() => {
    fetchHeadlines();
  }, []);

  useEffect(() => {
    fetchBriefs();
  }, [headlines]);

  // Determine if briefs are still loading.
  const isLoading = loadingBriefs.length === 0 || loadingBriefs.some((v) => v);

  // Auto-save useEffect: automatically trigger saveBriefs when briefs are generated
  useEffect(() => {
    if (!autoSaved && !isLoading && briefs.length > 0) {
      onAcceptPlan();
      setAutoSaved(true);
    }
  }, [autoSaved, isLoading, briefs]);

  const navigateToPlan = () => {
    navigate(
      projectId
        ? ROUTES.ProjectSlug(projectId, { tab: PROJECT_TABS.plan })
        : ROUTES.PLANNER,
    );
  };

  // Save the briefs via the saveBriefs service
  const onAcceptPlan = async (callback) => {
    try {
      let response = await saveBriefs({
        project_id: projectId,
        tasks: briefs.map((brief) => ({
          ...brief,
          assignee_id: brief.assignee?.id,
        })),
      });
      if (response.status === 200) {
        props.flow !== 'onboarding' && navigateToPlan();
        if (props.flow === 'expandPlan' || props.flow === 'newPlan') {
          success('Plan Expanded!');
        }
        if (callback) {
          callback();
        }
      } else {
        errorToast('An error occurred while expanding plan.');
      }
    } catch (e) {
      errorToast(e);
    }
  };

  const onDeleteHandler = () => {
    setDeleteTaskModalView(true);
  };

  const onConfirmDeleteHandler = async () => {
    setBriefs((prevBriefs) =>
      prevBriefs.filter((brief) => brief.id !== editBrief?.id),
    );
    setEditBrief();
  };

  return (
    <>
      <AIPlanLayout
        loading={isLoading}
        onBoostPlan={fetchHeadlines}
        onAcceptPlan={handleAcceptPlanWithCallback}
        onCancel={navigateToPlan}
        isManual={isManual}
        header={
          (props.flow === 'onboarding' ||
            props.flow === 'newPlan' ||
            props.flow === 'expandPlan') &&
          'Happy with your initial plan?'
        }
        description={
          (props.flow === 'onboarding' ||
            props.flow === 'newPlan' ||
            props.flow === 'expandPlan') &&
          'You can click on view content to start creating your content.'
        }
        buttonText={
          (props.flow === 'onboarding' ||
            props.flow === 'newPlan' ||
            props.flow === 'expandPlan') &&
          'Add more'
        }
        showButton={
          props.flow !== 'onboarding' &&
          props.flow !== 'newPlan' &&
          props.flow !== 'expandPlan'
        }
        projectId={projectId}
      >
        <div className={Styles.cardsTable}>
          {briefs.map((brief, index) => (
            <ProjectPlanCard
              key={index}
              brief={brief}
              onClick={() => setEditBrief(brief)}
              onboardingFooter={onboardingFooter || campaignCards}
            />
          ))}
          {isLoading && <Spinner text="Generating plan" className="mh-100" />}
        </div>
      </AIPlanLayout>

      {!!editBrief && (
        <BriefDrawer
          onHide={() => setEditBrief()}
          onSubmit={(action, values) => {
            setBriefs((prevBriefs) => {
              const indexOfBriefToReplace = prevBriefs.findIndex(
                (brief) => brief.id === editBrief?.id,
              );
              prevBriefs[indexOfBriefToReplace] = { ...editBrief, ...values };
              successToast('Brief Updated!');
              return prevBriefs;
            });
            setEditBrief();
          }}
          action="edit"
          task={editBrief}
          show={!!editBrief}
          onDeleteHandler={onDeleteHandler}
          disableCreateDraft
        />
      )}

      {deleteTaskModalView && (
        <ConfirmDeleteModal
          show={deleteTaskModalView}
          onHide={() => setDeleteTaskModalView(false)}
          onDelete={onConfirmDeleteHandler}
        />
      )}
    </>
  );
};

export default AIProjectPlan;
