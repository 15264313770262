import { postRequest } from 'helpers/axios';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setLoader } from 'redux/slices/loader';
import {
  getAllInvoices,
  getCurrentSubscription,
  getPaymentMethods,
  markDefaultCard,
  refreshSubscription,
  removeCard,
  sendSupportEmail,
  cancelSubscription,
} from 'services/subscriptionService';
import PaymentCard from './PaymentCard';
import PlanTypeCard from './PlanTypeCard';
import sc from './Subscription.module.scss';
import SubscriptionCardModal from './SubscriptionCardModal';
import SubscriptionTable from './SubscriptionTable';
import { EmailModal } from './EmailModal';
import { Button } from 'react-bootstrap';
import { CONFIRM_SUBSCRIPTION } from 'constants';
import { TbExclamationCircle } from 'react-icons/tb';
import SubscriptionCardForm from './SubscriptionForm';
import ConfirmationModal from 'Containers/Modal/ConfirmationModal/ConfirmationModal';

const BillingSectionHeading = ({ heading, desc, className }) => {
  return (
    <div className={`${sc.billingSectionHeadingWrapper} ${className}`}>
      <h3 className={sc.heading}>{heading}</h3>
      <p className={sc.desc}>{desc}</p>
    </div>
  );
};

const BillingScreen = () => {
  const [selectedCard, setSelectedCard] = useState();
  const [showMore, setShowMore] = useState(1);
  const [cardModalOpen, setCardModalOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState({});
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const paymentIntent = searchParams?.get('payment_intent');
  const {
    plan_name: planName,
    stripe_price,
    end_date,
    payment_method,
    status,
  } = currentSubscription || {};

  const { interval, amount } = stripe_price || {};

  const dispatch = useDispatch();

  const fetchPaymentMethods = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Fetching Billing Info',
          description: 'Please wait...',
        }),
      );
      const paymentMethodsRes = await getPaymentMethods();
      const { data: paymentMethodData } = paymentMethodsRes || {};
      const { payment_methods, default_payment_method } =
        paymentMethodData || {};
      setPaymentMethods(payment_methods);
      setSelectedCard(default_payment_method);
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    } catch (error) {
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  }, [dispatch]);

  const fetchInvoices = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Fetching Invoices',
          description: 'Please wait...',
        }),
      );
      const invoicesRes = await getAllInvoices();
      const { data: invoicesData } = invoicesRes || {};
      const { invoices } = invoicesData || [];
      setInvoices(invoices);
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    } catch (error) {
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  }, [dispatch]);

  const fetchCurrentSubscription = useCallback(async () => {
    try {
      const currentSubscriptionRes = await getCurrentSubscription();
      const { data: subscriptionData } = currentSubscriptionRes || {};
      const { current_subscription } = subscriptionData || {};
      setCurrentSubscription(current_subscription);
    } catch (error) {}
  }, []);

  const confirmSubscription = useCallback(async () => {
    try {
      await refreshSubscription(paymentIntent);
      toast.success('Plan subscribed successfully!');
      fetchPaymentMethods();
      fetchCurrentSubscription().then(() => setShowSuccessPage(true));
    } catch (error) {}
  }, [fetchCurrentSubscription, fetchPaymentMethods, paymentIntent]);

  const handleCancelSubscription = async (priceId) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Unsubscribing Plan!',
          description: 'Please wait...',
        }),
      );
      await cancelSubscription();
      await fetchCurrentSubscription();

      toast.success('Your Subscription Was Cancelled');
      await refetch();
      dispatch(setLoader({ isLoading: false }));
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      toast.error(error);
    }
  };

  useEffect(() => {
    paymentIntent && confirmSubscription();
  }, [confirmSubscription, paymentIntent]);

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  useEffect(() => {
    fetchCurrentSubscription();
  }, [fetchCurrentSubscription]);

  useEffect(() => {
    if (paymentMethods?.data?.length === 1) {
      setShowMore(1);
    }
  }, [paymentMethods?.data?.length]);
  useEffect(() => {
    showSuccessPage &&
      planName &&
      amount &&
      interval &&
      navigateToSuccessPage();
  }, [planName, amount, interval, showSuccessPage]);

  const navigateToSuccessPage = () =>
    navigate(
      `/${CONFIRM_SUBSCRIPTION}/plan/${planName}/price/${amount}/type/${interval}`,
    );

  const { data: paymentMethodData } = paymentMethods || {};
  const viewAllButtonCursor =
    paymentMethodData?.length > 1 ? 'pointer' : 'default';

  if (!currentSubscription) {
    return null;
  }

  const handleDefault = async (cardId) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Marking card as default!',
          description: 'Please wait...',
        }),
      );
      await markDefaultCard(cardId);
      setSelectedCard(cardId);
      fetchCurrentSubscription();
      fetchPaymentMethods();
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    } catch (error) {
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  };

  const handleRemove = async (cardId) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Removing Card!',
          description: 'Please wait...',
        }),
      );
      await removeCard(cardId);
      fetchCurrentSubscription();
      fetchPaymentMethods();
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    } catch (error) {
      dispatch(
        setLoader({
          isLoading: false,
        }),
      );
    }
  };

  const handleInvoiceDownload = async (invoiceId, invoiceNumber, url) => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Sending Invoice!',
        description: 'Please wait...',
      }),
    );
    try {
      postRequest(`invoices/send_receipt`, {
        id: invoiceId,
        url,
      });

      setTimeout(() => {
        dispatch(setLoader({ isLoading: false }));
        toast.success('You will soon get an invoice.');
      }, 3000);
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      toast.error(`An error occurred: ${error.response?.data?.error}`);
      toast.error(`${error.response?.data?.backtrace}`);
    }
  };

  const sendEmail = async (subject, message) => {
    try {
      await sendSupportEmail(subject, message);
      toast.success('Email sent successfully');
    } catch (error) {
      toast.error('Oops! encountered an issue while sending email', error);
    }
  };

  const transformedPaymentMethodData =
    paymentMethodData?.reduce((acc, currentValue) => {
      if (currentValue.id === selectedCard) {
        acc.unshift(currentValue); // Add the target element to the beginning of the accumulator
      } else {
        acc.push(currentValue); // Add other elements to the accumulator
      }
      return acc;
    }, []) || [];

  return (
    <div className={sc.billing__root}>
      {!(
        currentSubscription?.active &&
        currentSubscription?.status === 'past_due'
      ) && (
        <div className={sc.billing__root__header}>
          <span>
            <TbExclamationCircle />
          </span>
          <p>
            {planName === 'Free Plan'
              ? ''
              : currentSubscription?.active &&
                  currentSubscription?.status === 'past_due'
                ? 'Your payment has failed. Please update your payment method, or your subscription will end.'
                : currentSubscription?.status === 'trial'
                  ? `Free Trial ends on ${moment(end_date).format('MMM DD, YYYY')}`
                  : currentSubscription?.status === 'canceled' &&
                      currentSubscription?.active
                    ? `Your subscription has been canceled, but you can use system features till ${moment(currentSubscription?.end_date).format('MMM DD, YYYY')} as per your last subscription`
                    : `Your next billing cycle is ${moment(end_date).format('MMM DD, YYYY')}`}
          </p>
        </div>
      )}
      <div className={sc.outer_container} style={{ flexDirection: 'column' }}>
        <div className={sc.headingWrapper}>
          <div className={sc.headingBox}>
            <p className={sc.paymentDetailHeading}>
              Manage your billing and payment details.
            </p>
          </div>
          <div className={`mb-2 ${sc.buttons_div}`}>
            <Button
              variant="light"
              size="sm"
              className="px-3 cancel_btn text-capitalize"
              onClick={() => setIsModalOpen(true)}
            >
              Email support
            </Button>
            {currentSubscription?.status !== 'canceled' && (
              <Button
                variant="danger"
                size="sm"
                className={`px-3 cancel_btn text-capitalize ${sc.cancel_button}`}
                onClick={() => setIsCancelModalOpen(true)}
              >
                Cancel Subscription
              </Button>
            )}
            <EmailModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              sendEmail={sendEmail}
            />
            <ConfirmationModal
              show={isCancelModalOpen}
              onHide={() => setIsCancelModalOpen(false)}
              onDelete={handleCancelSubscription}
              modalHeading="Cancel Subscription"
              modalDescription="Are you sure you want to cancel your subscription? This action cannot be
        undone."
              actionText="Proceed"
              loaderHeading="Unsubscribing!"
            />
          </div>
        </div>
        <div className={sc.container}>
          <div className={sc.planTypeCardWrapper}>
            <BillingSectionHeading
              heading="Subscription Plan"
              desc={`You’ve chosen a ${planName} ${
                interval === 'month' ? '(month)' : '(annual)'
              }`}
            />

            <PlanTypeCard currentSubscription={currentSubscription} />
          </div>
          {paymentMethods && (
            <div className={`${sc.addedCardsWrapper}`}>
              <BillingSectionHeading
                heading="Card details"
                desc="Select default payment method."
              />
              <div className={sc.addedCardsArea}>
                <div className={sc.paymentMethodCardsWrapper}>
                  {[...transformedPaymentMethodData?.slice(0, showMore)].map(
                    (card) => (
                      <PaymentCard
                        key={card.id}
                        card={card}
                        setSelectedCard={(card) => handleDefault(card.id)}
                        checked={card.id === selectedCard}
                        selectedCard={selectedCard}
                        setShowMore={setShowMore}
                        handleRemove={handleRemove}
                      />
                    ),
                  )}
                </div>
                <div className={sc.addPaymentActionWrapper}>
                  {showMore === 1 && (
                    <button
                      disabled={
                        paymentMethodData?.length === 1 ||
                        !paymentMethodData?.length
                      }
                      onClick={() => setShowMore(paymentMethodData?.length)}
                      className={sc.viewAllBtn}
                      style={{ cursor: viewAllButtonCursor }}
                    >
                      <span>View All</span>
                      <img src="/Images/chevron-down.svg" alt="downArrow" />
                    </button>
                  )}
                  {showMore > 1 && (
                    <button
                      onClick={() => setShowMore(1)}
                      className={sc.viewLessBtn}
                    >
                      <span>View Less</span>
                      <img src="/Images/chevron-down.svg" alt="downArrow" />
                    </button>
                  )}
                </div>
                {/* <div className={sc.addNewPaymentMethodBtnWrapper}>
                  <button
                    className={sc.addNewPaymentMethodBtn}
                    onClick={() => setCardModalOpen(true)}
                  >
                    <img src="/Images/plus-grey1.svg" alt="addIcon" />
                    <span className={sc.newMethodText}>
                      Add New Payment Method
                    </span>
                  </button>
                </div> */}
                {!cardModalOpen ? (
                  <div className={sc.addNewPaymentMethodBtnWrapper}>
                    <button
                      className={sc.addNewPaymentMethodBtn}
                      onClick={() => setCardModalOpen(true)}
                    >
                      <img src="/Images/plus-grey1.svg" alt="addIcon" />
                      <span className={sc.newMethodText}>
                        Add New Payment Method
                      </span>
                    </button>
                  </div>
                ) : (
                  <SubscriptionCardForm
                    onHide={() => setCardModalOpen(false)}
                    show={cardModalOpen}
                    isProductView={true}
                    refetch={fetchPaymentMethods}
                    // product={productData}
                    isTrial={currentSubscription?.status === 'trial'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div className={sc.billingTableWrapper}>
          <div className={sc.billingHeaderBox}>
            <BillingSectionHeading
              heading="Billing and invoicing"
              desc=""
              className={sc.utility_padding_botttom}
            />
            <div className={sc.moreElipsisIcon}>
              {/* <Dropdown>
              <Dropdown.Toggle className={sc.dropdownBtn}>
                {dotsVerticalIcon}
              </Dropdown.Toggle>

              <Dropdown.Menu className={sc.dropdownMenu}>
                dropDownMenu nae mil raha
              </Dropdown.Menu>
            </Dropdown> */}
            </div>
          </div>
          <div className={sc.billingTableBox}>
            <SubscriptionTable
              invoices={invoices}
              handleInvoiceDownload={handleInvoiceDownload}
            />
          </div>
        </div>
      </div>

      {/* <SubscriptionCardModal
        onHide={() => setCardModalOpen(false)}
        show={cardModalOpen}
        isProductView={false}
        refetch={fetchPaymentMethods}
      /> */}
    </div>
  );
};

export default BillingScreen;
