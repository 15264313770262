import React from 'react';
import Styles from './Style.module.scss';
import VocableLogo from '../../assets/login/vocable..svg';
import sumo from '../../assets/sumo.svg';
import { ErrorMessage, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import BSButton from 'Components/Button/BSButton';
import FormikTextField from 'Components/Form/FormikTextField';
import { HiOutlineEnvelope } from 'react-icons/hi2';
import { GoLock } from 'react-icons/go';
import { IoEyeOutline } from 'react-icons/io5';
import { IoEyeOffSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import _Button1 from '../../assets/login/_Button.svg';
import Google from '../../assets/login/google.svg';
import _Button2 from '../../assets/login/_Button (1).svg';
import _Button3 from '../../assets/login/_Button (2).svg';
import _Button4 from '../../assets/login/_Button (3).svg';
import { useNavigate } from 'react-router-dom';
import { EMAIL_REGEX, PASSWORD_FORMAT } from 'constants';
import * as Yup from 'yup';
import PasswordValidationError from 'Components/Password/Error';
import { useDispatch } from 'react-redux';
import { register, socialLogin } from 'redux/slices/auth';
import { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { SIGNUP_MODAL_HEADING } from 'constants';
import AppSumo from '../../assets/appsumo.svg';
import { MdOutlinePhoneIphone } from 'react-icons/md';

const SumoSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [emailSend, setEmailSend] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const steps = [0, 1, 2, 3]; // Array representing steps
  const [activeStep, setActiveStep] = useState(0); // Start with the first step

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const initialValues = {
    email: '',
    last_name: '',
    first_name: '',
    phone: '',
    password: '',
    confirm_password: '',
    company_name: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .matches(EMAIL_REGEX, 'Invalid email')
      .required('Email is required.'),
    phone: Yup.string()
      .trim()
      .matches(/^[0-9]+$/, 'Phone number must contain only digits.')
      .min(10, 'Phone number must be at least 10 digits.'),
    password: Yup.string()
      .required('Password is required.')
      .min(8, 'Password must be at least 8 characters.')
      .max(18, 'Password must be at most 18 characters.'),
    first_name: Yup.string().trim().required('First name is required.'),
    // last_name: Yup.string().trim().required('Last name is required.'),
    company_name: Yup.string().trim().required('Company name is required.'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match.')
      .matches(PASSWORD_FORMAT, {
        message: PasswordValidationError(),
      })
      .required('Confirm password is required'),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const {
      email,
      password,
      confirm_password,
      first_name,
      last_name,
      company_name,
    } = values || {};
    const body = {
      user: {
        email,
        first_name,
        last_name,
        password,
        password_confirmation: confirm_password,
        company_name,
      },
    };

    // How can I clear query params here?

    const response = await dispatch(register(body));
    const { payload = {} } = response;

    if (payload.confirmation_required) {
      // handleClose();
      resetForm();
      handleSendEmail();
    }
    if (payload.error) {
      // handleClose();
      resetForm();
      setIsError(true);
    }
    if (payload.user?.sign_in_count < 2) {
      // navigate(ROUTES.OnboardingInfo);
    }
  };

  const handleSendEmail = () => {
    setEmailSend(true);
  };

  const handleGoogleLoginSuccess = async (response) => {
    const responseData = await dispatch(
      socialLogin(
        { googleAccessToken: response.access_token },
        SIGNUP_MODAL_HEADING,
      ),
    );
    const { payload = {} } = responseData || {};
    if (payload.user?.sign_in_count < 2) {
      // navigate(ROUTES.OnboardingInfo);
    }
  };

  const handleGoogleLoginFailure = () => {
    renderError('Login failed, please contact with support!');
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginFailure,
  });

  const handleClick = (values, resetForm) => {
    handleSubmit(values, { resetForm });
  };

  return (
    <div className={Styles.OuterContainer}>
      <div className={Styles.OuterContainer__left}>
        <div className={Styles.OuterContainer__left__logo}>
          <div className={Styles.logo__text}>
            <img src={VocableLogo} alt="Sumo" />
          </div>
          <span>with</span>
          <img alt="Appsumo logo" src={sumo} className="mt-1" />
        </div>

        <div className={Styles.OuterContainer__left__form_container}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
          >
            {({ values, setFieldValue, isValid, resetForm }) => {
              return (
                <>
                  <div
                    className={
                      Styles.OuterContainer__left__form_container__welcome
                    }
                  >
                    <p>Welcome Sumo-ling!</p>
                    <span>
                      You're just a step away from easier and smarter content
                      creation. Let's set up your account.
                    </span>
                  </div>
                  {/* form header */}
                  <div>
                    <div className={Styles.logos_container}>
                      <button
                        onClick={() => googleLogin()}
                        className={Styles.google_btn}
                      >
                        <span>Continue with Google</span>
                        <img src={Google} alt="google" />
                      </button>
                    </div>
                    <div className={Styles.or_container}>
                      <div className={Styles.or_container__line}></div>
                      <div className={Styles.or_container__or}>OR</div>
                      <div className={Styles.or_container__line}></div>
                    </div>
                  </div>
                  <Form
                    className={Styles.formik_form}
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {emailSend || isError ? (
                      <div className={Styles.email_send_box}>
                        <div className={Styles.email_send_box__inner_box}>
                          <div
                            className={Styles.email_send_box__inner_box__top}
                          >
                            {emailSend ? 'Verify Email' : 'Email Already Taken'}
                          </div>
                          <div
                            className={Styles.email_send_box__inner_box__bottom}
                          >
                            {emailSend
                              ? 'Signed up successfully, please verify your email address by following the instructions sent to your email.'
                              : 'Please use a different email address to register as this email is already taken.'}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={Styles.parallel_field}>
                          <div className="w-100">
                            <FormikTextField
                              name="first_name"
                              type="text"
                              placeholder="Enter first name...  "
                              classList="input input__Email"
                              Label="First Name"
                              isRequired
                            />
                            <ErrorMessage name="first_name">
                              {(msg) => <span className="error">{msg}</span>}
                            </ErrorMessage>
                          </div>
                          <div className="w-100">
                            <FormikTextField
                              name="last_name"
                              type="text"
                              placeholder="Enter last name...  "
                              classList="input input__Email"
                              Label="Last Name"
                            />
                            <ErrorMessage name="last_name">
                              {(msg) => <span className="error">{msg}</span>}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className={Styles.fullWidth}>
                          <FormikTextField
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                            classList="input input__Email"
                            Label="Email"
                            isRequired
                            preIcon={<HiOutlineEnvelope color="#667085" />}
                          />
                          <ErrorMessage name="email">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>
                        <div className={Styles.fullWidth}>
                          <FormikTextField
                            name="phone"
                            type="phone"
                            placeholder="+1 000-000-000"
                            classList="input input__Phone"
                            Label="Phone Number"
                            isRequired
                            preIcon={<MdOutlinePhoneIphone color="#667085" />}
                          />
                          <ErrorMessage name="phone">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>
                        <div className={Styles.password_field}>
                          <FormikTextField
                            name="company_name"
                            type="text"
                            placeholder="Enter company name..."
                            classList="input input__Email"
                            Label="Company Name"
                            isRequired
                          />
                          <ErrorMessage name="company_name">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>
                        <div className={Styles.password_field}>
                          <FormikTextField
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Enter password..."
                            classList="input input__Email"
                            Label="Password"
                            isRequired
                            preIcon={<GoLock color="#667085" />}
                            postIcon={
                              showPassword ? (
                                <IoEyeOffSharp
                                  color="#667085"
                                  onClick={togglePasswordVisibility}
                                />
                              ) : (
                                <IoEyeOutline
                                  color="#667085"
                                  onClick={togglePasswordVisibility}
                                />
                              )
                            }
                          />
                          <ErrorMessage name="password">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>
                        <div className={Styles.password_field}>
                          <FormikTextField
                            name="confirm_password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            placeholder="Enter password..."
                            classList="input input__Email"
                            Label="Confirm Password"
                            isRequired
                            preIcon={<GoLock color="#667085" />}
                            postIcon={
                              showConfirmPassword ? (
                                <IoEyeOffSharp
                                  color="#667085"
                                  onClick={toggleConfirmPasswordVisibility}
                                />
                              ) : (
                                <IoEyeOutline
                                  color="#667085"
                                  onClick={toggleConfirmPasswordVisibility}
                                />
                              )
                            }
                          />
                          <ErrorMessage name="confirm_password">
                            {(msg) => <span className="error">{msg}</span>}
                          </ErrorMessage>
                        </div>
                        <div className={Styles.btn_container}>
                          <BSButton
                            classList={`btn button secondary_btn w-100`}
                            variant="dark"
                            ButtonText="Sign Up"
                            disabled={!isValid}
                            onClick={() => handleClick(values, resetForm)}
                          />
                        </div>
                      </>
                    )}
                    <div className={Styles.trial_account}>
                      <span className={Styles.trial_account__dont_have}>
                        Already have an account?
                      </span>
                      <Link
                        to="/log-in"
                        className={Styles.trial_account__have}
                        onClick={() => navigate('/log-in')}
                      >
                        Sign In
                      </Link>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>

      {/* Right Card */}
      <div className={`${Styles.OuterContainer__right}`}>
        <img src={AppSumo} alt="AppSumo" />
      </div>
    </div>
  );
};

export default SumoSignUp;
