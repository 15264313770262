import React, { useEffect, useRef, useState } from 'react';
import { BubbleMenu, FloatingMenu } from '@tiptap/react';
import useCollabEditor from 'hooks/useCollabEditor';
import { BiCopy, BiPencil, BiUnlink } from 'react-icons/bi';
import { LinkModal } from '../Modal/LinkModal/LinkModal';
import CommentsSidebar from './Components/CommentsSidebar';
import ContentSettings from './Components/ContentSettings';
import DocumentHealth from './Components/Document Health/DocumentHealth';
import EditorHeader from './Components/EditorHeader';
import EditorSection from './Components/EditorSection';
import AddBlockMenu from './Components/AddBlockMenu';
import './Editor.scss';
import Loader from 'Components/Loader/Loader';
import BriefDrawer from 'Components/Drawers/BriefDrawer/BriefDrawer';
import useBrief from 'hooks/briefs/useBrief';
import { updateTask } from 'services/contentPlannerService';
import { error as errorToast } from 'helpers/toaster';
import Header from './Components/Header';
import { generateStreamResponse } from 'helpers/openAIChat';
import ContentGenerationModal from 'Containers/Modal/ContentGenerationModal/ContentGenerationModal';
import { updateDocument } from 'services/documentService';
import Settings from './Settings';
import Publish from './Publish';
import Promote from './Promote';
import PromotionTable from './Components/Breadcrumb/PromotionTable';
import { GENERATE_INNER_HTML_PROMPT_POSTFIX } from 'constants';
import { copyToClipboard } from 'helpers';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import AiTextOffcanvas from './Components/OffCanvas/OffCanvas';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import { publishDocuments } from 'services/integrationService';
import { useSelector } from 'react-redux';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';
import MergePublishSetting from './MergePublishSetting.jsx/MergePublishSetting';
import PublishModal from 'Containers/Modal/PublishModal/PublishModal';
import XThreadModal from 'Containers/Modal/XThreadModal/XThreadModal';
import TweetOrThreadModal from 'Containers/Modal/XThreadModal/TweetOrThreadModal';
import { setLoader } from 'redux/slices/loader';
import { useDispatch } from 'react-redux';

let mainContainerRef;

const getWidth = ({ contentSidebarCollapse }) => {
  if (contentSidebarCollapse) {
    return 'calc(100% - 241.5px)';
  }

  return 'calc(100% - 553px)';
};

const Editor = ({ freeStyle, setIsLoading }) => {
  const {
    comments,
    setComments,
    documentCopy,
    contentSidebarCollapse,
    showComments,
    showDocHealth,
    showEditor,
    handleContentSideBarToggle,
    lastUpdated,
    collaborators,
    documentOwner,
    hoverId,
    setHoverId,
    selection,
    docHealth,
    setDocHealth,
    url,
    setUrl,
    linkModalOpen,
    handleRewrite,
    handleWriteParagraph,
    refetchContent,
    openModal,
    closeModal,
    saveLink,
    removeLink,
    handleCopyLink,
    handleExpand,
    addComment,
    resolveComment,
    deleteComment,
    editor,
    fetchComments,
    access,
    collaborator,
    docHealthLoading,
    setSelection,
  } = useCollabEditor();

  const formSubmitRef = useRef();

  const [aiTitle, setAITitle] = useState(documentCopy?.title);
  const [streamContent, setStreamContent] = useState('');
  const [contentGenerated, setContentGenerated] = useState(false);
  const [showContentGeneration, setShowContentGeneration] = useState(false);
  const [aiContent, setAiContent] = useState('');
  const [showAiTextEditor, setShowAiTextEditor] = useState(true);

  const [showTemplateDrawer, setShowTemplateDrawer] = useState(false);
  const [showBriefDrawer, setShowBriefDrawer] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [input, setInput] = useState('');
  const [aIaction, setAiAction] = useState();
  const [documentHeaderTab, setDocumentHeaderTab] = useState('Write');
  const [publishSelectedIntegration, setPublishSelectIntegration] = useState(
    {},
  );
  const [displaySettings, setDisplaySettings] = useState(false);

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  const abortController = useRef();
  const { documentId } = useParams() || {};

  const briefId = documentCopy?.task_associated;

  const { task, refetch: refetchTask } = useBrief(briefId);

  const documentTemplateOption = documentCopy?.template_options?.options ?? [];

  const isFullAccess = access === 'full_access';
  const isEdit = access === 'can_edit';
  const isComment = access === 'can_comment';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [publishMessage, setPublishMessage] = useState('');
  const [showThreadOption, setShowThreadOption] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    editor && setIsLoading && setIsLoading();
  }, [editor]);

  useEffect(() => {
    if (isComment) {
      editor?.setEditable(false);
    }
  }, []);

  useEffect(() => {
    if (contentGenerated) {
      handleContentGenerated();
    }
  }, [contentGenerated]);

  if ((!collaborators.length <= 0 && !documentOwner) || !access) {
    return (
      <Loader loaderHeading="Loading" loaderDescription="Loading the Editor" />
    );
  }

  const shouldShowContentSettings = !freeStyle && !isComment;

  // TODO: check this method
  const secondWidthMenu = getWidth({
    contentSidebarCollapse,
  });

  const handlePublishClick = () => {
    if (formSubmitRef.current) {
      formSubmitRef.current(); // Trigger form submit
    }
  };

  const handlePromptAI = async (input, model) => {
    // Get the current document content from the editor
    const documentContent = editor ? editor.getHTML() : '';
    const documentTitle = documentCopy?.title || 'Untitled Document';
    
    // Create a comprehensive system prompt with document context
    const systemPrompt = `
  You are an AI writing assistant helping with a document titled "${documentTitle}". You have access to the current content of the document to provide context for your responses.
  
  ----DOCUMENT CONTENT----
  ${documentContent}
  ----END DOCUMENT CONTENT----
  
  Please assist the user with their writing request while keeping the document's context in mind. When appropriate, format your responses as HTML that can be directly inserted into the document.
  
  Your responses should:
  1. Match the style and tone of the existing document
  2. Be formatted as valid HTML when providing content to be inserted
  3. Be concise and focused on addressing the user's specific request
  
  ${GENERATE_INNER_HTML_PROMPT_POSTFIX || ''}
  `;
  
    const URL = `${process.env.REACT_APP_URL}/api/v1/assistant_completion_stream_data`;
    const BODY = JSON.stringify({
      prompt: input,
      system_prompt: systemPrompt,
      ai_model: model,
      max_token: 4000
    });
  
    try {
      abortController.current = new AbortController();
      setAiContent('');
      setContentGenerated(false);
      setShowDrawer(true);
      setAiAction('prompt');
  
      await generateStreamResponse(
        URL,
        BODY,
        setAiContent,
        setContentGenerated,
        abortController?.current.signal,
        'ai-answer',
      );
    } catch (error) {
      console.error("Error in AI prompt:", error);
      // You might want to show an error message to the user
    }
  };

  const handleCopy = () => {
    const blob = new Blob([aiContent], { type: 'text/html' });
    const clipboardItem = new ClipboardItem({ 'text/html': blob });
    copyToClipboard([clipboardItem], true);
    renderSuccess('Text copied to clipboard');
  };

  const updateAITitle = (aiContent) => {
    setAITitle((prevTitle) => {
      if (!aiContent) {
        return prevTitle;
      }

      const matchResult = aiContent.match(/<h1>(.*?)<\/h1>/);

      if (!matchResult || !matchResult[1]) {
        return prevTitle;
      }

      return matchResult[1];
    });
  };

  const handleInsert = () => {
    updateAITitle(aiContent);

    editor.commands.insertContent(aiContent, {
      parseOptions: {
        preserveWhitespace: false,
      },
    });
  };

  const onSubmit = async (action, values) => {
    try {
      await updateTask(briefId, values);
      await refetchTask();
    } catch (error) {
      errorToast(error);
    }
  };

  const onRegenerate = async (values) => {
    try {
      await onSubmit('edit', values);

      abortController.current = new AbortController();
      setStreamContent('');
      setContentGenerated(false);
      setShowContentGeneration(true);
      setAiAction('regenerate');

      const URL = `${process.env.REACT_APP_URL}/api/v1/completion_stream_data_style_guides`;
      const BODY = JSON.stringify({
        project_id: documentCopy.project_id,
        task_id: briefId,
      });

      await generateStreamResponse(
        URL,
        BODY,
        setStreamContent,
        setContentGenerated,
        abortController?.current.signal,
      );
    } catch (error) {
      errorToast(error);
    }
  };

  const handleContentGenerated = async () => {
    await updateDocument(documentCopy.id, { content: streamContent });
    setShowBriefDrawer(false);
    setShowContentGeneration(false);
    await refetchContent(aIaction === 'prompt' ? false : true);
  };

  const handlePublishDoc = async (options = {}) => {
    if (currentSubscription?.plan_name === 'Basic Plan') {
      setShowUpgradeModal(true);
      return;
    }

    const objectKey = Object.keys(publishSelectedIntegration)[0];
    if (!publishSelectedIntegration[objectKey] || !objectKey) {
      errorToast('Please select atleast one channel to publish');
      return;
    }

    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Processing!',
          description: 'Please wait...',
        }),
      );
      const xThread = options.xThread;
      const data = await publishDocuments(documentId, {
        integration_id: publishSelectedIntegration[objectKey]?.id,
        x_thread: xThread,
      });

      if (data?.status === 201) {
        dispatch(setLoader({ isLoading: false }));
        setPublishMessage(data?.data?.message);
        setIsModalOpen(true);
        setDocumentHeaderTab('write');
        // renderSuccess('Published successfully');
      } else if (data?.status === 200) {
        dispatch(setLoader({ isLoading: false }));
        setPublishMessage(data?.data?.message);
        setIsModalOpen(true);
        // renderSuccess(data?.data?.message)
      } else {
        dispatch(setLoader({ isLoading: false }));
        renderError(data?.message || 'Publishing failed.');
      }
    } catch (error) {
      if (error?.response?.data?.message === 'message-too-long') {
        setShowThreadOption(true);
      } else {
        renderError(error?.response?.data?.message || 'Publishing failed.');
      }
      dispatch(setLoader({ isLoading: false }));
      console.log('Error:', error);
    }
  };

  const checkPublishDoc = () => {
    try {
      if (currentSubscription?.plan_name === 'Basic Plan') {
        setShowUpgradeModal(true);
        return false;
      }

      const objectKey = Object.keys(publishSelectedIntegration)[0];
      if (!publishSelectedIntegration[objectKey] || !objectKey) {
        errorToast('Please select atleast one channel to publish');
        return false;
      }
      return true;
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const checkIntegrations = (integrations) => {
    // Check if the object is empty
    if (Object.keys(integrations).length === 0) {
      return false;
    }

    // Check if all integrations have is_active set to false
    const allInactive = Object.values(integrations).every(
      (integration) => integration.is_active === false,
    );

    return !allInactive; // If all are inactive, it returns false
  };

  const documentHeadersComponents = {
    Write: (
      <EditorSection
        editor={editor}
        setAITitle={setAITitle}
        handleRewrite={handleRewrite}
        comments={comments}
        setComments={setComments}
        fetchComments={fetchComments}
        isComment={isComment}
        hoverId={hoverId}
        selection={selection}
        secondWidth={secondWidthMenu}
        handleWriteParagraph={handleWriteParagraph}
        openModalLink={openModal}
        mainContainerRef={mainContainerRef}
        handleExpandChild={handleExpand}
        addComment={addComment}
        resolveComment={resolveComment}
        deleteComment={deleteComment}
        setSelection={setSelection}
      />
    ),
    // Settings: <Settings />,
    Publish: (
      <MergePublishSetting
        setPublishSelectIntegration={setPublishSelectIntegration}
        publishSelectedIntegration={publishSelectedIntegration}
        displaySettings={displaySettings}
        setDocumentHeaderTab={setDocumentHeaderTab}
        setDisplaySettings={setDisplaySettings}
        checkIntegrations={checkIntegrations}
        handlePublishDoc={handlePublishDoc}
        formSubmitRef={formSubmitRef}
        showUpgradeModal={showUpgradeModal}
        setShowUpgradeModal={setShowUpgradeModal}
      />
    ),
    // Publish: !checkIntegrations(publishSelectedIntegration)  ? (
    //   <Publish
    //     setPublishSelectIntegration={setPublishSelectIntegration}
    //     publishSelectedIntegration={publishSelectedIntegration}
    //     displaySettings={displaySettings}
    //     setDisplaySettings={setDisplaySettings}
    //     checkIntegrations={checkIntegrations}
    //   />
    // ) : (
    //   <Settings
    //     setDisplaySettings={setDisplaySettings}
    //     // other props for Settings if needed
    //   />
    // ),
    Promote: <Promote />,
  };

  return (
    <span
      id="suggestionPortal"
      ref={(el) => {
        mainContainerRef = el;
      }}
    >
      <BubbleMenu
        pluginKey="bubbleMenuLink"
        className="bubble-menu-light"
        tippyOptions={{ duration: 150, placement: 'auto' }}
        editor={editor}
        shouldShow={({ editor, from, to }) => {
          // only show the bubble menu for links.
          return from === to && editor.isActive('link');
        }}
      >
        <button className="button" onClick={openModal}>
          <BiPencil size={20} />
        </button>
        <button className="button-remove" onClick={removeLink}>
          <BiUnlink size={20} />
        </button>
        <button className="button-remove" onClick={handleCopyLink}>
          <BiCopy size={20} />
        </button>
      </BubbleMenu>
      {!isComment && (
        <FloatingMenu
          editor={editor}
          tippyOptions={{
            duration: 100,
            maxWidth: '100%',
            border: '2px solid red',
          }}
        >
          <AddBlockMenu
            editor={editor}
            inline={true}
            freeStyle={freeStyle}
            handlePromptAI={handlePromptAI}
            aiContent={aiContent}
            handleInsert={handleInsert}
            handleCopy={handleCopy}
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            setShowAiTextEditor={setShowAiTextEditor}
            showEditor={() => handleContentSideBarToggle('editor')}
            input={input}
            setInput={setInput}
          />
        </FloatingMenu>
      )}
      {linkModalOpen && (
        <LinkModal
          url={url}
          isOpen={linkModalOpen}
          onRequestClose={closeModal}
          contentLabel="Edit Link Modal"
          closeModal={closeModal}
          onChangeUrl={(e) => setUrl(e.target.value)}
          onSaveLink={saveLink}
          onRemoveLink={removeLink}
          setUrl={setUrl}
        />
      )}
      <Header
        document={documentCopy}
        contentSidebarCollapse={contentSidebarCollapse}
        refetchContent={refetchContent}
        setShowComments={handleContentSideBarToggle}
        lastUpdated={lastUpdated}
        showComments={showComments}
        shouldShowContentSettings={shouldShowContentSettings}
        isEdit={isEdit}
        isComment={isComment}
        comments={comments}
        canDelete={isFullAccess}
        collaborator={collaborator}
        collaborators={collaborators}
        setShowTemplateDrawer={setShowTemplateDrawer}
        briefId={briefId}
        setShowBriefDrawer={setShowBriefDrawer}
        docHealth={docHealth}
        freeStyle={freeStyle}
        fromEditorDoc={true}
        handlePublishDoc={handlePublishDoc}
        documentHeaderTab={documentHeaderTab}
        setDocumentHeaderTab={setDocumentHeaderTab}
        onPublish={handlePublishClick}
        checkPublishDoc={checkPublishDoc}
      />

      <div className="editor-container d-flex">
        <div
          className={`second ${contentSidebarCollapse ? 'full-width' : 'width_editor_text_area'}`}
        >
          <EditorHeader
            document={documentCopy}
            docHealth={docHealth}
            aiTitle={aiTitle}
            lastUpdated={lastUpdated}
            showDocHealth={() => handleContentSideBarToggle('dochealth')}
            shouldShowContentSettings={shouldShowContentSettings}
            isComment={isComment}
            setShowTemplateDrawer={setShowTemplateDrawer}
            briefId={briefId}
            setShowBriefDrawer={setShowBriefDrawer}
            contentSidebarCollapse={contentSidebarCollapse}
            documentHeaderTab={documentHeaderTab}
            setDocumentHeaderTab={setDocumentHeaderTab}
          />
          {documentHeadersComponents[documentHeaderTab] ||
            documentHeadersComponents.Write}

          {/* 
          <div style={{ padding: '20px 32px' }}>
            <PromotionTable />
          </div> */}

          {/* <EditorSection
            editor={editor}
            setAITitle={setAITitle}
            handleRewrite={handleRewrite}
            comments={comments}
            setComments={setComments}
            fetchComments={fetchComments}
            isComment={isComment}
            hoverId={hoverId}
            selection={selection}
            secondWidth={secondWidthMenu}
            handleWriteParagraph={handleWriteParagraph}
            openModalLink={openModal}
            mainContainerRef={mainContainerRef}
            handleExpandChild={handleExpand}
            addComment={addComment}
            resolveComment={resolveComment}
            deleteComment={deleteComment}
            setSelection={setSelection}
          /> */}
        </div>
        <div
          className={`third  ${
            contentSidebarCollapse ? 'container-closed' : ''
          }`}
        >
          {showComments && (
            <CommentsSidebar
              comments={comments}
              fetchComments={fetchComments}
              setHoverId={setHoverId}
              hoverId={hoverId}
              resolveComment={resolveComment}
              deleteComment={deleteComment}
            />
          )}
          {showDocHealth && (
            <DocumentHealth
              docHealth={docHealth}
              setDocHealth={setDocHealth}
              docHealthLoading={docHealthLoading}
              onClose={() => handleContentSideBarToggle('close')}
            />
          )}
          {
            <AiTextOffcanvas
              aiContent={aiContent}
              handleInsert={handleInsert}
              input={input}
              setInput={setInput}
              setAiContent={setAiContent}
              handleCopy={handleCopy}
              showEditor={showEditor}
              onClose={() => handleContentSideBarToggle('close')}
              contentGenerated={contentGenerated}
            />
          }
        </div>
      </div>

      {shouldShowContentSettings && (
        <ContentSettings
          show={showTemplateDrawer}
          onHide={() => setShowTemplateDrawer(false)}
          documentTemplateOptions={documentTemplateOption}
          refetchContent={refetchContent}
        />
      )}
      {showBriefDrawer && (
        <BriefDrawer
          show={showBriefDrawer}
          onHide={() => setShowBriefDrawer(false)}
          task={task}
          onSubmit={onSubmit}
          regenerate
          regenerateFn={onRegenerate}
          action="edit"
        />
      )}

      {showContentGeneration && (
        <ContentGenerationModal
          onHide={() => {
            setShowContentGeneration(false);
            setStreamContent('');
            setContentGenerated(false);
            abortController?.current.abort();
          }}
          show={showContentGeneration}
          string={streamContent}
          contentGenerated={contentGenerated}
        />
      )}

      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}
      {isModalOpen && (
        <PublishModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          message={publishMessage}
        />
      )}
      {showThreadOption && (
        <XThreadModal
          isOpen={showThreadOption}
          convertItToThread={() => handlePublishDoc({ xThread: true })}
          onClose={() => setShowThreadOption(false)}
        />
      )}
    </span>
  );
};

export default Editor;
