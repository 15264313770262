import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import StatsSection from './StatsSection';
import Styles from './Dashboard.module.scss';
import ActiveCampaigns from './ActiveCampaigns';
import { FiCalendar, FiFile, FiFileText, FiLayers, FiChevronDown } from 'react-icons/fi';
import BriefSection from './BriefSection';
import { AiOutlineProject } from 'react-icons/ai';
import NewDocumentModal from 'Components/Modals/NewDocumentModal/NewDocumentModal';
import BriefDrawer from 'Components/Drawers/BriefDrawer/BriefDrawer';
import { LuPlus } from 'react-icons/lu';
import useCreateBrief from 'hooks/briefs/useCreateBrief';
import useCreateProject from 'hooks/projects/useCreateProject';
import { useSelector } from 'react-redux';
import { formattedDate } from 'helpers';
import DocumentSection from './DocumentSection';
import useTotalCountDocuments from 'hooks/documents/useTotalCountDocuments';
import useOverdueCountBriefs from 'hooks/briefs/useOverdueCountBriefs';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';
import Announcement from 'Components/Pages/Brand/Announcement';
import { useNavigate } from 'react-router-dom';
import { success as renderSuccess, error as renderError } from 'helpers/toaster.js';
import { generateCampaignTitleWithClaude } from 'services/anthropicService';

// Import icons for channel grid
import { 
  FaInstagram, 
  FaYoutube, 
  FaHeadphones, 
  FaEnvelope, 
  FaFacebook, 
  FaBlog, 
  FaLinkedin
} from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { SiTiktok } from "react-icons/si";

// Campaign goal options
const CAMPAIGN_GOALS = [
  { label: 'Brand Awareness', value: 'Brand Awareness' },
  { label: 'Engagement', value: 'Engagement' },
  { label: 'Lead Generation', value: 'Lead Generation' },
  { label: 'Sales Conversion', value: 'Sales Conversion' },
];

const Dashboard = () => {
  // Global state & hooks
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // Insights & stats state
  const [insightsDuration, setInsightsDuration] = useState('week');
  const { overdue: overdueBriefsCount, refetch: refetchBriefsCount } =
    useOverdueCountBriefs(insightsDuration);
  const {
    completed: completedDocumentsCount,
    in_progress: inProgressDocumentsCount,
    refetch: refetchDocumentStats,
  } = useTotalCountDocuments(insightsDuration);
  
  // Add loading state for campaign generation
  const [isGenerating, setIsGenerating] = useState(false);

  // Modal states for other actions (document, brief)
  const [modalView, setModalView] = useState({
    newDocument: false,
    addTask: false,
    // Note: We are no longer using addProject since the dashboard triggers creation directly.
  });
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  // New state for the campaign description from the dashboard input
  const [campaignDescription, setCampaignDescription] = useState('');

  // Changed from single selection to multiple selection with no preselection
  const [selectedChannels, setSelectedChannels] = useState([]);
  const planName = user?.subscriptions?.current_subscription?.plan_name;
  const userName = user?.name || 'User';
  
  // New state for selected brand (exactly like in ProjectDrawer)
  const [selectedBrand, setSelectedBrand] = useState('');
  const [brandLabel, setBrandLabel] = useState('Choose brand');
  
  // Campaign goal state
  const [campaignGoal, setCampaignGoal] = useState('');
  const [campaignGoalLabel, setCampaignGoalLabel] = useState('Choose campaign goal');
  const [showGoalDropdown, setShowGoalDropdown] = useState(false);
  
  // Add refs for detecting clicks outside dropdowns
  const goalDropdownRef = useRef(null);
  const brandDropdownRef = useRef(null);
  
  // Handle clicks outside of dropdowns
  useEffect(() => {
    function handleClickOutside(event) {
      if (goalDropdownRef.current && !goalDropdownRef.current.contains(event.target)) {
        setShowGoalDropdown(false);
      }
      if (brandDropdownRef.current && !brandDropdownRef.current.contains(event.target)) {
        setShowBrandDropdown(false);
      }
    }
    
    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    
    // Clean up
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to transform brands data (copied directly from ProjectDrawer)
  function transformBrandsData(brandsData) {
    return brandsData?.map((item) => {
      const [key, value] = Object.entries(item)[0];
      if (value === null) {
        return { label: 'Unknown', value: key };
      }
      return { label: value, value: key };
    });
  }

  // Get brand options using the same approach as ProjectDrawer
  const brandOptions = transformBrandsData(user?.user_brands?.brands_data) || [];

  // Hooks for creating brief and project
  const { mutateAsync: createBrief } = useCreateBrief({ userId: user?.id });
  const { mutateAsync: createProject, isLoading: isCreateLoading } = useCreateProject({
    userId: user?.id,
  });

  // New UI styles for the updated prompt box and channel grid
  const newUIStyles = {
    greetingSection: {
      textAlign: 'center',
      marginBottom: '2rem',
    },
    greetingTitle: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
      marginBottom: '0.75rem',
      color: '#000',
    },
    greetingSubtitle: {
      color: '#6b7280',
      marginBottom: '2rem',
    },
    channelGrid: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '1rem',
      justifyContent: 'center',
      marginBottom: '2.5rem',
      position: 'relative',
      zIndex: '1',
    },
    channelCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff',
      border: '1px solid #e0e0e0',
      borderRadius: '0.7rem',
      padding: '1.5rem 1rem',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      width: '120px',
      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.03)',
      position: 'relative',
    },
    channelCardActive: {
      // Each card gets its own background color based on the channel's color
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
      border: '2px solid #000', // Adding black border for selected state
    },
    channelIcon: {
      fontSize: '1.5rem',
      marginBottom: '0.5rem',
    },
    brandDropdown: {
      position: 'relative',
      cursor: 'pointer',
    },
    brandDropdownMenu: {
      position: 'absolute',
      top: '100%',
      left: '0',
      zIndex: '10',
      backgroundColor: '#ffffff',
      borderRadius: '0.5rem',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
      width: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      display: 'none',
      border: '1px solid #e0e0e0',
    },
    brandDropdownMenuShow: {
      display: 'block',
    },
    brandDropdownItem: {
      padding: '0.75rem 1rem',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
    },
    brandDropdownItemHover: {
      backgroundColor: '#f3f4f6',
    }
  };

  // Channel options for the grid with background colors for selected state
  const channelOptions = [
    { id: 'Instagram', Icon: FaInstagram, color: '#E1306C', bgColor: '#FCEEF2' },
    { id: 'YouTube', Icon: FaYoutube, color: '#FF0000', bgColor: '#FEE8E8' },
    { id: 'Email', Icon: FaEnvelope, color: '#4285F4', bgColor: '#EBF1FE' },
    { id: 'Facebook', Icon: FaFacebook, color: '#1877F2', bgColor: '#E9F1FD' },
    { id: 'Blog', Icon: FaBlog, color: '#2DB67D', bgColor: '#E8F5F0' },
    { id: 'LinkedIn', Icon: FaLinkedin, color: '#0077B5', bgColor: '#E5F1F8' },
    { id: 'Twitter', Icon: FaXTwitter, color: '#000000', bgColor: '#F0F0F0' },
    { id: 'TikTok', Icon: SiTiktok, color: '#000000', bgColor: '#F0F0F0' },
  ];

  // Toggle channel selection
  const toggleChannelSelection = (channelId) => {
    setSelectedChannels(prev => {
      if (prev.includes(channelId)) {
        return prev.filter(id => id !== channelId);
      } else {
        return [...prev, channelId];
      }
    });
  };

  // Dropdown items for bottom actions
  const dropdownItems = [
    {
      modalKey: 'newDocument',
      Icon: FiFile,
      label: 'Document',
    },
    {
      modalKey: 'addTask',
      Icon: FiFileText,
      label: 'Brief',
    },
    {
      modalKey: 'addProject',
      Icon: FiLayers,
      label: 'Campaign',
    },
  ];

  // Brand dropdown toggle state
  const [showBrandDropdown, setShowBrandDropdown] = useState(false);

  // Handle brand selection
  const handleBrandSelect = (brand) => {
    // If the same brand is clicked again, unselect it
    if (selectedBrand === brand.value) {
      setSelectedBrand('');
      setBrandLabel('Choose brand');
    } else {
      setSelectedBrand(brand.value);
      setBrandLabel(brand.label);
    }
    setShowBrandDropdown(false);
  };
  
  // Handle campaign goal selection
  const handleGoalSelect = (goal) => {
    setCampaignGoal(goal.value);
    setCampaignGoalLabel(goal.label);
    setShowGoalDropdown(false);
  };

  // Reorder brand options to put selected brand at the top
  const getOrderedBrandOptions = () => {
    if (!selectedBrand) return brandOptions;
    
    const selectedBrandOption = brandOptions.find(brand => brand.value === selectedBrand);
    if (!selectedBrandOption) return brandOptions;
    
    return [
      selectedBrandOption,
      ...brandOptions.filter(brand => brand.value !== selectedBrand)
    ];
  };

  // Function to create a fallback title if Claude API fails
  const createFallbackTitle = (brandName, goal, channels) => {
    const dateStr = new Date().toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    
    let title = '';
    
    if (brandName) {
      title += `${brandName} `;
    }
    
    title += goal ? `${goal} ` : 'Engagement ';
    
    if (!channels || channels.length === 0) {
      title += 'Multi-Channel Campaign';
    } else if (channels.length === 1) {
      title += `${channels[0]} Campaign`;
    } else if (channels.length === 2) {
      title += `${channels[0]} & ${channels[1]} Campaign`;
    } else {
      title += `${channels.length}-Channel Campaign`;
    }
    
    title += ` (${dateStr})`;
    
    return title;
  };

  // Corrected handleGenerateCampaign function without the useState inside
  const handleGenerateCampaign = async () => {
    console.log('Generate button clicked');
    
    // Set loading state - no declaration here
    setIsGenerating(true);
  
    // Calculate start date (today) and end date (30 days from today)
    const today = new Date();
    const endDate = new Date();
    endDate.setDate(today.getDate() + 30);
  
    // Mapping of selected channel to content types (keys from SYSTEM_PROMPTS_HASH)
    const channelContentTypes = {
      Instagram: 'Instagram Carousel, Instagram Reel, Instagram Post',
      LinkedIn: 'Linkedin Post, Linkedin Article',
      Email: 'Email Newsletter',
      Blog: 'Blog Post, Case Study, Guide, Medium Article',
      YouTube: 'Youtube Script',
      Podcast: 'Podcast Episode',
      Twitter: 'Twitter Thread',
      Facebook: 'Facebook Post',
      TikTok: 'TikTok Video',
    };
  
    let contentTypes = '';
    
    // If no channels selected, use all content types
    if (selectedChannels.length === 0) {
      contentTypes = Object.values(channelContentTypes).join(', ');
    } else {
      // Create a combined content types string based on selected channels
      selectedChannels.forEach(channel => {
        const channelContentType = channelContentTypes[channel] || '';
        if (channelContentType) {
          contentTypes += (contentTypes ? ', ' : '') + channelContentType;
        }
      });
    }
  
    // Debug logging to verify values
    console.log('Selected Channels:', selectedChannels.length ? selectedChannels : 'All Channels');
    console.log('Selected Brand:', selectedBrand);
    console.log('Selected Goal:', campaignGoal);
    console.log('Computed Content Types:', contentTypes);
  
    // Check if contentTypes is blank and warn before proceeding
    if (!contentTypes) {
      console.error('Content types are blank. Please check your selected channel or mapping.');
      setIsGenerating(false);
      renderError('Please select at least one channel');
      return; // early exit to avoid creating a campaign with blank content types
    }
  
    try {
      // Get the brand name for the title generation
      const brandName = brandOptions.find(brand => brand.value === selectedBrand)?.label || '';
      
      // Generate campaign title using Claude
      let campaignTitle = 'New Campaign from Dashboard'; // Default fallback
      
      try {
        const generatedTitle = await generateCampaignTitleWithClaude({
          description: campaignDescription,
          goal: campaignGoal || 'Engagement',
          channels: selectedChannels.length ? selectedChannels : Object.keys(channelContentTypes),
          brand: brandName
        });
        
        if (generatedTitle) {
          campaignTitle = generatedTitle;
        } else {
          // Create a basic title if Claude doesn't return one
          campaignTitle = createFallbackTitle(
            brandName,
            campaignGoal || 'Engagement',
            selectedChannels
          );
        }
      } catch (titleError) {
        console.error('Title generation failed:', titleError);
        // Create a fallback title
        campaignTitle = createFallbackTitle(
          brandName,
          campaignGoal || 'Engagement',
          selectedChannels
        );
      }
      
      console.log('Generated Campaign Title:', campaignTitle);
  
      // Prepare data with the generated title
      const data = {
        name: campaignTitle,
        description: campaignDescription,
        campaign_goal: campaignGoal || 'Engagement', // Use selected goal or default to "Engagement"
        content_types: contentTypes, // Set based on the selected channel mapping
        business_id: selectedBrand || '',
        content_no: 30,
        creation_type: 'ai_powered', // Same default as in the ProjectDrawer
        start_date: today.toISOString(),
        end_date: endDate.toISOString(),
        briefs_qtt: 30, // Default value for briefs quota set to 30
      };
  
      const response = await createProject({ body: data });
      renderSuccess(response?.data?.message);
      navigate(
        `/projects/${response?.data?.project?.id}/newPlan?isManual=false`,
        {
          state: {
            project: response?.data?.project,
            campaignCards: true,
            amount: data.content_no,
            customAmount: true,
          },
        }
      );
    } catch (error) {
      console.error('Error creating project:', error);
      renderError('Failed to create campaign. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };
  
  // Handler for creating a new brief (unchanged from original)
  const onConfirmCreateHandler = async (payload) => {
    await createBrief({
      ...payload,
      deadline_date: payload.deadline_date ? formattedDate(payload.deadline_date) : '',
    });
    refetchBriefsCount();
  };

  return (
    <>
      <section className={Styles.Dashboard}>
        {/* New UI Elements */}
        <div
          style={{
            padding: '1.5rem',
            backgroundColor: '#f8f9fa',
            borderRadius: '0.75rem',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
          }}
        >
          {/* Greeting Section */}
          <div style={newUIStyles.greetingSection}>
            <h2 style={newUIStyles.greetingTitle}>
              Hey, {userName.split(' ')[0]}! What Are We Planning Today?{' '}
              <span style={{ color: '#FFD700' }}>✨</span>
            </h2>
            <p style={newUIStyles.greetingSubtitle}>
              Select one channel for 30 days of platform-specific content; or select multiple channels for a coordinated campaign.
            </p>
            {/* Channel Grid */}
            <div style={newUIStyles.channelGrid}>
              {channelOptions.map((channel) => (
                <div
                  key={channel.id}
                  style={{
                    ...newUIStyles.channelCard,
                    ...(selectedChannels.includes(channel.id)
                      ? { 
                          ...newUIStyles.channelCardActive,
                          backgroundColor: channel.bgColor
                        }
                      : {}),
                  }}
                  onClick={() => toggleChannelSelection(channel.id)}
                >
                  <channel.Icon
                    style={{
                      ...newUIStyles.channelIcon,
                      color: channel.color,
                    }}
                    size={24}
                  />
                  <span>{channel.id}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Enhanced Prompt Box */}
          <div
            style={{
              backgroundColor: '#ffffff',
              border: '1px solid #e2e8f0',
              borderRadius: '1rem',
              padding: '1.75rem',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
              marginBottom: '2rem',
              transition: 'all 0.3s ease',
            }}
          >
            <h3
              style={{
                fontSize: '1.2rem',
                fontWeight: '600',
                marginBottom: '1rem',
                color: '#1a202c',
              }}
            >
              Generate Your Content Strategy
            </h3>
            <div style={{ marginBottom: '1rem' }}>
              <textarea
                placeholder="Share a bit more about your goals, content themes, and target audience. I'll craft a custom plan that truly speaks to your brand."
                value={campaignDescription}
                onChange={(e) => setCampaignDescription(e.target.value)}
                style={{
                  width: '100%',
                  minHeight: '100px',
                  padding: '1rem',
                  borderRadius: '0.5rem',
                  border: '1px solid #e2e8f0',
                  fontSize: '0.95rem',
                  resize: 'vertical',
                  fontFamily: 'inherit',
                  transition: 'border-color 0.2s ease',
                  outline: 'none',
                  boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.05)',
                  marginBottom: '1rem',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '1rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  flexWrap: 'wrap',
                }}
              >
                {/* Campaign Goal Dropdown */}
                <div
                  ref={goalDropdownRef}
                  style={{
                    padding: '0.6rem 1rem',
                    background: '#ffffff',
                    border: '1px solid #e0e0e0',
                    color: '#333',
                    fontWeight: '500',
                    borderRadius: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                    cursor: 'pointer',
                    position: 'relative',
                    transition: 'all 0.2s ease',
                  }}
                  onClick={() => setShowGoalDropdown(!showGoalDropdown)}
                >
                  <span>{campaignGoalLabel}</span>
                  <FiChevronDown style={{ 
                    transition: 'transform 0.3s ease',
                    transform: showGoalDropdown ? 'rotate(180deg)' : 'rotate(0deg)'
                  }} />
                  
                  {/* Campaign Goal dropdown menu */}
                  {showGoalDropdown && (
                    <div 
                      style={{
                        position: 'absolute',
                        top: '100%',
                        left: '0',
                        zIndex: '10',
                        backgroundColor: '#ffffff',
                        borderRadius: '0.8rem',
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
                        width: 'auto',
                        minWidth: '100%',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        marginTop: '0.6rem',
                        border: '1px solid #e0e0e0',
                        animation: 'fadeIn 0.2s ease-out',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {CAMPAIGN_GOALS.map((goal) => (
                        <div
                          key={goal.value}
                          style={{
                            padding: '0.75rem 1rem',
                            cursor: 'pointer',
                            transition: 'background-color 0.2s',
                            borderBottom: '1px solid #f0f0f0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            ...(campaignGoal === goal.value ? { backgroundColor: '#f8f9fa', fontWeight: '600' } : {}),
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = '#f3f4f6';
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = campaignGoal === goal.value ? '#f8f9fa' : 'transparent';
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleGoalSelect(goal);
                          }}
                        >
                          {goal.label}
                          {campaignGoal === goal.value && (
                            <span style={{ color: '#000', fontSize: '0.9rem' }}>✓</span>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                
                {/* Brand dropdown - enhanced visual styling */}
                <div
                  ref={brandDropdownRef}
                  style={{
                    padding: '0.6rem 1rem',
                    background: '#ffffff',
                    border: '1px solid #e0e0e0',
                    color: '#333',
                    fontWeight: '500',
                    borderRadius: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                    cursor: 'pointer',
                    position: 'relative',
                    transition: 'all 0.2s ease',
                    ...(selectedBrand ? { borderColor: '#000', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' } : {})
                  }}
                  onClick={() => setShowBrandDropdown(!showBrandDropdown)}
                >
                  <span>{brandLabel}</span>
                  <FiChevronDown style={{ 
                    transition: 'transform 0.3s ease',
                    transform: showBrandDropdown ? 'rotate(180deg)' : 'rotate(0deg)'
                  }} />
                  
                  {/* Brand dropdown menu */}
                  {showBrandDropdown && (
                    <div 
                      style={{
                        position: 'absolute',
                        top: '100%',
                        left: '0',
                        zIndex: '10',
                        backgroundColor: '#ffffff',
                        borderRadius: '0.8rem',
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
                        width: 'auto',
                        minWidth: '100%',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        marginTop: '0.6rem',
                        border: '1px solid #e0e0e0',
                        animation: 'fadeIn 0.2s ease-out',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {getOrderedBrandOptions().length > 0 ? (
                        getOrderedBrandOptions().map((brand) => (
                          <div
                            key={brand.value}
                            style={{
                              padding: '0.75rem 1rem',
                              cursor: 'pointer',
                              transition: 'background-color 0.2s',
                              borderBottom: '1px solid #f0f0f0',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              ...(selectedBrand === brand.value ? { backgroundColor: '#f8f9fa', fontWeight: '600' } : {}),
                              width: '100%',
                              minWidth: '200px',
                            }}
                            onMouseOver={(e) => {
                              e.currentTarget.style.backgroundColor = '#f3f4f6';
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.backgroundColor = selectedBrand === brand.value ? '#f8f9fa' : 'transparent';
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleBrandSelect(brand);
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {selectedBrand === brand.value && (
                                <span style={{ 
                                  color: '#000', 
                                  marginRight: '6px',
                                  fontSize: '0.75rem'
                                }}>✓</span>
                              )}
                              {brand.label}
                            </div>
                            {selectedBrand === brand.value && (
                              <span 
                                style={{ 
                                  color: '#111111', // Black text color
                                  fontSize: '0.75rem',
                                  padding: '3px 8px',
                                  borderRadius: '1rem',
                                  backgroundColor: 'rgba(0, 167, 183, 0.15)', // Light transparent turquoise
                                  cursor: 'pointer',
                                  marginLeft: '10px',
                                  whiteSpace: 'nowrap',
                                  fontWeight: '600',
                                  boxShadow: '0 1px 3px rgba(0, 167, 183, 0.1)',
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  border: '1px solid transparent', // Transparent border by default
                                  transition: 'all 0.2s ease' // Smooth transition for hover effect
                                }}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.border = '1px solid #111111'; // Black border on hover
                                  e.currentTarget.style.backgroundColor = 'rgba(0, 167, 183, 0.25)'; // Slightly darker background
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.border = '1px solid transparent'; // Back to transparent border
                                  e.currentTarget.style.backgroundColor = 'rgba(0, 167, 183, 0.15)'; // Back to original color
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedBrand('');
                                  setBrandLabel('Choose brand');
                                  setShowBrandDropdown(false);
                                }}
                              >
                                Clear
                              </span>
                            )}
                            </div>
                        ))
                      ) : (
                        <div style={{ padding: '0.75rem 1rem', color: '#6b7280', textAlign: 'center' }}>
                          No brands available
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <button
                style={{
                  padding: '0.75rem 1.5rem',
                  backgroundColor: '#111111',
                  color: 'white',
                  borderRadius: '2rem',
                  border: 'none',
                  cursor: isGenerating || isCreateLoading ? 'not-allowed' : 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '0.5rem',
                  fontWeight: '600',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                  transition: 'all 0.2s ease',
                  opacity: isGenerating || isCreateLoading ? 0.7 : 1,
                }}
                onClick={handleGenerateCampaign}
                disabled={isGenerating || isCreateLoading}
              >
                {isGenerating ? (
                  <span>Generating...</span>
                ) : (
                  <>
                    <span>Generate</span>
                    <span>→</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Only keeping Active Campaigns section */}
        <ActiveCampaigns
          openProjectDrawer={
            planName === 'Basic Plan'
              ? () => setShowUpgradeModal(true)
              : () => {
                  // The campaign is now created directly from the prompt.
                }
          }
        />
      </section>

      {/* Bottom Actions - Removed as requested */}
      {modalView.newDocument && (
        <NewDocumentModal
          show={modalView.newDocument}
          onHide={() =>
            setModalView((prevState) => ({
              ...prevState,
              newDocument: false,
            }))}
        />
      )}

      {modalView.addTask && (
        <BriefDrawer
          show={modalView.addTask}
          onHide={() => setModalView((prevState) => ({ ...prevState, addTask: false }))}
          action="create"
          onSubmit={(_, payload) => onConfirmCreateHandler(payload)}
        />
      )}
    </>
  );
};

export default Dashboard;