import React, { useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ModalContext } from 'layouts/LandingLayout/LandingLayout';

import Button from 'ui/Button/Button';
import { Navbar, Container, Image, Nav, NavLink } from 'react-bootstrap';

import { ROUTES } from 'constants/routes';
import Styles from './Header.module.scss';
import CustomDropDown from 'Components/DropDownToggle/DropDown';

const Header = () => {
  const { modalViewHandler, setModalType } = useContext(ModalContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dropdownListContentFeatures = [
    {
      id: Math.random(),
      title: 'Keyword Strategy',
      iconPath: '/Images/kws.svg',
    },

    {
      id: Math.random(),
      title: 'SEO Reports',
      iconPath: '/Images/SEO reports.svg',
    },

    {
      id: Math.random(),
      title: 'Templates',
      iconPath: '/Images/Templates.svg',
    },

    {
      id: Math.random(),
      title: 'Content Planner',
      iconPath: '/Images/Content planner Feature.svg',
    },

    {
      id: Math.random(),
      title: 'Ideation Tool',
      iconPath: '/Images/Ideation tool.svg',
    },
    {
      id: Math.random(),
      title: 'Style Guide',
      iconPath: '/Images/Style Guide.svg',
    },
    {
      id: Math.random(),
      title: 'AI Writing Tools',
      iconPath: '/Images/AI Writing Tools.svg',
    },
    {
      id: Math.random(),
      title: 'Smart Entry Editor',
      iconPath: '/Images/Smart Entry Editor.svg',
    },
    {
      id: Math.random(),
      title: 'Brief Assignment',
      iconPath: '/Images/Brief Assignment.svg',
    },
    {
      id: Math.random(),
      title: 'Multi-Team Collaboration',
      iconPath: '/Images/Multi-Team Collaboration.svg',
    },
  ];
  const dropdownListContent = [
    {
      id: 1,
      title: 'Articles',
      iconPath: './Images/articles.svg',
      sublist: [
        {
          id: 1,
          title: 'Blog Outline',
          iconPath: './Images/BlogOutline.svg',
          itemId: 1,
        },
        {
          id: 2,
          title: 'SEO Long-Form Post',
          iconPath: './Images/SEOLongFormPost.svg',
          itemId: 1,
        },
        {
          id: 3,
          title: 'Product Comparison Post',
          iconPath: './Images/ProductComparisonPost.svg',
          itemId: 1,
        },
        {
          id: 4,
          title: 'Case Study',
          iconPath: './Images/IndustryAnalysis.svg',
          itemId: 1,
        },
        {
          id: 5,
          title: 'Contrarian Essay',
          iconPath: './Images/ContrarianEssay.svg',
          itemId: 1,
        },
        {
          id: 6,
          title: 'Comprehensive Guide',
          iconPath: './Images/ComprehensiveGuide.svg',
          itemId: 1,
        },
        {
          id: 7,
          title: 'Product Review',
          iconPath: './Images/ProductReview.svg',
          itemId: 1,
        },
        {
          id: 8,
          title: 'Blog Post',
          iconPath: './Images/BlogPost.svg',
          itemId: 1,
        },
        {
          id: 9,
          title: 'TikTok Video',
          iconPath: './Images/tik-tok.svg',
          itemId: 1,
        },
        {
          id: 10,
          title: 'News Article',
          iconPath: './Images/NewsArticle.svg',
          itemId: 1,
        },
        {
          id: 11,
          title: 'Essay',
          iconPath: './Images/Essay.svg',
          itemId: 1,
        },
      ],
    },
    {
      id: 2,
      title: 'Description',
      iconPath: './Images/Description.svg',
      sublist: [
        {
          id: 1,
          title: 'Product Description',
          iconPath: './Images/ProductDescription.svg',
          itemId: 1,
        },
        {
          id: 2,
          title: 'Video Title and Description',
          iconPath: './Images/VideoTitleandDescription.svg',
          itemId: 1,
        },
        {
          id: 3,
          title: 'Podcast Description',
          iconPath: './Images/PodcastDescriptionHeader.svg',
          itemId: 1,
        },
        {
          id: 4,
          title: 'Meta Title and Description',
          iconPath: './Images/MetaTitleandDescription.svg',
          itemId: 1,
        },
      ],
    },
    {
      id: 3,
      title: 'Press Release',
      iconPath: './Images/Press Release.svg',
      sublist: [
        {
          id: 1,
          title: 'Product Launch Announcement',
          iconPath: './Images/ProductLaunchAnnouncement.svg',
          itemId: 1,
        },
        {
          id: 2,
          title: 'Industry Insights Press Release',
          iconPath: './Images/IndustryInsightsPressRelease.svg',
          itemId: 1,
        },
        {
          id: 3,
          title: 'Merger OR Acquisition',
          iconPath: './Images/MergerORAcquisition.svg',
          itemId: 1,
        },
        {
          id: 4,
          title: 'Rebranding Announcement',
          iconPath: './Images/RebrandingAnnouncement.svg',
          itemId: 1,
        },
        {
          id: 5,
          title: 'New Hire Announcement',
          iconPath: './Images/NewHireAnnouncement.svg',
          itemId: 1,
        },
        {
          id: 6,
          title: 'Event Announcement',
          iconPath: './Images/EventAnnouncement.svg',
          itemId: 1,
        },
        {
          id: 7,
          title: 'New Partnership Announcement',
          iconPath: './Images/NewPartnershipAnnouncement.svg',
          itemId: 1,
        },
      ],
    },
    {
      id: 4,
      title: 'Ad Copy',
      iconPath: './Images/AdCopy.svg',
      sublist: [
        {
          id: 1,
          title: 'High Converting Facebook Ad',
          iconPath: './Images/HighConvertingFacebookAd.svg',
          itemId: 1,
        },
        {
          id: 2,
          title: 'Google Ads',
          iconPath: './Images/GoogleAds.svg',
          itemId: 1,
        },
        {
          id: 3,
          title: 'Retargeting Ads',
          iconPath: './Images/RetargetingAds.svg',
          itemId: 1,
        },
        {
          id: 4,
          title: 'Social Ad Hooks',
          iconPath: './Images/SocialAdHooks.svg',
          itemId: 1,
        },
        {
          id: 5,
          title: 'Podcast Ad',
          iconPath: './Images/PodcastAd.svg',
          itemId: 1,
        },
        {
          id: 6,
          title: 'Linkedin Ads',
          iconPath: './Images/LinkedinAds.svg',
          itemId: 1,
        },
      ],
    },
    {
      id: 5,
      title: 'Social Media',
      iconPath: './Images/SocialMedia.svg',
      sublist: [
        {
          id: 1,
          title: 'YouTube Script',
          iconPath: './Images/YouTubeScript.svg',
          itemId: 1,
        },
        {
          id: 2,
          title: 'Linkedin Posts',
          iconPath: './Images/LinkedinPosts.svg',
          itemId: 1,
        },
        {
          id: 3,
          title: 'Facebook Post',
          iconPath: './Images/FacebookPost.svg',
          itemId: 1,
        },
        {
          id: 4,
          title: 'Instagram Carousels',
          iconPath: './Images/InstagramPosts.svg',
          itemId: 1,
        },
      ],
    },
    {
      id: 6,
      title: 'Email Campaigns',
      iconPath: './Images/EmailCampaigns.svg',
      sublist: [
        {
          id: 1,
          title: 'Sales Email Sequence',
          iconPath: './Images/SalesSequence.svg',
          itemId: 1,
        },
        {
          id: 2,
          title: 'Onboarding Email Sequence',
          iconPath: './Images/OnboardingEmailSequence.svg',
          itemId: 1,
        },
        {
          id: 3,
          title: 'Nurture Email Sequence',
          iconPath: './Images/NurtureEmailSequence.svg',
          itemId: 1,
        },
        {
          id: 4,
          title: 'Cold Email Sequence',
          iconPath: './Images/ColdEmailSequence.svg',
          itemId: 1,
        },
        {
          id: 5,
          title: 'Promotional Emails',
          iconPath: './Images/PromotionalEmails.svg',
          itemId: 1,
        },
        {
          id: 6,
          title: 'Abandoned Cart Recovery Emails',
          iconPath: './Images/AbandonedCartRecoveryEmails.svg',
          itemId: 1,
        },
        {
          id: 7,
          title: 'Upselling OR Cross Selling Emails',
          iconPath: './Images/UpsellingORCrossSellingEmails.svg',
          itemId: 1,
        },
      ],
    },
  ];
  const dropdownListResources = [
    {
      id: Math.random(),
      title: 'Blog',
      iconPath: '/Images/kws.svg',
      href: 'https://blog.vocable.ai/',
    },
  ];

  return (
    <Navbar className={Styles.Navbar} collapseOnSelect expand="xl">
      <Container className={Styles.Navbar_Container} fluid>
        <Navbar.Brand className={Styles.brand_logo}>
          <Link to={ROUTES.HomePage}>
            <Image
              src="/VocableLogo.svg"
              className="d-inline-block align-top"
              alt="Vocable Logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className={Styles.NavLinks}>
            <CustomDropDown
              expandedViewEnabled={true}
              content={dropdownListContent}
              title="Templates"
              className={Styles.signIn_btn}
            />
            <CustomDropDown
              expandedViewEnabled={false}
              title="Features"
              content={dropdownListContentFeatures}
              className={Styles.signIn_btn}
            />
            <NavLink
              onClick={() => {
                navigate('/subscription-plan');
              }}
              className={Styles.signIn_btn}
            >
              Pricing
            </NavLink>
            <NavLink
              href="https://blog.vocable.ai/"
              target="_blank"
              className={Styles.signIn_btn}
            >
              Blog
            </NavLink>
          </Nav>
          <Nav className={Styles.NavActions}>
            <Button
              variant="transparent"
              onClick={() => {
                // setModalType('SIGN-IN');
                // modalViewHandler();
                navigate('/log-in');
              }}
              className={Styles.signIn_btn}
            >
              Sign In
            </Button>
            <Button
              variant="dark"
              shadow
              onClick={() => {
                // setModalType('SIGN-UP');
                // modalViewHandler();
                navigate('/sign-up');
              }}
              className={Styles.signup_btn}
            >
              Sign Up For FREE
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
