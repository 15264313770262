import React from 'react';
import Tag from 'Components/tag/Tag';
import Spinner from 'Components/Spinner/Spinner';
import ResearchToolCards from 'Containers/AITools/ResearchToolCards';
import Styles from './AIPoweredBrief.module.scss';
import { Tab, Tabs } from 'react-bootstrap';

const KeywordResearch = ({
  tags,
  selectedTags,
  setTags,
  researchInfo,
  isFetchingKeywords,
  isAnalyzingKeywords,
}) => {
  return (
    <div className={Styles.Container_KeywordsContainer}>
      <p className={Styles.Container_Title_2}>
        Boost your reach with these suggested keywords
      </p>
      {isFetchingKeywords ? (
        <Spinner
          className={Styles.Container_KeywordsContainer_KeywordsSpinner}
          text="Fetching Keywords"
        />
      ) : (
        <div className={Styles.Container_KeywordsContainer_TagsContainer}>
          <p className={Styles.Container_Subtitle}>Select up to 3.</p>
          <div
            className={Styles.Container_KeywordsContainer_TagsContainer__item}
          >
            {tags?.map((tag) => (
              <Tag
                key={tag.id}
                tag={tag}
                setTags={setTags}
                tags={tags}
                multiTags={true}
                maxTags={3}
              />
            ))}
          </div>
        </div>
      )}

      {isAnalyzingKeywords ? (
        <Spinner
          className={Styles.Container_KeywordsContainer_ResearchSpinner}
          text="Analyzing Keywords"
        />
      ) : (
        selectedTags.length > 0 &&
        researchInfo && (
          <div className={Styles.Container_KeywordsContainer_ResearchContainer}>
            <Tabs defaultActiveKey={researchInfo[0].Keyword}>
              <Tab
                title="Keyword result for:"
                tabClassName="keyword-result-for"
              />
              {researchInfo.map((category) => {
                return (
                  <Tab
                    title={category.Keyword}
                    eventKey={category.Keyword}
                    key={category.Keyword}
                  >
                    <ResearchToolCards isAiPoweredBrief basicInfo={category} />
                  </Tab>
                );
              })}
            </Tabs>
          </div>
        )
      )}
    </div>
  );
};

export default KeywordResearch;
