import React, { useEffect } from 'react';
import { XLoginButton } from 'react-social-login-buttons';
import Styles from '../Socials.module.scss';
import { getXToken } from 'services/integrationService';
import {
  error as errorToast,
  success as successToast,
} from 'helpers/toaster.js';

const TwitterLoginComponent = ({ onLoginSuccess }) => {
  const getTwitterAuthToken = async () => {
    try {
      let response = await getXToken();
      const oauthToken = response.data.oauth_token;
      const width = 600;
      const height = 400;
      const left = window.innerWidth / 2 - width / 2;
      const top = window.innerHeight / 2 - height / 2;
      const windowOptions = `width=${width},height=${height},top=${top},left=${left},scrollbars=yes`;
      const twitterUrl = `https://api.twitter.com/oauth/authorize?oauth_token=${oauthToken}`;
      const authWindow = window.open(twitterUrl, 'Twitter Auth', windowOptions);
    } catch (error) {
      console.error('Error getting Twitter request token:', error);
    }
  };

  const handleLinkedInSuccess = async (code) => {
    onLoginSuccess();
    successToast(`Integration Successfully added!`);
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'X_SUCCESS') {
        handleLinkedInSuccess();
        // Handle login success (e.g., update UI or state)
      } else if (event.data.type === 'X_ERROR') {
        successToast(event.data.payload.message);
        console.error('LinkedIn error:', event.data.payload.message);
        // Handle login error
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <img
      src={`/Images/logo-x.png`}
      alt={'x'}
      onClick={getTwitterAuthToken}
      width="50"
      height="50"
    />
  );
};

export default TwitterLoginComponent;
