import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiUserPlus } from 'react-icons/fi';
import * as Yup from 'yup';
import Styles from '../TableBody/TableBody.module.scss';
import { getUserImage, humanize, areEqualConsideringEmpty } from 'helpers';
import ImageView from '../Image/ImageView';
import Badge from 'Components/Badge/Badge';
import KanbanDropDown from 'Components/KanbanCard/KanbanDropDown';
import NewDocumentModal from 'Components/Modals/NewDocumentModal/NewDocumentModal';
import { ROUTES } from 'constants/routes';
import { LuPencil } from 'react-icons/lu';
import BSButton from 'Components/Button/BSButton';
import { DropdownButton } from 'react-bootstrap';
import Dropdown from 'Components/Dropdown/Dropdown';
import {
  CONTENT_TYPE_OPTIONS,
  CONTENT_TYPE_ICONS,
} from 'constants/contentTypes';
import { Form, Formik } from 'formik';
import { updateTask } from 'services/taskService';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import { getAllMembers } from 'services/teamService';
import { KANBAN_STATES } from 'constants';
import FormikDateField from 'Components/Form/FormikDateField';
import moment from 'moment';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';
import { useSelector } from 'react-redux';

const ContentPlannerTable = ({
  fetchCPTasks,
  tasks,
  onEdit,
  onDelete,
  onMove,
  onArchive,
  onUpdateBrief,
  selectedColumns,
  calendarTable,
  handleAICreate,
  initialSortColumn,
  initialSortDirection,
  sortRef,
  onSortChange
}) => {
  // Determine the number of "other" columns:
  // These are the columns rendered from selectedColumns (after filtering those enabled)
  const enabledColumns = selectedColumns.filter((c) => c[1]).map((c) => c[0]);
  // Plus one extra for the Actions column
  const otherColumnsCount = enabledColumns.length + 1;
  // Each "other" column is fixed at 8% width.
  const fixedWidth = 8; // in percent
  // Compute title column width as the remainder of 100%
  const titleColumnWidth = `calc(100% - ${otherColumnsCount * fixedWidth}%)`;

  const [newDocumentModalView, setNewDocumentModalView] = useState(false);
  const [collaborators, setCollaborators] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    taskId: null,
    taskName: null,
    projectId: null,
  });
  const [editingCell, setEditingCell] = useState(null); // Track which cell is being edited
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  
  // Add sorting state
  const [sortColumn, setSortColumn] = useState(initialSortColumn || null);
  const [sortDirection, setSortDirection] = useState(initialSortDirection || 'asc');
  const [sortedTasks, setSortedTasks] = useState([...tasks]);
  
  const { user } = useSelector((state) => state.auth);
  const { id: userId, subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  // Set up sorting methods on ref if provided
  useEffect(() => {
    if (sortRef && sortRef.current) {
      sortRef.current.sortBy = (column, direction) => {
        setSortColumn(column);
        setSortDirection(direction || 'asc');
      };
      
      sortRef.current.getCurrentSort = () => ({
        column: sortColumn,
        direction: sortDirection
      });
    }
  }, [sortRef, sortColumn, sortDirection]);

  // Apply initial sort if provided
  useEffect(() => {
    if (initialSortColumn) {
      setSortColumn(initialSortColumn);
      setSortDirection(initialSortDirection || 'asc');
    }
  }, [initialSortColumn, initialSortDirection]);

  // Handle sorting when tasks, sortColumn, or sortDirection changes
  useEffect(() => {
    if (!sortColumn) {
      setSortedTasks([...tasks]);
      return;
    }

    const sortedData = [...tasks].sort((a, b) => {
      let valueA, valueB;

      // Extract values based on column
      switch (sortColumn) {
        case 'contentType':
          valueA = a.content_type === 'other' ? a.other_content_type : a.content_type;
          valueB = b.content_type === 'other' ? b.other_content_type : b.content_type;
          break;
        case 'status':
          valueA = a.state;
          valueB = b.state;
          break;
        case 'deadline':
          // Handle sorting for dates
          valueA = a.deadline_date ? new Date(a.deadline_date).getTime() : 0;
          valueB = b.deadline_date ? new Date(b.deadline_date).getTime() : 0;
          break;
        default:
          valueA = a[sortColumn];
          valueB = b[sortColumn];
      }

      // Handle null/undefined values
      if (valueA === null || valueA === undefined) return sortDirection === 'asc' ? -1 : 1;
      if (valueB === null || valueB === undefined) return sortDirection === 'asc' ? 1 : -1;

      // Compare based on direction
      if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setSortedTasks(sortedData);
    
    // Call the onSortChange callback if provided
    if (onSortChange) {
      onSortChange(sortColumn, sortDirection);
    }
  }, [tasks, sortColumn, sortDirection, onSortChange]);

  const fetchAllMembers = useCallback(async () => {
    try {
      const response = await getAllMembers();
      setCollaborators(
        response.data.map((user) => ({
          value: user.id,
          label: (
            <div className="d-flex align-items-center gap-3">
              {user.mini_avatar ? (
                <ImageView
                  src={user.mini_avatar}
                  class="rounded-profile-x-sm"
                />
              ) : (
                getUserImage(
                  user.mini_avatar,
                  user.name,
                  { width: 32, height: 32 },
                  'sm',
                )
              )}
              <p>{user.name}</p>
            </div>
          ),
        })),
      );
    } catch (e) {
      renderError(e);
      setCollaborators([]);
    }
  }, []);

  useEffect(() => {
    if (tasks.length > 0 && !collaborators) fetchAllMembers();
  }, [tasks, collaborators, fetchAllMembers]);

  const handleClick = (isDocument, id, templateId, projectId) => {
    if (isDocument) {
      if (templateId) {
        return ROUTES.EditorDocumentTemplate(id, templateId);
      } else {
        return ROUTES.FreestyleDocumentTemplate(id);
      }
    } else {
      return ROUTES.Project.Folder(projectId, id);
    }
  };

  const onHideModal = (refetchTasks = false) => {
    refetchTasks && fetchCPTasks && fetchCPTasks();
    setNewDocumentModalView(false);
  };

  // Start editing a cell
  const startEditing = (taskId, fieldName) => {
    if (currentSubscription?.plan_name === 'Basic Plan') {
      setShowUpgradeModal(true);
      return;
    }
    setEditingCell({ taskId, fieldName });
  };
  
  // Cancel editing
  const cancelEditing = () => {
    setEditingCell(null);
  };

  // Handle clicking outside of an editing cell to cancel
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editingCell) {
        // Don't close when clicking form elements or buttons
        if (event.target.closest('form') || 
            event.target.closest('button') || 
            event.target.closest('.form-control') ||
            event.target.closest('.dropdown-menu') ||
            event.target.closest('.dropdown-toggle') ||
            event.target.closest('.calendar-dropdown') ||
            event.target.closest('.react-datepicker')) {
          return;
        }
        
        // Only close if clicking outside the editing area
        if (!event.target.closest('.inlineEditWrapper')) {
          cancelEditing();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingCell]);

  // Submit an update from in-place editing
  const handleUpdateSubmit = async (taskId, values) => {
    try {
      const resp = await updateTask(taskId, { task: values });
      renderSuccess('Updated successfully!');
      onUpdateBrief(taskId, resp.data.task);
      setEditingCell(null);
    } catch (e) {
      renderError('Cannot update task!');
    }
  };

  // Generate a cell renderer for editable values
  const createEditableCell = (task, fieldName, displayValue, formConfig) => {
    const isEditing = editingCell && 
                     editingCell.taskId === task.id && 
                     editingCell.fieldName === fieldName;

    if (isEditing) {
      // Editing mode - show form
      return (
        <div className={Styles.inlineEditWrapper} onClick={(e) => e.stopPropagation()}>
          <Formik
            initialValues={formConfig.initialValues}
            validationSchema={formConfig.validationSchema}
            validateOnMount
            enableReinitialize
          >
            {({ values, isValid, dirty, setFieldValue }) => (
              <Form className="w-100">
                <div className="mb-2">
                  {formConfig.fieldComponent({
                    name: formConfig.fieldName,
                    label: formConfig.fieldLabel,
                    options: formConfig.options,
                    placeholder: formConfig.placeholder,
                    setFieldValue,
                    isClearable: formConfig.isClearable,
                    isRequired: formConfig.isRequired,
                    value: values[formConfig.fieldName],
                  })}
                </div>
                <div className="d-flex justify-content-end gap-2">
                  <button 
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={cancelEditing}
                  >
                    Cancel
                  </button>
                  <button 
                    type="button"
                    className="btn btn-sm btn-save"
                    disabled={!isValid || !dirty}
                    onClick={() => handleUpdateSubmit(task.id, values)}
                  >
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      );
    } else {
      // Display mode - show value with ability to click and edit
      return (
        <div 
          className={Styles.editableCell} 
          onClick={() => startEditing(task.id, fieldName)}
        >
          {displayValue}
          <span className={Styles.editHint}>Click to edit</span>
        </div>
      );
    }
  };

  const COLUMNS_RENDERER = {
    deadline: (task) => {
      const { deadline_date, id } = task;
      const formattedDate = deadline_date ? moment(deadline_date).format('MM/DD/YYYY') : 'Not Specified';
      
      return createEditableCell(
        task,
        'deadline',
        <span>{formattedDate}</span>,
        {
          initialValues: { 
            deadline_date: deadline_date ? moment.utc(deadline_date, 'MMMM D, YYYY').toDate() : '' 
          },
          validationSchema: Yup.object().shape({
            deadline_date: Yup.date()
              .nullable(true)
              .test('is-valid-date', 'Invalid date format', (value) =>
                moment(value, 'YYYY-MM-DD', true).isValid(),
              ),
          }),
          fieldName: 'deadline_date',
          fieldLabel: 'Draft Due',
          fieldComponent: ({ name, label }) => <FormikDateField name={name} label={label} />
        }
      );
    },
    assignedTo: (task) => {
      const { id, assignee } = task;
      
      const displayValue = (
        <div className={'d-flex align-items-center gap-table-data'}>
          {!assignee?.name ? (
            <div className="rounded-profile-x-sm">
              <FiUserPlus size={16} />
            </div>
          ) : assignee?.mini_avatar ? (
            <ImageView
              class="rounded-profile-x-sm"
              src={assignee?.mini_avatar}
            />
          ) : (
            getUserImage(
              assignee?.mini_avatar,
              assignee?.name,
              { width: 32, height: 32 },
              'sm',
            )
          )}
          <p className="name name-dark">{assignee?.name ?? 'Unassigned'}</p>
        </div>
      );
      
      return createEditableCell(
        task,
        'assignedTo',
        displayValue,
        {
          initialValues: { assignee_id: assignee?.id || '' },
          fieldName: 'assignee_id',
          fieldLabel: 'Assign To',
          placeholder: 'Assign to',
          options: collaborators,
          isClearable: true,
          fieldComponent: ({ name, label, placeholder, options, setFieldValue, isClearable }) => (
            <Dropdown
              name={name}
              label={label}
              placeholder={placeholder}
              options={options}
              onChange={(option) => setFieldValue(name, option?.value ?? '')}
              defaultValue={options?.find(option => option.value === assignee?.id)}
              isClearable={isClearable}
              iconWithOption={true}
            />
          )
        }
      );
    },
    status: (task) => {
      const { id, state } = task;
      
      return createEditableCell(
        task,
        'status',
        <span style={calendarTable ? { whiteSpace: 'nowrap' } : {}}>
          <Badge status={state} />
        </span>,
        {
          initialValues: { state: state || '' },
          fieldName: 'state',
          fieldLabel: 'Status',
          placeholder: 'Select status',
          options: KANBAN_STATES.map((type) => ({
            value: type,
            label: type
              .replace(/_/g, ' ')
              .replace(/\b\w/g, (l) => l.toUpperCase()),
          })),
          isRequired: true,
          fieldComponent: ({ name, label, placeholder, options, setFieldValue }) => (
            <Dropdown
              name={name}
              label={label}
              placeholder={placeholder}
              options={options}
              onChange={(option) => setFieldValue(name, option?.value ?? '')}
              defaultValue={options.find(option => option.value === state)}
              isRequired={true}
              iconWithOption={true}
            />
          )
        }
      );
    },
    contentType: (task) => {
      const { id, content_type, other_content_type } = task;
      const icon =
        content_type === 'other'
          ? CONTENT_TYPE_ICONS.other
          : CONTENT_TYPE_ICONS[content_type];
          
      return createEditableCell(
        task,
        'contentType',
        <div className="d-flex align-items-center content-type-container">
          {icon}
          <span style={calendarTable ? { whiteSpace: 'nowrap' } : {}}>
            {content_type === 'other'
              ? humanize(other_content_type)
              : humanize(content_type || '')}
          </span>
        </div>,
        {
          initialValues: { content_type: content_type || '' },
          fieldName: 'content_type',
          fieldLabel: 'Content Type',
          placeholder: 'Select Content Type',
          options: CONTENT_TYPE_OPTIONS,
          isRequired: true,
          fieldComponent: ({ name, label, placeholder, options, setFieldValue }) => (
            <Dropdown
              name={name}
              label={label}
              placeholder={placeholder}
              options={options}
              onChange={(option) => setFieldValue(name, option?.value ?? '')}
              defaultValue={options.find(option => option.value === content_type)}
              isRequired={true}
              iconWithOption={true}
            />
          )
        }
      );
    },
  };

  return (
    <>
      <tbody>
        {(sortedTasks ?? []).map((task) => {
          const { id, title, state, project_access, document, project_id } =
            task;

          return (
            <tr key={id} className={Styles.TableRow}>
              {/* Title column uses computed dynamic width */}
              <td
                className={'text-sm-normal ' + Styles.TableData_ListTitle}
                style={{ width: titleColumnWidth, whiteSpace: 'nowrap' }}
                onClick={
                  currentSubscription?.plan_name === 'Basic Plan' &&
                  project_id !== null
                    ? () => setShowUpgradeModal(true)
                    : () => onEdit(id)
                }
              >
                <span
                  className={`d-flex justify-content-between align-items-center gap-1 ${Styles.TableData_Hidden_icon}`}
                >
                  <span
                    className={`${Styles.TableData_KanbanTitle} ${Styles.TableData_first_column} dynamicTitle`}
                    title={title}
                  >
                    {title}
                  </span>
                </span>
              </td>

              {/* Render other columns (fixed width of 8% each) */}
              {enabledColumns.map((column) =>
                COLUMNS_RENDERER[column] ? (
                  <td
                    key={`${id}-${column}`}
                    className={'text-sm-normal ' + Styles.TableData}
                    style={{ width: `${fixedWidth}%` }}
                  >
                    <span
                      className={`d-flex justify-content-between align-items-center ${Styles.TableData_Hidden_icon} gap-1`}
                    >
                      {COLUMNS_RENDERER[column](task)}
                    </span>
                  </td>
                ) : null,
              )}

              {/* Actions column (also fixed width of 8%) */}
              <td
                className={'text-sm-normal overflow-visible ' + Styles.TableData}
                style={{ width: `${fixedWidth}%` }}
              >
                <div className="d-flex align-items-center justify-content-end gap-4">
                  <Link
                    className={`text-sm-normal ${Styles.TableData_Link}`}
                    onClick={() =>
                      currentSubscription?.plan_name === 'Basic Plan' &&
                      project_id !== null
                        ? () => setShowUpgradeModal(true)
                        : !document?.id &&
                          handleAICreate(id, project_id, title)
                    }
                    to={
                      document?.id &&
                      handleClick(
                        document?.id,
                        document?.id,
                        document?.template_id,
                        project_id,
                      )
                    }
                  >
                    {document?.id ? 'View Content' : 'View Content'}
                  </Link>
                  <KanbanDropDown
                    id={id}
                    onDelete={onDelete}
                    onEdit={
                      currentSubscription?.plan_name === 'Basic Plan' &&
                      project_id !== null
                        ? () => setShowUpgradeModal(true)
                        : onEdit
                    }
                    onMove={onMove}
                    access={project_access}
                    onArchive={onArchive}
                    state={state}
                  />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
          message={
            'Updating campaign related content is a pro feature. Please update to pro plan to access this feature.'
          }
        />
      )}

      {newDocumentModalView && (
        <NewDocumentModal
          show={newDocumentModalView}
          onHide={onHideModal}
          aiPowered
          {...modalInfo}
        />
      )}
    </>
  );
};

export default ContentPlannerTable;